import React from 'react'
import { translate, currentLng, converNumberToPersian, priceStandardView } from '../utils/useful'
import { siteTheme, siteConfig } from '../variables/config'
import TLink from './TLink'
import stripHtml from "string-strip-html"
import { imageAddress } from '../utils/useful'
import moment from 'jalali-moment'
import HttpServices from '../utils/Http.services'
import Loader from "react-loader-spinner";

class AdvertisementS extends React.Component {
    state = {
        schema: { title: null, description: null, price: null, image: null, portals: null }
    }

    componentDidMount() {
        this.fetch()
    }


    fetch() {
        //   console.log("HERE")
        this.setState({ isLoading: true })
        HttpServices.request('getOneAdvertisement', { place: 'shuffle' }, (fetchResult, fetchError) => {
            this.setState({ isLoading: false })
            // console.log(fetchResult)
            if (fetchError) { return }
            if (fetchResult.info) {
                this.setState({ data: fetchResult.info })
            }
        })
    }


    render() {
        let prop = this.state.data//this.dataBuilder(this.props.data, this.props.schema)
        if (this.props.mainKey) {
            prop = prop[this.props.mainKey]
        }
        // console.log(prop)
        if (!this.state.isLoading) {
            return (
                <div className="w-100 d-flex flex-column product-box" style={{ alignItems: 'stretch', flex: 1 }}>
                    {/* <TLink to={'/آگهی/' + (prop?.slug ? prop?.slug : prop?._id)} ref={el => this.box = el} className="h-100 mt-0" > */}
                    <a href={prop?.values?.link ?? '/pages/advertisements'} target="_blank" className="flexcc w-100 flex-column" style={{ alignItems: 'stretch', flex: 1, backgroundColor: '#fff', borderRadius: 8 }}>

                        <div className="flexcc w-100 flex-column" style={{ flex: 1 }}>
                            <img src={imageAddress(prop?.values?.image, 'advertisement', 'small')} alt="" style={{ borderRadius: 8, width: '100%', objectFit: 'contain' }} />
                            <div className="px-3 pb-2 d-flex w-100 flex-column" style={{ flex: 1 }}>
                                <div className="flexcb mt-3 " style={{ flexWrap: 'wrap' }}>
                                    <p className=" text-normal" style={{ color: '#FF6C00' }}>{prop?.values?.title ?? 'تبلیغ در انبار مازاد'}</p>
                                    <p className=" text-smaller px-3 py-1" style={{ backgroundColor: '#f2f6f8', color: '#789', whiteSpace: 'nowrap', borderRadius: 4 }}>تبلیغ</p>
                                </div>
                                <div className="w-100 mt-2" style={{ backgroundColor: '#FF6C0050', height: 1 }}>

                                </div>
                                <div style={{ flex: 1 }}>
                                    <p className="text-smaller mt-2 mb-2 " style={{ color: '#585858' }} >{prop?.values?.description ?? "اینجا محل تبلیغ شماست. برای اطلاعات بیشتر تماس بگیرید"}</p>
                                </div>

                                <div className="flexcb">
                                    <p className="text-smaller mt-2 mb-2 font-bold" style={{ color: '#585858' }} ></p>
                                    <div className={"product-view-but"} style={{}}>
                                        <p className="text-smaller" >برو به صفحه</p>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </a>

                    {/* </TLink> */}
                </div>
            )
        } else {
            return (
                <div className={"flexcc"} style={{ flex: 1, backgroundColor: '#fff', borderRadius: 8 }}>
                    <div className="flexcc pt-2" style={{}}>
                        <Loader
                            type={"Oval"}
                            color={"rgba(253,121,45,1)"}
                            height={40}
                            width={40}
                        />
                    </div>
                </div>
            )
        }
    }
}

export default (AdvertisementS);