import React from 'react';
import { translate, checkTextTranslation } from '../../utils/useful'
import Section from '../../components/Section'
import TLink from '../../components/TLink';
import HttpService from '../../utils/Http.services';
import { siteConfig } from '../../variables/config';
import stripHtml from "string-strip-html";
import { imageAddress } from '../../utils/useful'
import Loader from 'react-loader-spinner'
import FastHelmet from '../../components/FastHelmet';
import DataGrid from '../../components/DataGrid'
import Product from '../../components/SingleProduct'
import AccessoryCategory from '../../components/AccessoryCategory'

class Accessories extends React.Component {


  state = {
    data: [],
    info: {}
  }


  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.init()
    });
    window.addEventListener('resize', this.setWidth)

    this.init()

  }


  componentWillUnmount() {
    this.unlisten();
    window.removeEventListener('resize', this.setWidth)

  }

  init() {
    window.scrollTo(0, 0)
    // console.log(window.pageYOffset)
    this.setState({ data: [], info: {}, isLoadingData: true }, () => {
      this.fetch()
      this.setWidth()
    })

  }


  setWidth = () => {
    this.setState({ width: window.innerWidth })
  }



  fetch() {

    let product = this.props.match.params.product

    this.setState({ isLoading: true })

    if (!product) {

      HttpService.request("getAllWithData", { applet: 'categories', sort: { 'values.priority': 1 } }, (fetchResult, fetchError) => {
        this.setState({ isLoading: false })
        if (fetchError) { return }
        // console.log(fetchResult.info)

        this.setState({ data: fetchResult.info })
      })

    } else {
      HttpService.request("getOneWithData", { filter: { applet: 'categories', '_id': product } }, (fetchResult, fetchError) => {
        if (fetchError) { return }
        if (fetchResult.info) {
          this.setState({ info: fetchResult.info })
          // console.log(fetchResult)
          let category = fetchResult.info._id

          HttpService.request("getAllWithData", { filter: { applet: 'products', 'values.category': category, '$or': [{ "values.parent": null }, { "values.parent": '' }] } }, (fetchResult, fetchError) => {
            // console.log(fetchResult)
            let products = []
            fetchResult.info.forEach(element => {
              products.push(element._id)
            });
            // console.log(products)
            HttpService.request("getAllWithData", { filter: { applet: 'accessories', 'values.category': { $in: products } } }, (fetchResult, fetchError) => {
              // console.log(fetchResult)
              this.setState({ isLoading: false })
              if (fetchError) { return }
              // console.log(fetchResult.info)
              this.setState({ data: fetchResult.info })
            })
          })
        }
      })
    }
  }

  render() {

    return (

      <section style={{ paddingBottom: 80, backgroundColor: '#f2f6f8', minHeight: '100vh' }}>

        <FastHelmet title={translate("accessories")} description={translate("accessories-name", { name: (this.state.info._id ? this.state.info?.values?.name : 'opia') })} />

        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#000' }}>
          <h1 className=" text-mega-big white">{translate("accessories-name", { name: (this.state.info._id ? this.state.info?.values?.name : translate('opia')) })}</h1>
        </div>

        <div className="container mt-4">
          <div className="row">
            <div className="col-12 mb-5 mt-4">
              <DataGrid isLoading={this.state.isLoading} data={this.state.data} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={this.state.info._id ? Product : AccessoryCategory} col="col-12 col-md-6 col-lg-3" background={"#fff"} />
            </div>
          </div>
        </div>


      </section>
    );
  }
}

export default Accessories;
