import PhoneInput from './PhoneInput'
import NumberInput from './NumberInput'
import PasswordInput from './PasswordInput'
import TextInput from './TextInput'
import SelectInput from './SelectInput'
import TextAreaInput from './TextAreaInput'
import ImageInput from './ImageInput'
import MapInput from './MapInput'
import FileInput from './FileInput'
import MultiSelectInput from './MultiSelectInput'
import TagInput from './TagInput'
import PriceInput from './PriceInput'
import MultiLevelInput from './MultiLevelInput'
import SelectWithParentInput from './SelectWithParentInput'
import SwitchInput from './SwitchInput'
import CheckboxInput from './CheckboxInput'
import ProfileImageInput from './ProfileImageInput'



const inputComponents = {
    PhoneInput,
    NumberInput,
    PasswordInput,
    TextInput,
    SelectInput,
    TextAreaInput,
    ImageInput,
    MapInput,
    FileInput,
    MultiSelectInput,
    TagInput,
    MultiLevelInput,
    SelectWithParentInput,
    PriceInput,
    SwitchInput,
    CheckboxInput,
    ProfileImageInput

}

export default (inputComponents);