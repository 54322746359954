import React from 'react';
import Hero from '../components/Hero'
import { translate, imageAddress } from '../utils/useful'
import DataGrid from '../components/DataGrid'
import Product from '../components/Product'
import HttpService from '../utils/Http.services'
import { chooseWallpaper } from '../utils/useful'
import FastHelmet from '../components/FastHelmet';
import { siteConfig } from '../variables/config';
import TextInput from '../components/inputs/TextInput';
import SelectInput from '../components/inputs/SelectInput';
import LoaderButton from '../components/LoaderButton';
import SelectWithParentInput from '../components/inputs/SelectWithParentInput';
import SortingButton from '../components/SortingButton';
import TLink from '../components/TLink';

class Search extends React.Component {

  state = {
    products: [],
    gallery: [],
    selectedGallary: 0,
    // categories1: [
    //   { name: 'میکروکنترلر و پروسسور', icon: '/images/icons/proccessor.svg', link: '' },
    //   { name: 'پاور و تغذیه', icon: '/images/icons/power.svg', link: '' },
    //   { name: 'قطعات الکترونیکی', icon: '/images/icons/devices.svg', link: '' },
    //   { name: 'میکرو کامپیوتر', icon: '/images/icons/micro.svg', link: '' },
    //   { name: 'سنسور', icon: '/images/icons/sensors.svg', link: '' },
    //   { name: 'ماژول', icon: '/images/icons/modules.svg', link: '' },
    //   { name: 'ابزار و تجهیزات', icon: '/images/icons/proccessor.svg', link: '' },
    //   { name: 'نمایشگر', icon: '/images/icons/display.svg', link: '' },
    // ],

    categories: [],

    searchHeaders: [
      { key: 'title', type: 'TextInput', information: { label: '{{lang}}title', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: 'جست و جو ...', required: true } },
      // { key: 'category', type: 'SelectWithParentInput', information: { isSearchable: true, label: '{{lang}}category', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', address: 'getAllWithData', filter: { applet: 'categories' }, fields: { title: 'values.name', value: '_id' }, type: 'api', placeholder: 'دسته بندی ...', required: true } },
      { key: 'categories', type: 'SelectWithParentInput', information: { label: 'شهر', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', address: 'getAllWithData', filter: { applet: 'categories' }, isSearchable: true, sort: "values.name", fields: { title: 'values.name', value: '_id', parent: 'values.parent' }, type: 'api', selectableParent: true, placeholder: 'دسته بندی', required: true } },

      { key: 'city', type: 'SelectWithParentInput', information: { label: 'شهر', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', address: 'getAllWithData', filter: { applet: 'cities' }, isSearchable: true, sort: "values.name", fields: { title: 'values.name', value: '_id', parent: 'values.parent' }, type: 'api',selectableParent: true,  placeholder: 'انتخاب شهر', required: true } },
    ],

    searchInfo: {},


    ads: []

  }


  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let searchInfo = {}
    if (params.get('cat'))
      searchInfo.categories = params.get('cat');
    if (params.get('city'))
      searchInfo.city = params.get('city');
    if (params.get('title'))
      searchInfo.title = params.get('title');

    this.setState({ searchInfo }, () => {
      this.fetch(true)
    })


    // this.fetchCategories()
    // this.fetchWallpapers()
    // this.fetchGallery()
    // this.fetchVideo()

  }

  fetch(search) {
    // console.log("FETCH")
    let filter = {}
    this.setState({ isLoadingProducts: true, data: [] })
    if (search) {
      this.setState({ isLoadingSearch: true })

      filter = { ...this.state.searchInfo }
      if (filter.title) {
        filter.title = { $regex: filter.title }
      }
    }

    // console.log(filter)
    HttpService.request("getAllAds", { filter, sort: this.state.currentSort }, (fetchResult, fetchError) => {
      // console.log(fetchResult)
      // console.log(fetchError)
      this.setState({ isLoadingProducts: false, isLoadingSearch: false })

      if (fetchError) { return }
      // console.log(fetchResult.info)
      this.setState({ products: fetchResult.info })
    })
  }



  // fetchCategories() {

  //   this.setState({ isLoadingCategories: true })
  //   HttpService.request("getAllWithData", { filter: { applet: 'categories' } }, (fetchResult, fetchError) => {
  //     // console.log(fetchResult)
  //     // console.log(fetchError)
  //     this.setState({ isLoadingCategories: false, })

  //     if (fetchError) { return }
  //     // console.log(fetchResult.info)
  //     this.setState({ categories: fetchResult.info })
  //   })
  // }



  changeSearchValue = (key, value) => {
    // console.log(value)
    let searchInfo = this.state.searchInfo

    if (key == 'used') {
      if (value) {
        searchInfo.used = {$ne:true}
      } else {
        delete searchInfo.used
      }
    } else {

      if (!value || value == '') {
        delete searchInfo[key]
      } else {
        searchInfo[key] = value

      }
    }
    // console.log(searchInfo)
    this.setState({ searchInfo })
    // console.log(searchInfo)
  }



  doSearch = (sort) => {
    let currentSort = sort ?? this.state.currentSort
    this.setState({ currentSort }, () => {
      this.fetch(true)
    })
  }

  render() {




    return (
      <div className={"Page container-fluid p-0"} style={{ backgroundColor: '#fafafa' }}>

        <FastHelmet title={'جست و جو'} description={'انبار مازاد'} />


        <div className="w-100 mb-3 flexcc flex-column" style={{ backgroundColor: '#f2f6f8' }}>
          {/* <h1 className=" text-center text-ultra-big pb-2 px-3" style={{ borderBottom: '1px solid rgb(245,105,33)' }}>انبار مازاد</h1> */}
          <div className="container flexcc flex-column mt-3 w-100 mb-5">


            <h1 className="text-center text-ultra-big pb-2 px-3 mt-3" style={{ fontWeight: 'normal', color: '#789' }} >جست و جو</h1>
            <div className="w-100 row py-1 px-1  mt-2 mb-3" style={{ backgroundColor: '#fff', borderRadius: 40, boxShadow: '0px 0px 15px #00000010' }}>
              <div className="col-12 search-field col-md-3 flexcc" style={{ borderLeft: '1px solid #FF6C0030' }}>
                <TextInput header={this.state.searchHeaders[0]} changeValue={this.changeSearchValue} data={this.state.searchInfo["title"]} />
              </div>
              <div className="col-12 search-field col-md-3 flexcc" style={{ borderLeft: '1px solid #FF6C0030' }}>
                <SelectWithParentInput header={this.state.searchHeaders[1]} changeValue={this.changeSearchValue} data={this.state.searchInfo["categories"]} />
              </div>
              <div className="col-12 search-field col-md-3 flexcc">
                <SelectWithParentInput header={this.state.searchHeaders[2]} changeValue={this.changeSearchValue} data={this.state.searchInfo["city"]} />
              </div>
              <div className="col-12 col-md-3 d-flex justify-content-end m-0 p-0">
                <LoaderButton
                  onClick={this.doSearch}
                  isLoading={this.state.isLoadingSearch}
                  text={'جست و جو'}
                  type={"Oval"}
                  className="w-100  "
                  buttonStyle={{ flex: 1, whiteSpace: 'nowrap', outline: 'none', backgroundColor: '#FF6C00', cursor: 'pointer', padding: '10px 20px', fontSize: 15, color: '#fff', border: 'none', width: '100%' }}
                  buttomClassName={'search-but'}
                  width={40}
                  height={40}
                  color={'#FF6C00'}
                />
              </div>

            </div>

            <div className='w-100 px-3'>
                  <input id="new" name="new" type="checkbox" onChange={e => this.changeSearchValue('used', e.target.checked)} />
                  <label className="px-1" style={{ fontSize: 14, color: '#789', userSelect: 'none' }} for="new" >تنها کالای نو</label>
                </div>


          </div>
        </div>


        <div className="container pt-3">



          <div className="row mt-0">



            <div className="col-12 mb-5 mt-4">

              <div className="flexcb mb-4 pb-2" style={{ borderBottom: '1px solid #DBDBDB' }}>
                <div>
                  <span className="text-semibig " style={{ color: '#585858' }}>آگهی ها</span>
                </div>

                <SortingButton doSearch={this.doSearch} />


              </div>

              <DataGrid isLoading={this.state.isLoadingProducts} data={this.state.products} notFoundText="موردی یافت نشد" schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Product} col="col-12 col-md-6 col-lg-3 flexcc align-items-stretch " />

              <div className="text-center">
                {this.state.products?.length === 0 && !this.props.isLoadingProducts && (
                  <>
                    <p style={{ fontSize: 13, color: '#789', marginTop: 20 }}>جست و جوی دیگری را برای یافتن کالا امتحان کنید </p>
                   <div className="mt-4">
                    <TLink to={'/'} style={{backgroundColor:'rgb(255, 108, 0)',borderRadius:20, padding:'6px 15px'}}>
                      <span className="white text-small">رفتن به صفحه اول</span>
                    </TLink>
                    </div>
                  </>
                )}
              </div>

            </div>
          </div>
        </div>





      </div>

    );
  }
}

export default Search;
