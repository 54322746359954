import React from 'react'
import LoaderButton from '../LoaderButton'
import FormViewer from '../FormViewer'
import TLink from '../TLink'

class PartnerFirstStep extends React.Component {
    state = {
        key: '',
        selectedCountry: {},
        data: {},
        isLoading: false,
        formHeaders: []
    }

    componentDidMount() {
        let formHeaders = [{ key: 'userIndicator', type: 'PhoneInput', information: { label: '{{lang}}phonenumber', placeholder: '{{lang}}phonenumber', required: true } }]
        if (this.props.initData.indicatorType && this.props.initData.indicatorType === 'email') {
            formHeaders = [{ key: 'userIndicator', type: 'EmailInput', information: { label: '{{lang}}phonenumber', placeholder: '{{lang}}phonenumber', required: true } }]
        }
        this.setState({ formHeaders })
    }



    letsStart = () => {
        this.props.letsStart()
    }


    render() {
        return (
            <div className="flexcc flex-column">

                <img src="https://image.flaticon.com/icons/svg/2909/2909653.svg" width="70px" />
                <h2 className="mt-2 mb-3" style={{ fontSize: 27 }}>Become a Partner</h2>
                <div className="w-100" style={{ maxWidth: 400 }}>

                    <p style={{ fontSize: 15, margin: '0px 20px 20px 20px' }}>This would be full description about being a partner. This would be full description about being a partner. This would be full description about being a partner</p>

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.letsStart}
                            isLoading={this.state.isLoading}
                            text={"Let's Start"}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                        <p style={{ fontSize: 15, margin: '10px 20px 20px' }}>With becoming partner you are accepting our partner <TLink pathname="/pages/terms" style={{ color: '#007aff' }}>terms</TLink> and <TLink pathname="/pages/policies" style={{ color: '#007aff' }}>Policies</TLink></p>
                        <p style={{ fontSize: 13, opacity: 0.5, marginTop: 10 }}>This website uses cookies to deliver the best possible user experience. With using the website you are accepting it</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default PartnerFirstStep;