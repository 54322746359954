import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import TLink from "../../components/TLink";
import { translate } from "../../utils/useful";

class YouShouldLogin extends React.Component {

  state = {
    data: [],
    method: 'online'

  }



  render() {
    return (
      <section>

        <div style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>
          <div className="p-3" style={{ backgroundColor: '#e0e6e8', borderRadius: '8px 8px 0px 0px' }}>
            <p className="font-bold text-center" style={{ fontSize: 18 }}>{translate('login')}</p>
          </div>


          <div className={'mb-5'} style={{ flex: 2, alignItems: 'center', textAlign: 'center' }}>

            <img className=" mt-5 mb-3" src="https://image.flaticon.com/icons/svg/3039/3039427.svg" style={{ width: 120 }} />

            <div className=" mr-3 ml-3">
              <p style={{ fontSize: 14, color: '#222' }}>{translate('not-loggedin')}</p>
              <p className=" mb-4 mt-2" style={{ fontSize: 14, color: '#222' }}>{translate("to-continue")} <TLink to={'/login'} search={'?ref=checkout'} style={{ color: '#007aff' }}>{translate('login')}</TLink></p>


            </div>
          </div>





        </div>
      </section>
    );

  }
}




const mapStateToProps = state => ({ settings: state.settings, user: state.user, cart: state.cart })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null, { forwardRef: true }
)(YouShouldLogin);


