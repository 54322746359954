import React from 'react'
import { translate } from '../../utils/useful'
import { siteConfig } from '../../variables/config'
import { withRouter } from 'react-router-dom'
import { pathMaker, imageAddress } from '../../utils/useful'
import TLink from '../TLink'

class AddAdSuccessStep extends React.Component {


    render() {
        return (

            <div className="flexcc flex-column">
                <div className="w-100 mt-0" style={{ maxWidth: 400 }}>
                    <div className="p-4 mt-3 text-center" style={{ backgroundColor: '#fff', borderRadius: 8 }}>

                        <img src={'/images/congratulation.png'} width="80px" alt="congratulation" />
                        <h2 className="mt-2 mb-0 text-big">آگهی</h2>
                        <h2 className="mt-0 mb-0 text-ultrabig">با موفقیت ثبت شد</h2>

                        <p className="mb-4 mt-0 mt-3" style={{ fontSize: 15 }}>آگهی پس از بررسی و تایید کارشناسان منتشر خواهد شد</p>



                        <button onClick={() => this.props.startOver()} pathname={'/'} style={{ outline: 'none', backgroundColor: '#FF6C00', cursor: 'pointer', padding: '10px 50px', borderRadius: 30, fontSize: 15, color: '#fff', border: 'none' }}>
                            ثبت آگهی جدید
                        </button>
                        <div className="mt-3">
                            <TLink pathname={'/'} style={{ outline: 'none', backgroundColor: '#000510', cursor: 'pointer', padding: '10px 50px', borderRadius: 30, fontSize: 15, color: '#fff', border: 'none' }}>
                               <span>برو به خانه</span>
                        </TLink>
                        </div>

                    </div>


                </div>
            </div>

        )
    }
}

export default withRouter(AddAdSuccessStep);