import React from 'react';
import { translate } from '../../utils/useful'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
// import FormViewer from '../../components/FormViewer'
// import Loader from 'react-loader-spinner'
import Modal from '../../components/Modal'
// import LoaderButton from '../../components/LoaderButton';
import HttpService from '../../utils/Http.services';
// import moment from 'moment'
import moment from 'jalali-moment'
import DataGrid from '../../components/DataGrid';
import Product from '../../components/Product'


class MeBookmark extends React.Component {


  state = {
    data: [],
  }

  componentDidMount() {
    this.fetch()
  }



  fetch = () => {
    this.setState({ isLoading: true, errors: {} })
    HttpService.request('getMyWishes', {}, (fetchResult, fetchError) => { // filter: { '**creator': true }
      this.setState({ isLoading: false })
      // console.log(fetchError)

      if (fetchError) { this.setState({ errors: fetchError.message }); return }
      // console.log(fetchResult)
      if (fetchResult.info) {
        this.setState({ data: fetchResult.info }, () => {
          // this.initData()
        })
      }
    })

  }




  render() {

    return (
      <section>

        <div style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>


          <div className="p-3" style={{}}>
            <p className="text-big pb-3" style={{ borderBottom: '2px solid #ddd' }}>علاقه‌مندی های من</p>
            <div className="flexc">
              <div style={{ width: 100, height: 2, backgroundColor: '#FF6C00', marginTop: -2 }}></div>
              <div style={{ width: 50, height: 2, backgroundColor: '#FF6C00', marginTop: -2, marginRight: 10 }}></div>
            </div>
          </div>

          <div className="w-100" style={{ padding: '20px 25px 0px 25px', paddingBottom: this.state.data.length == 0 ? '45px' : '0px' }}>


            {!this.state.isLoading && this.state.data?.length == 0 && (
              <div className="mt-4 text-center">
                <img src="/images/bookmark.svg" alt="" width="70px" />
                {/* <p className="text-center mb-3 mt-2">علاقه مندی یافت نشد</p> */}
              </div>
            )}


            <DataGrid isLoading={this.state.isLoading} data={this.state.data} mainKey={'targetId'} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Product} col="col-12 col-md-6 col-lg-4 flexcc align-items-stretch " />



          </div>
        </div>

      </section>
    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user, cart: state.cart })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null, { forwardRef: true }
)(MeBookmark);


