import React from 'react';
import DataGrid from '../components/DataGrid'
import BlogBox from '../components/BlogBox'
import HttpService from '../utils/Http.services';
import { translate, chooseWallpaper } from '../utils/useful';
import FastHelmet from '../components/FastHelmet';


class Posts extends React.Component {

  state = {
    data: []
  }

  componentDidMount() {
    this.fetch()
    this.fetchWallpapers()
  }


  fetchWallpapers() {

    HttpService.request('getAllWithData', { applet: 'wallpapers', filter: { 'values.page': 'مقالات' } }, (fetchResult, fetchError) => {
      // console.log(fetchResult)
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ wallpapers: fetchResult.info })
      }
    })
  }


  fetch() {
    this.setState({ isLoading: true })
    HttpService.request("getAllWithData", { applet: 'tutorials' }, (fetchResult, fetchError) => {
      this.setState({ isLoading: false })

      if (fetchError) { return }
      this.setState({ data: fetchResult.info })
    })
  }


  render() {

    return (
      <section style={{ paddingBottom: 80 }}>

        <FastHelmet title={translate('tutorials')} description={translate('blogs')} />

        <div className="text-center flexcc" style={{ padding: '40px 20px 40px 20px', position: 'relative', backgroundColor: '#bbccdd', backgroundImage: "url(" + chooseWallpaper(this.state.wallpapers) + ")", height: '60vh', backgroundSize: 'center', backgroundPosition: 'center' }}>
          <div className="position-absolute w-100 h-100" style={{ background: '#00000070', top: 0, left: 0 }}>
          </div>
          <div style={{ position: 'relative' }}>
            <h1 className=" text-mega-big white" >مقالات</h1>
            <p className=" text-small white mt-2 opacity-5" style={{ letterSpacing: 2 }} >RAEESMELK.COM</p>

          </div>
        </div>



        <div className="container mt-4">
          <div className="row">

            <div className="col-12 mb-5 mt-4">
              <DataGrid isLoading={this.state.isLoading} data={this.state.data} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={BlogBox} col="col-12 col-md-6 col-lg-4" />
            </div>
          </div>
        </div>

      </section>
    );
  }
}

export default Posts;
