import React from 'react';
import { translate } from '../../utils/useful'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
// import FormViewer from '../../components/FormViewer'
// import Loader from 'react-loader-spinner'
import Modal from '../../components/Modal'
// import LoaderButton from '../../components/LoaderButton';
import HttpService from '../../utils/Http.services';
// import moment from 'moment'
import moment from 'jalali-moment'
import DataGrid from '../../components/DataGrid';
import Product from '../../components/Product'
import Loader from 'react-loader-spinner';


class MeAds extends React.Component {


  state = {
    data: [],
  }

  componentDidMount() {
    this.fetch()
  }



  fetch = () => {
    this.setState({ isLoading: true, errors: {} })
    HttpService.request('getMyAds', {}, (fetchResult, fetchError) => { // filter: { '**creator': true }
      this.setState({ isLoading: false })
      // console.log(fetchError)

      if (fetchError) { this.setState({ errors: fetchError.message }); return }
      // console.log(fetchResult)
      if (fetchResult.info) {
        this.setState({ data: fetchResult.info }, () => {
          // this.initData()
        })
      }
    })

  }



  fetchInactivate = () => {
    this.setState({ isLoadingAction: true, errors: {} })
    let userinactive = this.state.currentItem.userinactive
    if (userinactive == null) {
      userinactive = false
    }
    console.log(this.state.currentItem._id)
    HttpService.request('inactivateAd', { id: this.state.currentItem._id, userinactive: !userinactive }, (fetchResult, fetchError) => { // filter: { '**creator': true }
      this.setState({ isLoadingAction: false })
      // console.log(fetchError)

      if (fetchError) { this.setState({ errors: fetchError.message }); return }
      // console.log(fetchResult)
      // if (fetchResult.info) {
      let data = this.state.data
      data.forEach(element => {
        if (element._id == this.state.currentItem._id) {
          element.userinactive = !userinactive
          // console.log("FOUND")
        }
      });
      this.moreModal.hideModal()
      this.inactivateModal.hideModal()

      this.setState({ data })
      // }
    })

  }


  fetchRemove = () => {
    this.setState({ isLoadingAction: true, errors: {} })
    HttpService.request('removeAd', { id: this.state.currentItem._id }, (fetchResult, fetchError) => { // filter: { '**creator': true }
      this.setState({ isLoadingAction: false })
      // console.log(fetchError)

      if (fetchError) { this.setState({ errors: fetchError.message }); return }
      // console.log(fetchResult)
      // if (fetchResult.info) {

      let data = this.state.data
      let index
      data.forEach((element, i) => {
        if (element._id == this.state.currentItem._id) {
          index = i
        }
      });

      if (index!=null) {
        data.splice(index, 1)
      }
      this.moreModal.hideModal()
      this.removeModal.hideModal()

      this.setState({ data })

      // }
    })

  }


  moreClicked = (prop) => {
    this.moreModal.showModal()
    this.setState({ currentItem: prop })
  }


  inactivateAdModal = (prop) => {
    this.inactivateModal.showModal()
  }

  removeAdModal = (prop) => {
    this.removeModal.showModal()
  }



  render() {

    return (
      <section>

        <div style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>


          <div className="p-3" style={{}}>
            <p className="text-big pb-3" style={{ borderBottom: '2px solid #ddd' }}>آگهی‌‌های من</p>
            <div className="flexc">
              <div style={{ width: 100, height: 2, backgroundColor: '#FF6C00', marginTop: -2 }}></div>
              <div style={{ width: 50, height: 2, backgroundColor: '#FF6C00', marginTop: -2, marginRight: 0 }}></div>
            </div>
          </div>

          <div className="w-100" style={{ padding: '20px 25px 0px 25px', paddingBottom: this.state.data.length == 0 ? '45px' : '0px' }}>


            {!this.state.isLoading && this.state.data?.length == 0 && (
              <div className="mt-4 text-center">
                <img src="/images/bookmark.svg" alt="" width="70px" />
                {/* <p className="text-center mb-3 mt-2">علاقه مندی یافت نشد</p> */}
              </div>
            )}


            <DataGrid isLoading={this.state.isLoading} data={this.state.data} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals', edit: true }} component={Product} col="col-12 col-md-6 col-lg-4 flexcc align-items-stretch " settings={{ showMore: true, moreClicked: this.moreClicked }} />



          </div>
        </div>

        <Modal ref={el => this.moreModal = el} className="" maxWidth={300}>
          <div className="p-4 flexcc flex-column" style={{ backgroundColor: '#fff', borderRadius: 8 }}>
            <p className="font-bold text-big">عملیات</p>
            <p className="font-small mb-3">انتخاب عملیات</p>


            {!this.state.isLoadingAction ? (
              <div className="w-100">
                {!this.state.currentItem?.userinactive && (
                  <button onClick={() => this.inactivateAdModal()} className="w-100 mb-1" style={{ backgroundColor: '#007aff', borderRadius: 30, padding: '12px 20px' }}>
                    <p className="white text-small font-bold">غیر فعال کردن آگهی</p>
                  </button>
                )}

                {this.state.currentItem?.userinactive && (
                  <button onClick={() => this.fetchInactivate()} className="w-100 mb-1" style={{ backgroundColor: '#007aff', borderRadius: 30, padding: '12px 20px' }}>
                    <p className="white text-small font-bold">فعال کردن آگهی</p>
                  </button>
                )}

                <button onClick={() => this.removeAdModal()} className="w-100" style={{ backgroundColor: '#ee5050', borderRadius: 30, padding: '12px 20px' }}>
                  <p className="white text-small font-bold">حذف آگهی</p>
                </button>
              </div>
            ) : (
                <div className="w-100 flexcc">
                  <Loader
                    type={"Oval"}
                    color={"rgba(255, 108, 0, 0.867)"}
                    height={50}
                    width={50}
                  />
                </div>
              )}

          </div>
        </Modal>



        <Modal ref={el => this.inactivateModal = el} className="" maxWidth={300}>
          <div className="p-4 flexcc flex-column" style={{ backgroundColor: '#fff', borderRadius: 8 }}>
            <p className="font-bold text-big">غیر فعال کردن</p>
            <p className="font-small mb-3 mt-2">آیا از غیر فعال کردن آگهی اطمینان دارید؟ آگهی شما پس از غیر فعال شدن دیگر نمایش داده نخواهد شد</p>

            {!this.state.isLoadingAction ? (
              <div className="w-100">
                <button onClick={() => this.fetchInactivate()} className="w-100 mb-1" style={{ backgroundColor: '#ee5050', borderRadius: 30, padding: '12px 20px' }}>
                  <p className="white text-small font-bold">غیر فعال کردن آگهی</p>
                </button>

                <button onClick={() => this.inactivateModal.hideModal()} className="w-100" style={{ backgroundColor: '#789', borderRadius: 30, padding: '12px 20px' }}>
                  <p className="white text-small font-bold">لغو عملیات</p>
                </button>
              </div>
            ) : (
                <div className="w-100 flexcc">
                  <Loader
                    type={"Oval"}
                    color={"rgba(255, 108, 0, 0.867)"}
                    height={50}
                    width={50}
                  />
                </div>
              )}

          </div>
        </Modal>


        <Modal ref={el => this.removeModal = el} className="" maxWidth={300}>
          <div className="p-4 flexcc flex-column" style={{ backgroundColor: '#fff', borderRadius: 8 }}>
            <p className="font-bold text-big">حذف آگهی</p>
            <p className="font-small mb-3 mt-2">آیا از حذف آگهی اطمینان دارید؟ آگهی شما پس از حذف به صورت دائمی از دسترس خارج خواهد شد</p>

            {!this.state.isLoadingAction ? (
              <div className="w-100">
                <button onClick={() => this.fetchRemove()} className="w-100 mb-1" style={{ backgroundColor: '#ee5050', borderRadius: 30, padding: '12px 20px' }}>
                  <p className="white text-small font-bold">حذف آگهی</p>
                </button>

                <button onClick={() => this.removeModal.hideModal()} className="w-100" style={{ backgroundColor: '#789', borderRadius: 30, padding: '12px 20px' }}>
                  <p className="white text-small font-bold">لغو عملیات</p>
                </button>
              </div>
            ) : (
                <div className="w-100 flexcc">
                  <Loader
                    type={"Oval"}
                    color={"rgba(255, 108, 0, 0.867)"}
                    height={50}
                    width={50}
                  />
                </div>
              )}

          </div>
        </Modal>

      </section>
    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user, cart: state.cart })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null, { forwardRef: true }
)(MeAds);


