import React from 'react';
import { translate, imageAddress } from '../../utils/useful'
import Section from '../../components/Section'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import HttpService from '../../utils/Http.services';
import stripHtml from "string-strip-html";
import Loader from 'react-loader-spinner'
import { siteConfig } from '../../variables/config';
import { priceStandardView, converNumberToPersian } from '../../utils/useful'
import FastHelmet from '../../components/FastHelmet';
import DataGrid from '../../components/DataGrid'
import ProductCell from '../../components/SingleProduct'

class Product extends React.Component {

  state = {
    // data: { _id: 1 },
    // images: [

    //   '/images/products/2.jpeg',
    //   '/images/products/3.jpeg',
    //   '/images/products/1.jpeg',

    //   '/images/products/5.jpeg',
    //   '/images/products/4.jpeg',

    // ],
    info: {},
    currentImageIndex: 0,
    currentImage: {},
    modalOpacity: 0,
    selectedTab: 0
  }


  componentDidMount() {
    // console.log("HERE")
    this.fetch()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.location != newProps.location) {
      this.setState({ isLoadingPage: true, data: {}, similarData: [] }, () => {
        this.fetch()
      })
    }
  }

  fetch() {

    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    this.setState({ isLoadingPage: true })
    HttpService.request("getOneWithData", { applet: 'products', filter: { _id: id } }, (fetchResult, fetchError) => {
      this.setState({ isLoadingPage: false })
      if (fetchError) { return }
      if (fetchResult.info) {
        // console.log(fetchResult.info)
        if (fetchResult.info.values.image) {
          // let images = Array.isArray(fetchResult.info.values.colors) ? fetchResult.info.values.colors : []
          // images.splice(0, 0, fetchResult.info.values.image)
          // fetchResult.info.values.images = images
          let images = []

          fetchResult.info.values.colors.forEach(color => {
            // if (color.image && color.image != '') {
            images.push(color.image)
            // }
          });

          this.setState({ currentImage: images[0], selectedColor: 0 })

        }



        this.setState({ info: fetchResult.info })

      }
    })
  }


  fetchAccessories() {

    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    this.setState({ isLoadingAccessories: true })
    HttpService.request("getAllWithData", { applet: 'accessories', filter: { 'values.category': id, 'values.show': true } }, (fetchResult, fetchError) => {
      this.setState({ isLoadingAccessories: false })
      if (fetchError) { return }
      if (fetchResult.info)
        console.log(fetchResult.info)
      this.setState({ accessories: fetchResult.info })
    })
  }



  openGallary(index) {
    this.setState({ showModal: true, modalOpacity: 1 }, () => {
      if (this.state.info?.values?.images[index] && index !== this.state.currentImageIndex) {

        this.setState({ dontShowImage: true }, () => {
          setTimeout(() => {
            this.setState({ dontShowImage: false })

            this.setState({ currentImageIndex: index })
            let left = 0
            let width = 0

            if (this["gallary" + index]) {
              left = this["gallary" + index].getBoundingClientRect().x
              width = this["gallary" + index].getBoundingClientRect().width
            }
            if (this.gallary) {
              this.gallary.scrollLeft = this.gallary.scrollLeft + left - (window.innerWidth / 2 - width / 2)
            }
          }, 200);
        })
      }
    })
  }

  closeModal() {
    this.setState({ modalOpacity: 0 }, () => {
      setTimeout(() => {
        this.setState({ showModal: false })
      }, 300);
    })
  }


  showCartBut = () => {
    let data = this.state.info
    let done = false

    // console.log(this.props.cart)

    for (let i = 0; i < this.props.cart?.items?.length; i++) {
      let item = this.props.cart.items[i]
      if (item?.data?._id == data._id && (!data.values?.colors || item?.color?.code == data.values?.colors[this.state.selectedColor]?.code)) {
        done = true
        return (
          <div className="mb-4 d-flex" style={{ justifyContent: 'space-between', backgroundColor: '#007aff', padding: '10px 10px', color: '#fff', fontWeight: 'bold', textAlign: 'center', width: '100%', borderRadius: 30, fontSize: 20 }}>
            <button onClick={() => this.reduceFromCart({ ...data })} className="text-big p-0 white h-100" style={{ backgroundColor: '#ffffff20', width: 36, borderRadius: 30 }}>-</button>
            <span style={{ fontWeight: 'normal' }}>{item.count} <span style={{ opacity: 0.5 }}>{this.state.info?.values?.unit ? this.state.info?.values?.unit : translate('items')}</span></span>
            <button onClick={() => this.addToCart({ data })} className="text-big p-0 white h-100" style={{ backgroundColor: '#ffffff20', width: 36, borderRadius: 30 }}>+</button>
          </div>
        )
      }
    }

    if (!done) {
      return (
        <button onClick={() => { this.addToCart({ data }) }} className="mb-4" style={{ backgroundColor: 'rgb(238,80,80)', padding: '15px 25px', color: '#fff', textAlign: 'center', width: '100%', borderRadius: 30, fontSize: 16 }}>{translate('add-to-bag')}</button>
      )
    }
  }

  reduceFromCart(data) {
    let newData = { data }
    if (this.state.info?.values?.colors) {
      newData.color = this.state.info?.values?.colors[this.state.selectedColor]
    }
    newData.type = null
    this.props.actions.reduceFromCart(newData)
  }


  addToCart(data) {
    this.setState({ showSelectColorMessage: false })
    data.type = null

    if (Array.isArray(this.state.info?.values?.colors) && this.state.info?.values?.colors?.length > 0) {

      if (this.state.selectedColor == null || this.state.selectedColor >= this.state.info?.values?.colors.length) {
        this.setState({ showSelectColorMessage: true })
      } else {
        // if (this.state.info?.values?.colors) {
        data.color = this.state.info?.values?.colors[this.state.selectedColor]
        // }

        this.props.actions.addToCart(data)
      }

    } else {

      this.props.actions.addToCart(data)
    }
  }


  getAccessories() {
    this.setState({ selectedTab: 3 })
    if (!this.state.accessories) {
      this.fetchAccessories()
    }
  }

  getPrice() {
    if (this.state.info?.values?.unavailable) {
      return translate('unavailable')
    } else {
      return converNumberToPersian(translate('currency', { price: priceStandardView(this.state.info?.values?.price) }))
    }
  }


  changeImage = (index) => {
    this.setState({ currentImageIndex: index, selectedColor: index })
  }

  changeColor = (index) => {
    this.setState({ currentImageIndex: index, selectedColor: index })
  }

  render() {

    if (!this.state.isLoadingPage) {
      return (
        <Section>

          <FastHelmet title={this.state.info?.values?.name} description={'لیست محصولات اوپیا |' + this.state.info?.values?.name} />


          <div className="container mb-5">
            <div style={{ padding: '90px 0px 50px 0px' }}>

              <div className="row w-100 m-0 p-0">
                <div className="col-12 col-md-6 ">

                  <div className="row d-flex">

                    <div className={"m-0 p-1 d-flex " + "col-12"} style={{ height: 'auto' }}>
                      <button className="p-0 w-100" >
                        <img src={imageAddress(this.state.info?.values?.colors[this.state.currentImageIndex].image, 'resource')} width="100%" height="auto" style={{ objectFit: 'contain', borderRadius: 8, }} alt="" />
                      </button>
                    </div>



                    {!Array.isArray(this.state.info?.values?.images) && (
                      <div className={"m-0 p-1 d-flex col-12"} style={{ height: 'auto' }}>
                        <div className="p-0 d-flex flex-column" >
                          <img src={imageAddress(this.state.info?.values?.images, 'product', 'thumb')} width="100%" height="auto" style={{ objectFit: 'contain', borderRadius: 8, }} />
                        </div>
                      </div>
                    )}
                  </div>


                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div ref={el => this.gallary = el} className="d-flex mt-1 pl-3 pr-3 smooth-scroll no-scrollbar" style={{ flexWrap: 'wrap', justifyContent: 'center' }}>

                      {Array.isArray(this.state.info?.values?.colors) && this.state.info?.values?.colors.map((prop, index) => {
                        // if (prop.image && prop.image != '') {
                        return (
                          <button className="m-0 p-1" key={index} ref={el => this["gallary" + index] = el} onClick={() => this.changeImage(index)} style={{ width: '25%' }}>
                            <img className={"cursor-pointer gallary-image " + (index === this.state.currentImageIndex ? 'active' : '')} key={index} src={imageAddress(prop.image, 'product')} style={{ width: '100%', borderRadius: 8 }} />
                          </button>
                        )
                        // }

                      })}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 pl-4 pr-4 pl-md-5 pr-md-5 mt-4 mt-md-0 pt-4" style={{ position: 'relative', flex: 1 }} >
                  <div className="" style={{ position: 'sticky', top: 90 }}>

                    {this.state.info?.applet != "accessories" && (
                      <p>{this.state.info?.values?.category?.values?.name}</p>
                    )}

                    <div className="mb-3 pb-3" style={{ borderBottom: '1px solid #eee' }}>
                      <div className="flexcb ">
                        <h1 className="mb-0 mt-0" style={{ fontWeight: 'bold', fontSize: 28 }}>{this.state.info?.values?.name}</h1>
                        <h3 className="mb-0 mt-0" style={{ fontSize: 25, fontWeight: 'normal' }}>{this.getPrice()}</h3>
                      </div>
                      {this.state.info?.values?.unit && (<p className={"text-small mt-1"} style={{ whiteSpace: 'nowrap', fontSize: 14, color: '#222' }}>واحد اندازه گیری: {this.state.info?.values?.unit}</p>)}
                    </div>

                    <div className="w-100 page-loader mb-4 mt-4">
                      {this.state.info?.values?.description && (
                        <p className="font-small">{stripHtml(this.state.info?.values?.description)}</p>
                      )}
                      {/* <div dangerouslySetInnerHTML={{ __html: this.state.info?.values?.description }}></div> */}
                    </div>

                    {Array.isArray(this.state.info?.values?.colors) && this.state.info?.values?.colors.length > 0 && (
                      <div className="w-100 page-loader mb-5 mt-4 flexc" style={{ flexWrap: 'wrap' }}>
                        <p style={{ color: '#789', whiteSpace: 'nowrap' }} className="mrd-2 mt-2">{translate('choose-color')}</p>
                        {this.state.info?.values?.colors.map((prop, index) => {
                          return (
                            <button key={index} onClick={() => this.changeColor(index)} className="flexcc mrd-2  py-1 mt-2" style={{ padding: '4px 6px', borderRadius: 20, border: '1px solid ' + (this.state.selectedColor === index ? '#101010' : '#eee'), backgroundColor: (this.state.selectedColor === index ? '#101010' : '#f2f6f8'), transition: 'all 0.5s' }}>
                              <div>
                                <div style={{ border: '1px solid #eee', width: 24, height: 24, borderRadius: 20, backgroundColor: '#' + prop.code }}></div>
                              </div>
                              <p className="font-small mrd-3 mld-2 text-small " style={{ color: this.state.selectedColor === index ? '#fff' : '#789', whiteSpace: 'nowrap' }}>{prop.name}</p>
                            </button>
                          )
                        })}

                      </div>
                    )}


                    {this.showCartBut(this.state.data, this.state.selectedColor)}
                    {this.state.showSelectColorMessage && (
                      <p className="text-center" style={{ color: '#ee5050' }}>{translate('errors.selectColorMessage')}</p>
                    )}

                  </div>
                </div>

              </div>

            </div>



            <div className="row w-100 m-0 ">
              <div className="col-12 ">

                <div className="flexc mb-4 pb-3" style={{ borderBottom: '2px solid #f2f6f8' }}>
                  <button className="mld-2" onClick={() => this.setState({ selectedTab: 0 })} style={{ color: this.state.selectedTab === 0 ? '#202020' : '#89a' }}><p className="mb-0 mt-0 text-semibig transition-all" style={{}}>{translate('product-information')}</p></button>
                  {this.state.info?.values?.specifications && (<button className="mld-2" onClick={() => this.setState({ selectedTab: 1 })} style={{ color: this.state.selectedTab === 1 ? '#202020' : '#89a' }}><p className="mb-0 mt-0 text-semibig transition-all">{translate('product-specifications')}</p></button>)}
                  {this.state.info?.values?.catalogue && (<button className="mld-2" onClick={() => this.setState({ selectedTab: 2 })} style={{ color: this.state.selectedTab === 2 ? '#202020' : '#89a' }}><p className="mb-0 mt-0 text-semibig transition-all">{translate('product-catalogue')}</p></button>)}
                  {this.state.info?.applet == 'products' && (<button className="mld-2" onClick={() => this.getAccessories()} style={{ color: this.state.selectedTab === 3 ? '#202020' : '#89a' }}><p className="mb-0 mt-0 text-semibig transition-all">{translate('accessories')}</p></button>)}

                </div>

                {this.state.selectedTab == 0 && (
                  <div className="w-100 page-loader">
                    <div dangerouslySetInnerHTML={{ __html: this.state.info?.values?.body }}></div>
                  </div>
                )}

                {this.state.selectedTab == 1 && (
                  <div className="w-100 page-loader">
                    <div dangerouslySetInnerHTML={{ __html: this.state.info?.values?.specifications }}></div>
                  </div>
                )}

                {this.state.selectedTab == 2 && (
                  <div className="w-100 page-loader">
                    {/* <p>{JSON.stringify(this.state.info?.values)}</p> */}
                    {this.state.info?.values?.catalogue ? (
                      <div className="mt-2 p-5 text-center" style={{ backgroundColor: '#f2f6f8', borderRadius: 8 }}>
                        <a href={siteConfig.assetsDomain + 'images/' + this.state.info?.values?.catalogue.address} target="_blank" style={{ backgroundColor: '#007aff', padding: '15px 25px', borderRadius: 8, color: '#fff' }}>{translate('download-catalogue')}</a>
                      </div>
                    ) : (
                        <div className="mt-2 p-5 text-center" style={{ backgroundColor: '#f2f6f8', borderRadius: 8 }}>
                          <p style={{ color: '#789' }}>{translate('no-catalogue')}</p>
                        </div>
                      )}
                  </div>
                )}


                {this.state.selectedTab == 3 && (
                  <div className="container mt-4">
                    <div className="row">

                      <div className="col-12 mb-5 mt-4">
                        <DataGrid isLoading={this.state.isLoadingAccessories} data={this.state.accessories} dontShowPrice={true} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={ProductCell} col="col-12 col-md-6 col-lg-3" />
                      </div>
                    </div>
                  </div>
                )}



              </div>
            </div>
          </div>



          {
            this.state.showModal && (
              <div className='flexcc w-100 gallary' ref={el => this.modal = el} style={{ opacity: this.state.modalOpacity, transition: 'all 0.3s', position: 'fixed', top: 0, left: 0, zIndex: 10, backgroundColor: '#778899dd', height: '100vh', width: '100%', backdropFilter: 'blur(30px)', WebkitBackdropFilter: 'blur(30px)' }}>
                <button onClick={() => this.closeModal()} style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}></button>
                <div className="w-100 text-center ">
                  <div className="flexcc w-100">
                    <button className="invert gallary-arrow" onClick={() => this.openGallary(this.state.currentImageIndex - 1)}><img src="https://image.flaticon.com/icons/svg/271/271228.svg" style={{ width: '30px', transform: "rotate(180deg)" }} /></button>
                    <div className="flexcc" style={{ height: '70vh', maxWidth: 'calc(100% - 70px)' }}>
                      <img src={imageAddress(this.state.info?.values?.images[this.state.currentImageIndex], 'product')} style={{ opacity: this.state.dontShowImage ? 0 : 1, transition: 'all 0.2s', maxHeight: '100%', borderRadius: 15, maxWidth: 'calc(100%)', objectFit: 'contain' }} />
                    </div>
                    <button className="invert gallary-arrow" onClick={() => this.openGallary(this.state.currentImageIndex + 1)}><img src="https://image.flaticon.com/icons/svg/271/271228.svg" style={{ width: '30px' }} /></button>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div ref={el => this.gallary = el} className="d-flex mt-1 pl-3 pr-3 smooth-scroll no-scrollbar" style={{ overflow: 'auto' }}>
                      {this.state.info?.values?.images.map((prop, index) => {
                        return (
                          <button className="m-0 p-0" key={index} ref={el => this["gallary" + index] = el} onClick={() => this.openGallary(index)}>
                            <img className={"m-1 cursor-pointer gallary-image " + (index === this.state.currentImageIndex ? 'active' : '')} key={index} src={imageAddress(prop, 'product')} style={{ maxHeight: '20vh', borderRadius: 8 }} />
                          </button>
                        )

                      })}
                    </div>
                  </div>
                </div>
              </div>
            )
          }

        </Section >
      );
    } else {
      return (
        <div style={{ textAlign: 'center', width: '100%', paddingTop: 90, minHeight: '100vh' }}>
          <Loader
            type="Oval"
            color="rgba(20,20,20,1)"
            height="50"
            width="50"
          />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({ cart: state.cart, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product);
