import React from 'react';
import { translate } from '../../utils/useful'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import FormViewer from '../../components/FormViewer'
import Loader from 'react-loader-spinner'
import Modal from '../../components/Modal'
import LoaderButton from '../../components/LoaderButton';
import HttpService from '../../utils/Http.services';

// const Map = new mapboxgl.Map({
//   accessToken:'pk.eyJ1IjoicG91eWFwZXpobWFuIiwiYSI6ImNrZHZwd2JiYTBzeHgyeWtqa2xodmNrdnQifQ.W9n1rw2PX1V1PjrcBDitrA',
//   container: 'map',
//   // style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
//   center: [-74.5, 40], // starting position [lng, lat]
//   zoom: 9 // starting zoom
//   });

class AddressList extends React.Component {


  state = {
    data: [],

    headers: [
      // { title: 'Description', key: 'description', type: 'text', showMain: false, },
      // { title: 'Country', type: 'select', key: 'country', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'countries' } }, settings: { required: false }, showMain: true },
      // { title: 'City', type: 'select', key: 'city', dependencies: [{ type: 'filter', action: 'enable', field: 'country', param: 'values.country' }], options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'cities' } }, settings: { required: true }, showMain: false },
      { type: 'SelectInput', key: 'values.city', information: { label: '{{lang}}province', address: 'getAllWithData', filter: { applet: 'cities' }, sort: "values.name", fields: { title: 'values.name', value: '_id' }, type: 'api', placeholder: '{{lang}}insertCity', required: true }, options: { type: 'api', fields: { value: '_id', title: 'values.name', placeholder: '' }, address: '/values/getAll', filter: { page: 'cities' }, placeholder: translate('select...') } },
      { type: 'TextInput', key: 'values.address', information: { label: '{{lang}}address', placeholder: '{{lang}}insertAddress', required: true } },
      { type: 'TextInput', key: 'values.postalCode', information: { label: '{{lang}}postalCode', placeholder: '{{lang}}insertPostalCode', required: true, }, },
      { type: 'MapInput', key: 'values.map', information: { label: '{{lang}}map',  required: true, }, },

      // { type: 'TextInput', key: 'project', information: { label: 'Project Name', placeholder: '{{lang}}insertAddress', required: true } },
      // { title: 'Recipient Name', type: 'text', key: 'recipient', showMain: true },

      // { title: 'Submit Address', type: 'button', function: 'submit', options: { color: "#000", fontcolor: '#fff' }, showMain: true },
    ]
  }

  componentDidMount() {
    this.getAddresses()
  }

  openModal(index) {

    let data = {}
    if (index != -1) {
      data = { ... this.state.data[index] }
    }
    console.log(data)
    this.setState({ initData: data }, () => {

      this.addAddress.showModal()
    })
  }


  postAddress = () => {
    let data = this.form.getForm()
    console.log(data)
    if (data && data.values?.postalCode && data.values.postalCode.length != 10) {
      // let err = "کد ملی به درستی وارد نشده است" 
      let err = { 'values.postalCode': "{{lang}}errors.postalCode" }
      this.setState({ errors: err })
      data = null
    }

    if (data) {
      this.setState({ isLoading: true, errors: {} })
      data.applet = 'addresses'
      HttpService.request('submitForm', data, (fetchResult, fetchError) => {
        if (fetchError) { this.setState({ errors: fetchError }); return }
        // console.log()
        this.setState({ isLoading: false })
        this.addAddress.hideModal()
        this.getAddresses()
      })
    }
  }

  getAddresses = () => {
    this.setState({ isLoading: true, errors: {} })
    HttpService.request('getAllWithData', { applet: 'addresses', filter: { '**creator': true } }, (fetchResult, fetchError) => {
      this.setState({ isLoading: false })

      if (fetchError) { this.setState({ errors: fetchError.message }); return }
      console.log(fetchResult.info)
      this.setState({ data: fetchResult.info }, () => {
        this.initData()
      })
      // this.getAddresses()
    })

  }


  initData() {
    if (Array.isArray(this.state.data) && this.props.data.address?._id) {
      this.state.data.forEach((oneData, index) => {
        if (oneData._id === this.props.data.address?._id) {
          this.setState({ selectedIndex: index })
        }
      });
    }
  }


  getInfo = (cb) => {
    if (this.state.selectedIndex >= 0) {
      let values = this.state.data[this.state.selectedIndex].values
      values.city = values.city._id
      values._id = this.state.data[this.state.selectedIndex]._id
      let data = { address: values }
      cb(data)
    } else {
      cb(null, "{{lang}}errors.selectAddress")
    }
    // return data
  }





  render() {


    return (
      <section>

        <div style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>
          <div className="p-3" style={{ backgroundColor: '#e0e6e8' }}>
            <p className="font-bold text-center" style={{ fontSize: 18 }}>{translate('addressList')}</p>
          </div>


          <div className="w-100" style={{ padding: '20px 25px 0px 25px', paddingBottom: this.state.data.length == 0 ? '25px' : '0px' }}>

            <div className="text-right mt-2" style={{ padding: 30, paddingTop: 10, paddingBottom: 15, borderRadius: 8, backgroundColor: '#f2f6f8' }}>
              <div className="mt-2" style={{ alignItems: 'center', display: 'flex' }}>

                <div style={{ flex: 2, textAlign: 'center', padding: 15 }}>
                  {this.state.data.length === 0 && !this.state.isLoading && (
                    <div>
                      <img src="https://image.flaticon.com/icons/svg/1076/1076323.svg" width="70px" alt="" />
                      <p className="mediumiransansfont mt-3" style={{ fontSize: 14, color: '#556080', textAlign: 'center' }}>{translate('no-address')}</p>
                    </div>
                  )}

                  {this.state.data.length !== 0 && !this.state.isLoading && (
                    <div>
                      {/* <img src="https://image.flaticon.com/icons/svg/1076/1076323.svg" width="70px" /> */}
                      <p className="mediumiransansfont" style={{ fontSize: 14, color: '#556080', textAlign: 'center', fontWeight: 'bold' }}>{translate('choose-address')}</p>
                    </div>
                  )}

                  <div className="d-inline-flex">
                    <p onClick={() => this.openModal(-1)} className="mediumiransansfont mt-2" style={{ fontSize: 14, color: '#fff', backgroundColor: '#007aff', borderRadius: 4, textAlign: 'center', cursor: 'pointer', padding: '7px 15px', fontWeight: 'bold' }}>+ {translate('add-address')}</p>
                  </div>
                </div>
              </div>
            </div>


            {this.state.isLoading && (
              <div style={{ textAlign: 'center', width: '100%', paddingTop: 20, marginBottom: 10 }}>
                <Loader
                  type="Oval"
                  color="rgba(0,122,255,1)"
                  height="50"
                  width="50"
                />
              </div>
            )}

            {this.state.data.map((prop, index) => {
              return (

                <div key={index} className="" style={{ padding: 20, borderBottom: '1px solid #f2f6f8', borderRadius: 8 }}>
                  <div style={{ alignItems: 'center', display: 'flex' }}>
                    <div onClick={() => this.setState({ selectedIndex: index })} style={{ cursor: 'pointer', flex: 2, display: 'flex', alignItems: 'center' }}>
                      <div>
                        <div style={{ backgroundColor: this.state.selectedIndex == index ? '#007aff' : '#cde', border: '3px solid #cde', transition: 'all 0.5s', width: 20, height: 20, borderRadius: 20, }}>
                        </div>
                      </div>

                      <div className=" mr-3 ml-3">
                        <p style={{ fontSize: 14, color: '#111', fontWeight: 'bold' }}>{prop.values.city?.values?.name}</p>
                        <p style={{ fontSize: 14, color: '#111' }}>{prop.values.address}</p>
                        <p style={{ fontSize: 13, color: '#456' }}>{translate("postalCodeInfo", { code: prop.values.postalCode })}</p>
                      </div>
                    </div>

                    <div >
                      <div style={{ display: 'inline-flex' }}>
                        <button onClick={() => this.openModal(index)} className=" font-bold" style={{ fontSize: 14, color: '#567', backgroundColor: '#f2f6f8', padding: "7px 15px", borderRadius: 4 }}>{translate('edit')}</button>
                      </div>
                    </div>
                  </div>
                </div>

              )
            })}


            <Modal ref={el => this.addAddress = el} maxWidth={400} fullHeight={true}>
              <div className="text-left">

                <div className="p-3" style={{ backgroundColor: '#e0e6e8' }}>
                  <p className="font-bold text-center" style={{ fontSize: 18 }}>{translate('addAddress')}</p>
                </div>

                <div className="w-100" style={{ padding: '20px 25px' }}>
                  <FormViewer ref={el => this.form = el} headers={this.state.headers} initData={this.state.initData} errors={this.state.errors} inputClass={'modern-input'} />
                  
                  <div className="text-center">
                    <LoaderButton
                      onClick={this.postAddress}
                      isLoading={this.state.isLoading}
                      text={translate('submit')}
                      type={"Oval"}
                      className="mt-4 mb-4"
                      buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                      width={40}
                      height={40}
                      color={'#202020'}
                    />
                  </div>

                </div>
              </div>
            </Modal>

          </div>
        </div>

      </section >
    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user, cart: state.cart })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null, { forwardRef: true }
)(AddressList);


