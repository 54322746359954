import React from 'react'
import i18n from 'i18next';
import { Link } from 'react-router-dom'
import { siteConfig } from '../variables/config'

class TLink extends React.Component {

    defaultMaker() {
        return siteConfig.dontShowUrlForDefaultLng ? (i18n.language === siteConfig.defaultLng ? '' : '/' + i18n.language) : '/' + i18n.language
    }

    render() {
        let to = {}
        if(this.props.pathname){
            to.pathname = this.defaultMaker() + this.props.pathname 
        }
        if(this.props.to){
            to.pathname = this.defaultMaker() + this.props.to 
        }
        if (this.props.search) {
            to.search = this.props.search
        }
        return (
            <Link className={this.props.className} style={this.props.style} to={to}>
                {this.props.children}
            </Link>
        )
    }
}

export default (TLink);