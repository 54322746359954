import React from "react";
import Modal from "../../components/Modal";



class Home extends React.Component {

  state = {

    isLoadingPage: true,
    similarData: [],
    data: {},
    tabbarData: [{ title: 'ملک های مشابه' }],
  }

  componentWillMount() {
    // this.fetch()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.location != newProps.location) {
      this.setState({ isLoadingPage: true, data: {}, similarData: [] }, () => {
        // this.fetch()
      })
    }
  }


  // fetch = () => {

  //   let search = this.props.location.search;
  //   let params = new URLSearchParams(search);
  //   let id = params.get('id');

  //   fetch(domain + `/values/getOneEstate`, {
  //     method: 'POST',
  //     body: JSON.stringify({ id: id }),
  //     headers: headers
  //   })
  //     .then(response => response.json())
  //     .then(data => {

  //       if (data && data.info) {
  //         this.setState({
  //           data: data.info[0],
  //           isLoadingPage: false,
  //         })

  //         let type = 'rent'
  //         // console.log()
  //         if (data.info[0].values.transaction == 'فروش') {
  //           type = 'sale'
  //         }
  //         this.fetchsimilar({ transaction: type })

  //       }
  //     })
  //     .catch(error => this.setState({ error, isLoading: false }));
  // }



  // fetchsimilar = (filter) => {
  //   // console.log(filter)

  //   for (const [key, value] of Object.entries(filter)) {
  //     if (value == null || value == '')
  //       delete filter[key]
  //   }
  //   // filter["transaction"] = "rent"
  //   fetch(domain + `/values/getEstates`, {
  //     method: 'POST',
  //     body: JSON.stringify({ filter: filter }),
  //     headers: headers
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data && data.info) {
  //         this.setState({
  //           similarData: data.info,
  //           // isLoading: false,
  //         })
  //       }
  //     })
  //     .catch(error => this.setState({ error, isLoading: false }));
  // }

  // changeTab(item) {

  // }


  loadInfo(values) {
    let dictionary = { area: 'مساحت', rooms: 'تعداد اتاق', region: 'محله', age: 'سال ساخت', type: 'نوع ملک', direction: 'جهت ملک', parking: 'تعداد پارکینگ' }
    let info = []
    for (const [key, value] of Object.entries(values)) {
      if (value)
        info.push({ key: key, value: value, extra: "extra" })
    }

    return (
      info.map((prop, index) => {
        return (
          <div key={index} className="col-3 col-md-2 col-lg-1">
            <div className="d-flex align-items-center flex-column mt-2">
              <img src={"./img/icons/" + prop.key + '.svg'} width='40px' height='40px' />
              <p style={{ fontSize: 12, marginLeft: 0, textAlign: 'center', opacity: 0.7, whiteSpace: 'nowrap', marginTop: 5 }}>{prop.value}</p>
              <p style={{ fontSize: 10, marginLeft: 0, textAlign: 'center', opacity: 0.5, whiteSpace: 'nowrap' }}>{dictionary[prop.key]}</p>
            </div>
          </div>
        )
      })
    )


  }



  showPrice() {
    if (this.state.data.values.transaction) {
      if (this.state.data.values.price) {
        return (
          <p className="boldiransansfont" style={{ fontSize: 20 }}>{this.state.data.values.price} تومان</p>
        )
      } else {
        return (
          <p className="boldiransansfont" style={{ fontSize: 20 }}>قیمت توافقی</p>
        )
      }
    } else {
      if (this.state.data.values.price || this.state.data.values.rentprice) {
        return (
          <div>
            <p className="boldiransansfont" style={{ fontSize: 20 }}>ودیعه {this.state.data.values.price ? this.state.data.values.price : 0} تومان</p>
            <p className="boldiransansfont" style={{ fontSize: 20 }}>اجاره {this.state.data.values.rentprice ? this.state.data.values.rentprice : 0} تومان</p>
          </div>
        )
      } else {
        return (
          <p className="boldiransansfont" style={{ fontSize: 20 }}>قیمت توافقی</p>
        )
      }
    }

  }



  render() {
    if (!this.state.isLoadingPage) {
      return (

        <div className=" mediumiransansfont" style={{ flexDirection: 'column', width: '100%', overflow: 'hidden', backgroundColor: '#fff', direction: 'rtl' }}  >

          <div className="" style={{ position: 'relative', paddingLeft: '3%', paddingRight: '3%' }}>
            <div className='row' >


              <div className='col-12 mt-5 text-center'>
                <div style={{ paddingLeft: 10, paddingRight: 10, width: '100%', justifyContent: 'space-between', alignItems: 'flex-center', display: 'flex', flexDirection: 'row', padding: 10 }}>

                  <div className={'d-flex'}>
                    <div style={{ backgroundImage: 'url(/img/b-logo.png)', backgroundSize: 'cover', backgroundPosition: 'center', width: 50, height: 50, borderRadius: 25, marginTop: 10 }}></div>
                    <div style={{ textAlign: 'right', marginLeft: 10, marginRight: 10 }}>
                      <p className="boldiransansfont" style={{ margin: 0, marginTop: 10, color: '#202020', fontSize: 18 }}>{this.state.data.values.transaction} {this.state.data.values.info.type} {this.state.data.values.info.area} متری</p>
                      <p style={{ margin: 0, color: '#202020', opacity: 1, fontSize: 10 }}><span><i className="fa fa-map-pin ml-2"></i></span>کرج - {this.state.data.values.info.region}</p>
                    </div>
                  </div>

                  <div className="mt-2">
                    <div onClick={() => this.refs.infoModal.showModal()} style={{ cursor: 'pointer', backgroundColor: '#007aff', color: '#fff', padding: 7, paddingLeft: 15, paddingRight: 15, borderRadius: 6 }}>
                      <p>ارتباط با مشاور</p>
                    </div>
                  </div>

                </div>
              </div>



              <div className='col-12 mt-1 text-center pt-0'>

                <img ref={"mainImage"} src={this.state.data.values.images.length > 0 ? "http://maskanone.com/images/" + this.state.data.values.images[0] : "/img/no-image.jpg"} style={{ width: '95%', maxWidth: '800px', borderRadius: 8 }} />

                <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                  <div className=' mt-3 text-center' style={{ width: '95%', maxWidth: '800px', borderRadius: 8 }}>
                    <div className=" mb-2 mediumiransansfont text-right " style={{ marginTop: 10 }}>
                      <div className="container" style={{ backgroundColor: '#f7f7f7', borderRadius: 8, padding: 30 }}>
                        <div className="row">
                          <div className="col-12 col-md-6">

                            {this.showPrice()}

                            <div className="d-flex" style={{ color: '#202020', marginTop: 8 }}>
                              <div className="d-flex align-items-center">
                                <p style={{ fontSize: 12, marginLeft: 4, opacity: 0.7 }}>{this.state.data.values.info.area} متر</p>
                                <img src="/img/bed.svg" width='22px' height='22px' style={{ opacity: 0.5 }} />
                              </div>

                              <div className="d-flex align-items-center mr-1">
                                <p style={{ fontSize: 12, marginLeft: 4, opacity: 0.7 }}>{this.state.data.values.info.rooms} خواب</p>
                                <img src="/img/room.svg" width='22px' height='22px' style={{ opacity: 0.5 }} />
                              </div>

                              <div className="d-flex align-items-center mr-1">
                                <p style={{ fontSize: 12, marginLeft: 4, opacity: 0.7 }}>{this.state.data.values.info.type}</p>
                                <img src="/img/type.svg" width='22px' height='22px' style={{ opacity: 0.5 }} />
                              </div>
                            </div>
                            <p style={{ margin: 0, color: '#202020', opacity: 0.7, fontSize: 11, marginTop: 10, marginBottom: 10 }}>واقع در کرج - {this.state.data.values.info.region}</p>
                          </div>
                          <div className="col-12 col-md-6 d-flex  justify-content-end align-items-start">
                            <div className="mt-2 d-inline-block">
                              <div onClick={() => this.refs.infoModal.showModal()} style={{ cursor: 'pointer', backgroundColor: '#007aff', color: '#fff', padding: 7, paddingLeft: 15, paddingRight: 15, borderRadius: 6 }}>
                                <p>ارتباط با مشاور</p>
                              </div>

                              {/* <div className="mt-1" onClick={() => this.refs.infoModal.showModal()} style={{ cursor: 'pointer', backgroundColor: '#e1e2e3', color: '#999', padding: 7, paddingLeft: 15, paddingRight: 15, borderRadius: 6 }}>
                                <p>اشتراک گذاری</p>
                              </div> */}
                            </div>

                          </div>
                          <div className="col-12">
                            <hr></hr>
                            <p style={{ color: '#999', fontSize: 13 }}>{this.state.data.values.description}</p>
                          </div>
                        </div>
                      </div>

                    </div>



                  </div>

                </div>


                <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                  <div className=' mt-3 text-center' style={{ width: '95%', maxWidth: '800px', borderRadius: 8 }}>
                    <div className=" mb-2 mediumiransansfont text-right " style={{ marginTop: 0 }}>
                      <div className="container" style={{ backgroundColor: '#f7f7f7', borderRadius: 8, padding: 30 }}>
                        <div className="row">
                          <div className="col-12">
                            <p className="boldiransansfont" style={{ fontSize: 20 }}>مشخصات ملک</p>

                            <div className="row" style={{ color: '#202020', marginTop: 8 }}>
                              {this.loadInfo(this.state.data.values.info)}
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>



                  </div>

                </div>

              </div>



              <div className="col-12  mb-5 mediumiransansfont text-center " style={{ marginTop: 10 }}>
                <div className="container">

                  {/* <Tabbar data={this.state.tabbarData} onClick={this.changeTab} pageViewer={this.refs.pageViewer} />
                  <TabbarViewer ref={'pageViewer'} pages={['Estate']} data={this.state.similarData} /> */}

                  <div style={{ display: 'inline-block', marginTop: 30 }}>
                    <div style={{ color: '#ffffffee', backgroundColor: '#13191d', borderRadius: 30, padding: 10, paddingLeft: 25, paddingRight: 25, fontSize: 12 }}>
                      <p>مشاهده بیشتر</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>

          </div>


          <Modal className="iransans" ref={'infoModal'} maxWidth={500} style={{ alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <div>
              <p className="boldiransansfont" style={{ fontSize: 15, textAlign: 'center' }}>ارتباط با گروه املاک یک</p>
              <div className="row mt-3">

                <div className={"col-6 mt-2 mb-2 text-center"}>
                  <img src="/img/phone.png" width="70"></img>
                  <p style={{ fontSize: 12, opacity: 0.4 }}>شماره تماس</p>
                  <p className="boldiransansfont" style={{ fontSize: 14, marginTop: 5 }}>۰۹۳۸۱۴۶۹۰۳۰</p>
                </div>

                <div className={"col-6 mt-2 mb-2 text-center"}>
                  <img src="/img/location.png" width="70"></img>
                  <p style={{ fontSize: 12, opacity: 0.4 }}>آدرس</p>
                  <p className="boldiransansfont" style={{ fontSize: 14, marginTop: 5 }}>گوهردشت مابین دهم و یازدهم غربی مسکن یک</p>
                </div>

              </div>
            </div>

          </Modal>


        </div>
      );
    } else {
      return (
        <div></div>
      )
    }
  }
}

export default Home;
