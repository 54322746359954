import React from "react";
// import CoverImage from '../components/CoverImage'
import Loader from 'react-loader-spinner'
// import { domain, headers, contentUrl } from '../Variables'
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
import HttpService from '../utils/Http.services';
import { siteConfig, siteTheme } from "../variables/config";
import { translate } from "../utils/useful";


class Verify extends React.Component {

  state = {
    isLoading: true,
    list: [],
    success: false,
    refId: ''
  }

  componentWillMount() {
    // this.props.clearAllCart()
    this.fetch()
  }


  fetch = () => {

    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    let authority = params.get('Authority');

    if (params.get('Status') == "OK") {
      const cookies = new Cookies();
      let token = cookies.get('token');
      if (token) {
        if (id) {
          this.setState({ isLoading: true })

          HttpService.request('verifyPayment', { id: id, authority: authority }, (fetchResult, fetchError) => {
            this.setState({ isLoading: false })
            if (fetchError) {
              this.setState({
                isLoading: false,
                success: false,
                error: fetchResult.message
              })
              return
            }
            this.setState({
              list: fetchResult.list,
              isLoading: false,
              success: true,
              refId: fetchResult.refId
            })
            this.props.clearAllCart()

          })

        } else {
          this.props.history.goBack()
        }
      } else {
        this.props.history.goBack()
      }
    } else {
      this.setState({
        isLoading: false,
        success: false,
        // error: data.message
      })
    }
  }


  render() {
    return (
      <div className="mediumiransansfont flexcc" style={{ backgroundColor: '#f2f6f8', minHeight: '100vh', paddingTop: 70, paddingBottom: 100 }}>

        <div style={{ textAlign: 'center', backgroundColor: '#fff', boxShadow: '0px 0px 35px rgba(0,0,0,0.1)', borderRadius: 15, width: '90%', maxWidth: '700px', margin: 'auto', padding: '50px 40px' }}>
          <img src={siteTheme.logo_s} width="200px" className="mb-3 " />
          {/* <p style={{ textAlign: "center", fontSize: 18, opacity: 1 }}>وضعیت پرداخت</p> */}
          {!this.state.isLoading && (
            <div>
              {this.state.success && (
                <div >
                  <div style={{ backgroundColor: '#f2f6f8', borderRadius: 15, padding: '30px 20px', marginBottom: 10 }}>
                    <p className="font-bold text-big" style={{ textAlign: "center", color: '#222', }}>{translate('payment-sucessful')}</p>
                    <p className="mt-2 text-small opacity-5" >{translate('refId', { id: this.state.refId })}</p>
                    <p className="mt-2 text-small opacity-5">{translate('shopping-thanks')} - {siteConfig.name}</p>
                  </div>
                  <div>
                  </div>


                </div>
              )}
              {!this.state.success && (
                <div>
                  <p className="text-big mb-2 mt-3" style={{ textAlign: "center" }}>{translate('payment-faild')}</p>
                  <p style={{ textAlign: "center", fontSize: 14, opacity: 0.7 }}>{translate('payment-error')}</p>
                </div>
              )}
            </div>
          )}

          {this.state.isLoading && (
            <div style={{ textAlign: 'center' }}>
              <Loader
                type="Oval"
                color="rgba(0,122,255,1)"
                height="40"
                width="40"
              />
            </div>
          )}

        </div>
      </div>


    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Verify);
