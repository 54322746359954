import React from "react";
import Dropzone from 'react-dropzone'
import { checkTextTranslation } from "../../utils/useful";
// import { imageAddress } from "../../useful.js";


class FileInput extends React.Component {

    state = {
        images: [],
        files: [],
        oldFiles: null

        // showVideo: false,

    }


    componentDidMount() {

        if (this.props.data) {
            let data = JSON.parse(JSON.stringify(this.props.data))
            this.setState({ oldFiles: Array.isArray(data) ? data : [data] })
        } else {
            this.setState({ oldFiles: null })
        }

        if (this.props.files) {
            this.setState({ files: this.props.files })
        }
    }

    componentDidUpdate(preProps) {
        if (preProps.data != this.props.data) {
            let data = JSON.parse(JSON.stringify(this.props.data))
            console.log(data)
            if (data && data != '' && data != []) {
                this.setState({ oldFiles: Array.isArray(data) ? data : [data] })
            } else {
                this.setState({ oldFiles: [] })
            }
        }
        if (this.props.files && preProps.files != this.props.files ) {
            this.setState({ files: this.props.files })
        }
        // console.log(preProps)
    }


    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] == condition) {
                return true
            }
        }
        return false
    }

    render() {
        const dropzoneRef = React.createRef();
        let info = this.props.header.information

        return (
            <div className="flexcc w-100 p-3 flex-column">
                {/* <label for={this.props.header.key} className={'mb-2 mr-1 ml-1 boldiransansfont ' + (this.conditionalSettings('required', true) ? 'required' : '')} style={{ marginBottom: 3, marginTop: 5, fontSize: 13, color: '#456', fontWeight: 'bold' }}>{this.props.label}</label> */}

                {(this.state.images.length > 0 && this.props.options && this.props.options.single) ? (<div></div>) : (



                    <Dropzone
                        ref={dropzoneRef}
                        multiple={!info.single && !info.corpper ? true : false}
                        // accept='image/jpeg'
                        onDrop={this.onDrop.bind(this)}
                        style={{ flex: 1, cursor: 'pointer' }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className="text-center w-100 outline-none" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <img src={'https://image.flaticon.com/icons/svg/3028/3028771.svg'} width={60} />
                                <p className="" style={{ opacity: 1, fontSize: 13, marginTop: 3, color: '#007affdd' }}>{checkTextTranslation(info.placeHolder ? info.placeHolder : '{{lang}}choose-file')}</p>
                            </div>
                        )}
                    </Dropzone>
                )}


                <div className="p-0 m-0 mt-1" style={{ display: 'inline-flex', flexWrap: 'wrap' }}>

                    {this.state.oldFiles && Array.isArray(this.state.oldFiles) && this.state.oldFiles.map((prop, index) => {
                        return (
                            <div style={{ position: 'relative' }}>
                                <a href={prop} target="_blank">
                                    <img className={"m-1 mt-3"} src={'/images/file.png'} height="60px" style={{ borderRadius: 4 }} />
                                    <p style={{ maxWidth: 90 }} className="text-center">{prop.address}</p>
                                </a>
                                <div onClick={() => { this.removeOldFile(index) }} style={{ position: 'absolute', top: 5, left: 0, backgroundColor: '#ee5050dd', borderRadius: '50%', width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, cursor: 'pointer' }}><p style={{ color: '#fff', marginTop: 4 }}>X</p></div>
                            </div>
                        )
                    })}

                    {/* {this.state.oldFiles && !Array.isArray(this.state.oldFiles) && (
                            <div style={{ position: 'relative' }}>
                                <a href={imageAddress(this.state.oldFiles)} target="_blank">
                                    <img className={"m-1 mt-3"} src={'/images/file.png'} height="60px" style={{ borderRadius: 4 }} />
                                    <p style={{ maxWidth: 90 }} className="text-center">{this.state.oldFiles}</p>
                                </a>
                                <div onClick={() => { this.removeOldFile(0) }} style={{ position: 'absolute', top: 5, left: 0, backgroundColor: '#ee5050dd', borderRadius: '50%', width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, cursor: 'pointer' }}><p style={{ color: '#fff', marginTop: 4 }}>X</p></div>
                            </div>
                        )} */}

                    {this.state.files.map((prop, index) => {
                        // console.log(prop)
                        return (
                            <div style={{ position: 'relative', marginLeft: 5, marginRight: 5, textAlign: 'center' }}>
                                <img className={"m-1 mt-3"} src={'https://image.flaticon.com/icons/svg/660/660717.svg'} height="60px" style={{ borderRadius: 4 }} />
                                <p style={{ fontSize: 12, maxWidth: '80px', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{prop.name}</p>
                                <div onClick={() => { this.removeFile("key", index) }} style={{ position: 'absolute', top: 5, left: 0, backgroundColor: '#ee5050dd', borderRadius: '50%', width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, cursor: 'pointer' }}><p style={{ color: '#fff', marginTop: 4 }}>X</p></div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }


    removeOldFile(index) {
        var oldFiles = this.state.oldFiles
        oldFiles.splice(index, 1)
        this.setState({ oldFiles })
        this.props.onChange(this.props.header.key, oldFiles, this.props.extra)
    }


    onDrop(files) {

        var allFiles = this.state.files
        files.forEach(file => {
            allFiles.push(file)
        });
        this.setState({ files: allFiles })
        this.props.changeFiles(allFiles, this.props.header.key, 'file')

    }





    removeFile(key, index) {
        // var images = this.state.images
        var files = this.state.files
        // images.splice(index, 1)
        files.splice(index, 1)
        this.setState({ files })
    }
}

export default FileInput;
