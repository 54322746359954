import React from 'react'
import LoaderButton from '../LoaderButton'
import FormViewer from '../FormViewer'
import { chooseWallpaper, phoneStandardView, translate } from "../../utils/useful";

// import HttpService from '../../utils/Http.services';

class LoginCodeStep extends React.Component {
    state = {
        key: '',
        selectedCountry: {},
        resendTime: 60,
        resendRequested: false,
        // errors:{},
        formHeaders: [{ key: 'code', type: 'NumberInput', information: { label: '{{lang}}activationCode', icon: '/images/code.svg', iconWidth: 35, iconHeight: 35, placeholder: '{{lang}}activationCode', required: true } }]
    }

    componentDidMount() {
        // this.changeResendTime()
        window.addEventListener("keypress", this.keyPress)
        this.timer = setInterval(this.checkResendTime, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener("keypress", this.keyPress)
        if (this.timer) clearInterval(this.timer)
    }


    keyPress = (e) => {
        if (e?.keyCode == 13) {
            this.postActicationCode()
        }
    }

    postActicationCode = () => {
        this.setState({  errors: {} })
        let data = this.form.getForm()
        if (data) {
            this.setState({isLoading: true,})
            this.props.setData(data, () => {
                this.props.postActicationCode((result, err) => {
                    if (err) { this.setState({ errors: err }) }
                    this.setState({ isLoading: false })
                })
            })
        }
    }

    requestResend() {
        if (!this.state.resendRequested) {
            this.setState({ resendRequested: true })
            this.props.postUserIndicator((result, err) => {
                this.setState({ resendRequested: false })
                if (err) { return }
                this.setState({ canResend: false, resendTime: 60 })
            })
        }
    }

    checkResendTime = () => {
        if (this.state.resendTime > 0) {
            this.setState({ resendTime: this.state.resendTime - 1 })
        } else { this.setState({ canResend: true }) }
    }




    render() {
        return (

            <div className="flexcc flex-column">
                <h2 className="mt-4 " style={{ fontSize: 27 }}>{translate('activation-code').toUpperCase()}</h2>
                <div className="w-100" style={{ maxWidth: 400 }}>

                    <FormViewer ref={el => this.form = el} headers={this.state.formHeaders} initData={this.props.initData} errors={this.state.errors} inputClass={'modern-input'} />

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.postActicationCode}
                            isLoading={this.state.isLoading}
                            text={translate('send-code')}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />

                        <p className="text-small">{phoneStandardView(this.props.initData.userIndicator)}</p>
                        <button className="mb-3" onClick={() => this.props.changeStage('init')} style={{ fontSize: 15, }}>{translate('change-phone')}</button>
                        <p style={{ fontSize: 13, opacity: 0.5, marginTop: 0 }}>{translate('if-not-recived-code')}</p>
                        {this.state.canResend ? (
                            <button onClick={() => this.requestResend()} className="flatButton mt-2" style={{ fontSize: 13, opacity: this.state.resendRequested ? 0.5 : 1, transition: 'all 0.4s', marginTop: 0 }}>{translate('request-resend')}</button>
                        ) : (
                                <p className="mt-2" style={{ fontSize: 13, opacity: 0.5, marginTop: 0 }}>{translate('resend-code-in', { seconds: this.state.resendTime })}</p>
                            )}

                    </div>
                </div>
            </div>

            // <div>
            //     <div style={{ maxWidth: 400, width: '100%' }}>
            //         <p style={{ margin: '30px 0px 20px 0px', textAlign: 'left', fontWeight: 'bold' }}>Activation Code *</p>
            //         <div style={{ padding: '5px 20px', display: 'flex', alignItems: 'center', backgroundColor: '#fff', border: '1px solid #f2f4f6', height: 45, width: '100%', borderRadius: 2 }}>
            //             <img src='https://image.flaticon.com/icons/svg/15/15358.svg' height="30px" style={{ marginRight: 10 }} />
            //             <input placeholder={'Activation Code'} style={{ outline: 'none', backgroundColor: 'transparent', border: 'none', fontSize: 16, height: '100%', width: '100%' }} />
            //         </div>

            //         <button style={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', margin: '30px 20px 20px 5px', color: '#fff', border: 'none' }}>
            //             SEND CODE
            //             </button>



            //     </div>
            // </div>
        )
    }
}

export default LoginCodeStep;