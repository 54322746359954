import React from 'react';
import { translate } from '../utils/useful'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
import Section from '../components/Section'
import StagesManager from '../components/StagesManager';
import TLink from '../components/TLink';

import PanelTabs from './me/PanelTabs';
import MeMainPage from './me/MeMainPage';
import MeProfile from './me/MeProfile';
import MeResources from './me/MeResources'
import { pathMaker } from '../utils/useful'
import MeOrders from './me/MeOrders';
import Cookies from "universal-cookie";
import Loader from 'react-loader-spinner'
import MeChangePassword from './me/MeChangePassword';
import MeWallet from './me/MeWallet';
import MeBookmark from './me/MeBookmark';
import MeAds from './me/MeAds';
import MeAccounts from './me/MeAccounts';


class Me extends React.Component {

  state = {
    tabs: [
      // { title: 'My Equipment Reservations', key: 'equipmentsRentals', check: 'isCounterpart', icon: 'https://image.flaticon.com/icons/svg/292/292063.svg' },
      // { title: 'Manage My Equipments', key: 'equipments', check: 'isCounterpart', icon: 'https://image.flaticon.com/icons/svg/292/292038.svg' },
      // { title: 'List My Equipment', key: 'addnew', check: 'isCounterpart', icon: 'https://image.flaticon.com/icons/svg/292/292057.svg' },
      { title: '{{lang}}dashboard', key: 'dashboard', icon: 'dashboard', showInDashboard: false },
      { title: '{{lang}}myProfile', key: 'profile', icon: 'user', showInDashboard: true },
      { title: 'علاقه‌مندی‌ها', key: 'bookmarks', icon: 'bookmark', showInDashboard: true },

      // { title: '{{lang}}myResources', check: 'resources', key: 'resources', icon: '/images/myResources.svg', showInDashboard: true, roles: [{ role: 'partner' }] },
      { title: 'آگهی های من', key: 'ads', icon: 'book', showInDashboard: true },
    
      { title: '{{lang}}changePassword', key: 'changePassword', icon: 'key', showInDashboard: true },
      // { title: 'حساب ها', key: 'accounts', icon: 'book', showInDashboard: false },

    ]
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    const cookies = new Cookies();
    let token = cookies.get('token');

    if (token) {
      this.setState({ isLoading: true })
      this.waitForId()
    } else {
      this.setState({ unautherized: true })
    }
    // this.checkDefaultTab()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname != prevProps.location.pathname) {
      this.checkDefaultTab()
    }
  }



  checkDefaultTab() {
    let pathname = window.location.pathname.split('/')
    pathname = pathname.length == 2 ? '' : pathname[pathname.length - 1]
    let def = ''

    // this.state.tabs.forEach(tab => {
    //   if (tab.key.toLowerCase() == pathname.toLowerCase()) {
    def = pathname
    //   }
    // });
    // console.log(def)
    if (def == '') {
      // this.stageManager.changeStage('dashboard')
      this.props.history.push(pathMaker('/me'))
      this.setState({ current: '' })

    } else {
      this.stageManager.changeStage(def)
      this.setState({ current: def })

    }
  }

  changePage = (page) => {
    this.props.history.push(pathMaker('/me/' + page))
    this.stageManager.changeStage(page)
    this.setState({ current: page })
    // console.log(page)
  }


  waitForId = () => {

    if (this.props.user.info._id) {
      this.setState({ isLoading: false }, () => {
        this.checkDefaultTab()
      })

      // this.setUser()
    } else {
      setTimeout(() => {
        this.waitForId()
      }, 500);

    }
  }

  // setUser() {

  //   // let user = { values: {} }
  //   // user._id = this.props.user.info._id
  //   console.log(this.props.user.info)

  //   // this.state.headers.forEach(header => {
  //   //   if (header.type != 'button')
  //   //     user.values[header.key] = this.props.user.info[header.key]
  //   // });

  //   this.setState({ isLoading: false })
  //   this.setState({ user: this.props.user.info })

  // }

  setUserNewInfo = (data) => {
    this.props.actions.setUser(data, null, true)
  }

  // componentDidUpdate(prevProps){
  //   if(prevProps.user!== this.props.user){
  //     this.setUser()
  //   }
  // }




  checkPermission(role) {
    // if (!page.roles) {
    //   return true
    // }
    // if (reference.info) {
    //   for (let i = 0; i < page.roles.length; i++) {
    //     const oneRole = page.roles[i];
    if (role === this.props.user.info.role) {
      return true
    }
    // }
    // }
  }



  render() {

    return (
      <Section background="#f2f6f8" minHeight="100vh">
        <div className="container" style={{ paddingTop: 40, paddingBottom: 100 }}>


          {this.state.unautherized ? (
            <div style={{ textAlign: 'center', width: '100%', padding: '30px', backgroundColor: '#fff', borderRadius: 8 }}>
              <h1 className="text-mega-big">۴۰۱</h1>
              <p className="mt-2">شما اجاره دسترسی به این صفحه را ندارید</p>
              <div className="mt-3" >
                <TLink to={'/login'} style={{ fontSize: 18, backgroundColor: '#222', color: '#fff', padding: '8px 15px', borderRadius: 8, fontWeight: 'bold' }}>ورود</TLink>
              </div>
            </div>
          ) : (

              <div className="row">
                <div className="col-12 col-md-5  col-lg-4 d-none d-md-block">
                  <PanelTabs tabs={this.state.tabs} user={this.props.user.info} changePage={this.changePage} />
                </div>

                {this.state.current != 'dashboard' && this.state.current != '' && (
                  <div className="mb-4 col-12 col-md-4 d-block d-md-none">
                    <button onClick={() => this.changePage('dashboard')} style={{ fontSize: 16 }}>
                      <span><i className="fa fa-long-arrow-right mx-2"></i>داشبورد</span>
                    </button>
                  </div>
                )}

                <div className="col-12 col-md-7 col-lg-8">

                  {this.state.isLoading && (
                    <div style={{ textAlign: 'center', width: '100%', padding: '30px', backgroundColor: '#fff', borderRadius: 8 }}>
                      <Loader
                        type="Oval"
                        color="rgba(0,122,255,1)"
                        height="50"
                        width="50"
                      />
                    </div>
                  )}

                  {!this.state.isLoading && (

                    <StagesManager ref={el => this.stageManager = el}>

                      <MeMainPage user={this.props.user.info} tabs={this.state.tabs} changePage={this.changePage} stageName={"dashboard"} stage={0} />
                      <MeProfile user={this.props.user.info} postUserInfo={this.postUserInfo} setUserNewInfo={this.setUserNewInfo} stageName={"profile"} stage={1} />
                      {this.checkPermission("partner") ? (
                        <MeResources stageName={"resources"} stage={2} />
                      ) : (<div></div>)}
                      <MeOrders stageName={"orders"} stage={2} />
                      <MeBookmark stageName={"bookmarks"} stage={2} />
                      <MeAds stageName={"ads"} stage={2} />
                      <MeChangePassword stageName={"changePassword"} stage={3} />
                      <MeWallet user={this.props.user.info} stageName={"wallet"} stage={3} />
                      <MeAccounts user={this.props.user.info} stageName={"accounts"} stage={3} />

                    </StagesManager>
                  )}
                </div>
              </div>
            )}
        </div>
      </Section>
    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Me);

