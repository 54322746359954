import React from 'react';
import Hero from '../../components/Hero'
import { translate } from '../../utils/useful'
import DataGrid from '../../components/DataGrid'
import Product from '../../components/SingleProduct'
import HttpService from '../../utils/Http.services'
import { chooseWallpaper } from '../../utils/useful'
import TLink from '../../components/TLink'

class HomeLomanab extends React.Component {

  state = {
    products: [],

    services: [
      { title: 'کیفیت بالا', image: 'https://image.flaticon.com/icons/svg/2166/2166853.svg', desc: 'محصولات تازه و با کیفیت را از ما بخواهید. تهیه کالا از با کیفیت ترین تامین کنندگان' },
      { title: 'تحویل فوری', image: 'https://image.flaticon.com/icons/svg/2166/2166885.svg', desc: 'لوماناب با همکاری با شرکت های پستی امکان ارسال کالا به سر تا سر کشور را برای مشتریان فراهم نموده' },
      { title: 'تنوع محصول', image: 'https://image.flaticon.com/icons/svg/2166/2166895.svg', desc: 'تنوع بالای محصولات از مهمترین نقاط قوت لوماناب می باشد که نتیجه آن رضایت مشتریان است' },
      { title: 'قیمت مناسب', image: 'https://image.flaticon.com/icons/svg/2166/2166955.svg', desc: 'قیمت مناسب محصولات لوماناب در کنار کیفیت بالای آن یک از افتخارات ما در ارائه خدمات است' }
    ],
  }


  componentDidMount() {
    this.fetch()
    this.fetchWallpapers()
  }

  fetch() {
    this.setState({ isLoadingProducts: true })
    HttpService.request("getAllWithData", { applet: 'products' }, (fetchResult, fetchError) => {
      this.setState({ isLoadingProducts: false })

      if (fetchError) { return }
      // console.log(fetchResult.info)
      this.setState({ products: fetchResult.info })
    })
  }



  fetchWallpapers() {

    HttpService.request('getAllWithData', { applet: 'wallpapers', filter: { 'values.page': 'home' } }, (fetchResult, fetchError) => {
      console.log(fetchResult)
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ wallpapers: fetchResult.info })
      }
    })
  }



  render() {

    return (
      <div className={"Page container-fluid p-0"}>
        {/* <Hero type={"image"} background={'#f2f4f6'} height="100vh" source={chooseWallpaper(this.state.wallpapers)} /> */}



        <div className="row flexcc w-100 p-0 m-0" style={{ background: this.props.background, height: this.props.height ? this.props.height : '100vh', position: 'relative' }}>

          <div className="position-absolute w-100 h-100" style={{ top: 0, left: 0, backgroundImage: "url(" + chooseWallpaper(this.state.wallpapers) + ")", backgroundPosition: 'center', backgroundSize: 'cover', }}></div>


          <div className="position-absolute w-100 h-100" style={{ background: '#10152050' }}>
          </div>

          <div className="text-center w-100 flexcc flex-column" style={{ position: 'relative' }}>
            <h1 className="text-mega-big" style={{ margin: 0, color: '#fff', fontWeight: '600' }}>{translate('Lomanab.ir')}</h1>
            {/* <img className="invert" src="/images/logo1.png" width="300px" /> */}
            <p className=" white text-normal mt-2" style={{ maxWidth: 550, width: '80%' }}>مجموعه لوماناب، با هدف افزایش کمیت و کیفیت محصولات داخلی، فعالیت های خود را در زمینه فروش و صادرات انواع آجیل خشکبار آغاز کرد. خدمات مشتری یکی از برنامه های اصلی کسب و کار ما است و بر این اساس تمام تلاش های ما برای ارائه رضایت مشتری و تجربه خرید خوب در ذهن مشتریان عزیز است</p>
            {/* <p className=" white text-normal mt-2">{translate('It is just the beginnig for us and you')}</p> */}

            <div className="flexcc mt-4">
              <TLink to={'/aboutus'} className="mrd-2" style={{ borderRadius: 30, color: '#fff', background: '#ffffff10', border: '#fff 2px solid', padding: '8px 30px', fontSize: 14, fontWeight: 'bold' }}>{translate('ABOUTUS')}</TLink>
              <TLink to={'/products'} className="mld-2" style={{ borderRadius: 30, color: '#202020', background: '#ffffff', border: '#fff 1px solid', padding: '8px 30px', fontSize: 14, fontWeight: 'bold' }}>{translate('products')}</TLink>
            </div>

          </div>
        </div>





        <section className="container">
          <div className="row mt-4 mb-3 text-center">


            <div className="col-12 mb-5 mt-4">
              <span className="font-bold text-center text-mega-big ">چرا لوماناب؟</span>
            </div>

            {this.state.services.map((prop, index) => {
              return (
                <div key={index} className="col-6 col-md-3 m-0  ">
                  <div className="p-4 text-center h-100" style={{ background: '#fff', borderRadius: 8 }}>
                    <div className="h-100 w-100">
                      <img className="mb-3" src={prop.image} height="100px" />
                      <p className="font-bold text-ultra-big text-center" >{prop.title}</p>
                      <div className="text-small mb-2 mt-2">{prop.desc}</div>
                    </div>
                  </div>
                </div>
              )
            })}

          </div>

          <div className="col-12 mb-5 mt-5 flexcc flex-column">
            <span className="font-bold text-center text-mega-big ">{translate('products')}</span>

            {/* <div className="flexcc w-100">
              <hr className="w-100 mrd-3" />
              <span className="font-bold text-center text-ultra-big mt-4">{translate('opia-products')}</span>
              <hr className="w-100  mld-3" />
            </div> */}
            <div className="text-center text-normal flexcc mt-2 mb-4" style={{ maxWidth: 650 }}>{translate('lomanab.products-desc')}</div>
          </div>

          <div className="col-12 mb-5 mt-4">
            <DataGrid isLoading={this.state.isLoadingProducts} data={this.state.products} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Product} col="col-12 col-md-6 col-lg-4" />
          </div>

        </section>


        {/* <section className='w-100 pt-2 pb-3' style={{ backgroundColor: '#000', marginTop: 80 }}>
          <div className="container">

            <div className="col-12 mb-5 mt-5 flexcc flex-column text-center">
              <span className="font-bold text-center text-ultra-big white">{translate('more-aboutus')}</span>
              <div className="text-center text-normal flexcc mt-2 white" style={{ maxWidth: 650 }}>{translate('more-aboutus-desc')}</div>

            </div>

            <div className="col-12 mb-5 mt-4">
              <div className="row flexcc" style={{ position: 'relative' }}>

                <video width="100%" controls style={{ maxWidth: 700 }}>
                  <source src="/video.mp4" type="video/mp4" />
                  Your browser does not support HTML5 video.
              </video>
              </div>
            </div>
          </div>
        </section> */}


        <section>
          <div className="container mb-5 px-5" style={{ backgroundColor: '#f2f6f8', borderRadius: 8 }}>
            <div className="row pt-5 pb-5 ">
              <div className="col-12 col-md-6 flex-column d-flex" style={{ justifyContent: 'center' }}>
                <span className="text-ultra-big font-bold">درباره لوماناب</span>
                <div className="mt-3">
                  {/* <div>{translate("what-we-do-desc1")}</div> */}
                  <div>{translate("lomanab.what-we-do-desc")}</div>
                </div>
              </div>

              <div className="col-12 col-md-6 position-relative" style={{ height: 250 }}>
                <figure style={{ position: 'absolute', top: '35%', left: '25%', width: '40%', }}>
                  <img src="/images/lomanab/cover1.jpg" width="100%" alt="" style={{ borderRadius: 8 }} />
                </figure>
                <figure style={{ position: 'absolute', top: '15%', left: '25%', width: '20%' }}>
                  <img src="/images/lomanab/cover2.jpg" width="180px" alt="" style={{ borderRadius: 8 }} />
                </figure>
                <figure style={{ position: 'absolute', top: '0%', left: '40%', width: '30%' }}>
                  <img src="/images/lomanab/cover3.jpg" width="180px" alt="" style={{ borderRadius: 8 }} />
                </figure>

              </div>
            </div>
          </div>
        </section>




      </div>

    );
  }
}

export default HomeLomanab;
