import React from "react";
import HttpService from '../../utils/Http.services';
import { siteConfig } from "../../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import TLink from "../../components/TLink";
import StagesManager from "../../components/StagesManager";
import ResourceFirstStep from "../../components/sendResource/ResourceFirstStep";
import ResourceTypeStep from "../../components/sendResource/ResourceTypeStep";
import UploadResourceStep from "../../components/sendResource/UploadResourceStep";

import ResourceInfoStep from "../../components/sendResource/ResourceInfoStep";
import ResourceThanksStep from "../../components/sendResource/ResourceThanksStep";
import { setObject, chooseWallpaper, translate } from "../../utils/useful";
import ResourceFilesStep from "../../components/sendResource/ResourceFilesStep";
import ResourcePhotoInfoStep from "../../components/sendResource/ResourcePhotoInfoStep";
import ResourcePriceTypeStep from "../../components/sendResource/ResourcePriceTypeStep";
import ResourcePriceStep from "../../components/sendResource/ResourcePriceStep";

class SendResource extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      colors: ['d7e2f7', 'eee1ff', 'fedfe7', 'dad6e4', 'f7dbd7', 'fff2e1'],
      wallpapers: [],
      // loginInfo: {},
      stage: 0,
      canResend: true,
      errors: [],
      data: { ["applet"]: 'resources' },
      phone: '',
      settings: { indicatorType: 'phone', mode: 'login', loginWithCode: false, passwordRequired: true }
    }
  }


  componentDidMount() {
    // let settings = this.state.settings
    // let pathname = this.props.location.pathname.split('/src/')
    // pathname = pathname[pathname.length - 1]
    // if (pathname.toLowerCase() === 'forgotpassword') {
    //   settings.mode = 'forgotPassword'
    // }
    this.fetchWallpapers()
    // this.changeStage("priceType")
  }


  letsStart = (cb) => {
    this.changeStage('type')
  }

  chooseResourceStep = (cb) => {
    // console.log(this.state.data)
    if (this.state.data.values?.resourceType == 'photo') {
      this.changeStage('photo')
    }
    if (this.state.data.values?.resourceType == 'vector') {
      this.changeStage('files')
    }
    // console.log(this.state.data)
  }


  goToInfoStep = (cb) => {
    this.changeStage('info')
  }

  photoFileDone = (cb) => {
    this.changeStage('photoInfo')
  }


  vectorFilesDone = (cb) => {
    this.changeStage('chooseCover')
  }

  chooseCoverDone = (cb) => {
    this.changeStage('info')
  }

  infoDone = (cb) => {
    this.changeStage('priceType')
  }

  gotoPrice = (cb) => {
    this.changeStage('price')
  }

  setData = (data, cb, ref) => {
    let newData
    if (!ref) {
      newData = this.state.data
    } else {
      newData = ref
    }
    console.log(data)
    for (const [key, value] of Object.entries(data)) {
      if (key == '**files') {
        if (!newData["**files"]) {
          newData["**files"] = {}
        }
        newData["**files"] = Object.assign(newData["**files"],value)
      } else {
        if (typeof value == "object") {
          this.setData(value, (newValue) => {
            console.log(newValue)
            newData[key] = Object.assign(newData[key],newValue)
          }, data[key])
        } else {
          console.log(newData)
          setObject(newData, key, value)
          console.log(newData)

        }
        // console.log(newData)
        // newData[key] = value
      }
    }
    if (ref) {
      console.log(ref)
      cb(newData)
      // return 
    } else {
      console.log(newData)
      this.setState({ data: newData }, () => {
        if (cb) { cb() }
      })
    }
  }



  changeStage = (newStage, cb) => {
    if (this.stageManager) {
      this.stageManager.changeStage(newStage, cb)
    }
  }

  lastStage = () => {
    if (this.stageManager) {
      this.stageManager.lastStage()
    }
  }


  uploadResource = (cb) => {
    let data = this.state.data
    data.values.status = 'Not Active'
    // console.log(data)
    HttpService.request('submitForm', data, (fetchResult, fetchError) => {
      if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }

      this.changeStage('thanks')

      if (cb) { cb() }
    })

  }

  fetchWallpapers() {
    HttpService.request('getAllWithData', { applet: 'wallpapers', filter: { 'values.page': 'send resource' } }, (fetchResult, fetchError) => {
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ wallpapers: fetchResult.info })
      }
    })
  }


  render() {
    return (
      <section className="d-flex w-100 position-relative" style={{ minHeight: '100vh', padding: '0px' }}>

        <section className="row p-0 w-100">

          <section className="d-none d-md-block col-12 col-md-5 col-lg-4" style={{ height: '100vh', position: 'sticky', top: 0, padding: '100px 30px 20px 30px', textAlign: 'center', backgroundImage: "url(" + chooseWallpaper(this.state.wallpapers) + ")", backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div className="h-100 w-100" style={{ background: '#00000050', position: 'absolute', top: 0, left: 0 }}></div>
            <div style={{ position: 'relative' }}>
              <h1 className="text-mega-big white">Chilik.net</h1>
              <h1 className="text-big white">{translate('send-new-resource')}</h1>
              <TLink to={'/pages/terms'}><p className="white opacity-7" style={{ fontSize: 15, margin: '10px 20px 20px 20px' }}>{translate('resource-accept-terms')}</p></TLink>
            </div>
          </section>

          <section className="col-12 col-md-7 col-lg-8" style={{ paddingTop: 100, opacity: this.state.mainOpacity, transition: 'all 0.5s', flex: '1 1 auto' }}>

            <StagesManager ref={el => this.stageManager = el} >
              <ResourceFirstStep data={this.state.data} setData={this.setData} letsStart={this.letsStart} stage={0} stageName={'init'} />
              <ResourceTypeStep data={this.state.data} setData={this.setData} chooseResourceStep={this.chooseResourceStep} stage={1} stageName={'type'} />
              <UploadResourceStep data={this.state.data} setData={this.setData} photoFileDone={this.photoFileDone} stage={2} stageName={'photo'} />
              <ResourcePhotoInfoStep data={this.state.data} setData={this.setData} goToInfoStep={this.goToInfoStep} stage={2} stageName={'photoInfo'} />

              <ResourceFilesStep data={this.state.data} setData={this.setData} vectorFilesDone={this.vectorFilesDone} stage={2} stageName={'files'} />
              <UploadResourceStep data={this.state.data} setData={this.setData} goToInfoStep={this.goToInfoStep} stage={2} stageName={'chooseCover'} />

              <ResourceInfoStep data={this.state.data} setData={this.setData} infoDone={this.infoDone} stage={2} stageName={'info'} />

              <ResourcePriceTypeStep data={this.state.data} setData={this.setData} uploadResource={this.uploadResource} gotoPrice={this.gotoPrice} stage={2} stageName={'priceType'} />
              <ResourcePriceStep data={this.state.data} setData={this.setData} uploadResource={this.uploadResource} stage={2} stageName={'price'} />

              <ResourceThanksStep data={this.state.data} stage={2} stageName={'thanks'} />
            </StagesManager>

          </section>
        </section>



      </section>
    )
  }
}


const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendResource);
