import React from 'react';
import { translate, imageAddress } from '../../utils/useful'
import Section from '../../components/Section'
import TLink from '../../components/TLink';
import HttpService from '../../utils/Http.services';
import stripHtml from "string-strip-html";
import { siteConfig } from '../../variables/config';
import Loader from 'react-loader-spinner'
import FastHelmet from '../../components/FastHelmet';


class Products extends React.Component {


  state = {
    data: []
  }


  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.init()
    });
    window.addEventListener('resize', this.setWidth)

    this.init()

  }


  componentWillUnmount() {
    this.unlisten();
    window.removeEventListener('resize', this.setWidth)

  }

  init() {
    window.scrollTo(0, 0)
    // console.log(window.pageYOffset)
    this.setState({ data: [], info: {}, isLoadingData: true }, () => {
      this.fetch()
      this.setWidth()
    })

  }


  setWidth = () => {
    this.setState({ width: window.innerWidth })
  }
  fetch() {
    let slug = this.props.match.params.slug
    let parent = this.props.match.params.parent

    this.setState({ isLoadingPge: true })

    if (parent) {

      HttpService.request("getOneWithData", { filter: { applet: 'products', '_id': parent } }, (fetchResult, fetchError) => {
        if (fetchError) { return }
        if (fetchResult.info) {
          this.setState({ info: fetchResult.info })
          //  console.log("HERE")
          // console.log(fetchResult)
          // let category = fetchResult.info._id
          HttpService.request("getAllWithData", { sort: { 'values.priority': -1 }, filter: { applet: 'products', 'values.parent': parent } }, (fetchResult, fetchError) => {
            this.setState({ isLoadingPge: false })
            if (fetchError) { return }
            console.log(fetchResult.info)

            this.setState({ data: fetchResult.info })
          })
        }
      })


    } else {
      HttpService.request("getOneWithData", { filter: { applet: 'categories', 'values.slug': slug } }, (fetchResult, fetchError) => {
        if (fetchError) { return }
        if (fetchResult.info) {
          this.setState({ info: fetchResult.info })
          console.log(fetchResult)
          let category = fetchResult.info._id
          HttpService.request("getAllWithData", { sort: { 'values.priority': 1 }, filter: { applet: 'products', 'values.category': category, $or: [{ 'values.parent': null }, { 'values.parent': '' }] } }, (fetchResult, fetchError) => {
            this.setState({ isLoadingPge: false })
            if (fetchError) { return }
            console.log(fetchResult.info)
            this.setState({ data: fetchResult.info })
          })
        }
      })
    }

  }

  render() {

    return (
      <section style={{ paddingBottom: 80, backgroundColor: '#f2f6f8', minHeight: '100vh' }}>

        <FastHelmet title={this.state.info?.values?.name} description={'لیست محصولات اوپیا |' + this.state.info?.values?.name} />


        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#000' }}>
          {/* <h1 className=" text-ultra-big white">{translate('opia-product')}</h1> */}
          <h1 className=" text-mega-big white">{this.state.info?.values?.name}</h1>
        </div>


        <div className="container">
          {this.state.data.map((prop, index) => {
            return (
              <div key={index} className={'flexcc mt-5 py-3'} style={{ borderRadius: 15, backgroundColor: '#fff', boxShadow: '0px 0px 30px rgba(0,0,0,0.05)' }}>
                <TLink className="w-100" style={{ padding: '20px 5%' }} pathname={prop.values.is3D ? ('/3d/' + prop._id) : (prop.values.hasChild ? (window.location.pathname + '/' + prop._id) : ('/product'))} search={((prop.values.hasChild || prop.values.is3D) ? '' : ('?id=' + prop._id))}>
                  <div className="row">

                    {((index % 2 === 1) || this.state.width < 768) && (
                      <div className="col-12 col-md-6 flexcc justify-content-center justify-content-md-start " >
                        <img src={imageAddress(prop.values?.image ? prop.values?.image : prop.values?.images[0], 'product', 'small')} style={{ maxHeight: "300px", borderRadius: 15, maxWidth: "80%" }} alt="" />
                      </div>
                    )}


                    <div className="col-12 col-md-6  px-4">
                      <div style={{ maxWidth: 400, }} className=" flex-column w-100">
                        <div className="d-flex mb-3 mt-2">
                          <h2 className=" text-mega-big mb-1 mt-0" >{prop.values?.category?.values?.name}</h2>
                          <div className="flexcc mld-4">
                            <p className=" text-semi-big mt-2 mrd-3 font-bold white" style={{ backgroundColor: '#ee5050', borderRadius: 8, padding: '5px 15px' }}>{translate(prop.values?.type)}</p>
                            {prop.values.unavailable && (
                              <p className=" text-semi-big mt-2 mrd-3 font-bold black" style={{ backgroundColor: '#e2e8f2', borderRadius: 8, padding: '5px 15px' }}>{translate('unavailable')}</p>
                            )}
                          </div>
                        </div>
                        <p className=" text-normal w-100" style={{ color: '#456' }}>{stripHtml(prop.values?.description)}</p>
                        {/* <h1 className=" text-semi-big mt-2">Availavle as:</h1> */}


                        <div className="mt-4 mb-4">
                          <span pathname={prop.values.hasChild ? (window.location.pathname + '/' + prop._id) : ('/product')} search={(prop.values.hasChild ? '' : ('?id=' + prop._id))} className="mb-4" style={{ backgroundColor: '#e4eaf2', whiteSpace: 'nowrap', padding: '10px 20px', color: '#456', textAlign: 'center', borderRadius: 10, fontSize: 14 }}>{prop.values.hasChild ? translate('view-products') : translate('shop-now')}</span>
                        </div>
                      </div>
                    </div>
                    {(index % 2 !== 1) && this.state.width >= 768 && (
                      <div className="col-12 col-md-6 flexcc justify-content-center ">
                        <img src={imageAddress(prop.values?.image ? prop.values?.image : prop.values?.images[0], 'product', 'small')} style={{ maxHeight: "300px", borderRadius: 15, maxWidth: "80%", }} alt="" />
                      </div>
                    )}
                  </div>
                </TLink>
              </div>
            )
          })}
        </div>

        {this.state.isLoadingPge && (
          <div style={{ textAlign: 'center', width: '100%', paddingTop: 90, }}>
            <Loader
              type="Oval"
              color="rgba(20,20,20,1)"
              height="50"
              width="50"
            />
          </div>
        )}

        {!this.state.isLoadingPge && this.state.data?.length == 0 && (
          <div className="mt-5">
            <p className="text-center mb-3">{translate('found-nothing')}</p>
          </div>
        )}

      </section>
    );
  }
}

export default Products;
