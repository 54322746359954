import React from 'react'
import { translate, currentLng } from '../utils/useful'
import { siteTheme, siteConfig } from '../variables/config'
import TLink from './TLink'

class CategoriesBox extends React.Component {
    state = {
        schema: { title: null, description: null, price: null, image: null, portals: null }
    }

    dataBuilder(data, schema) {
        let prop = {}
        for (const [key] of Object.entries(this.state.schema)) {
            prop[key] = this.valueBuilder(schema[key], data)
        }
        return prop
    }

    valueBuilder(key, data) {
        if (key != null && data.lng) {
            let keysArray = key.split('.')
            let newData = data
            if (keysArray[0] === "{{lng}}") {
                newData = data.lng[currentLng()]
            } else {
                newData = data[keysArray[0]]
            }
            if (keysArray[1] != null) {
                return this.valueBuilder(keysArray[1], newData)
            } else {
                return newData
            }
        } else {
            return key
        }
    }


    render() {
        let prop = this.props.data//this.dataBuilder(this.props.data, this.props.schema)
        return (
            <TLink pathname={'/category/' + prop.values.slug} >
                <div ref={el => this.box = el} className=" white h-100" >
                    <div className="w-100 h-100" style={{ background: '#000', borderRadius: 8 }}>
                    <img className="w-100" src={siteConfig.assetsDomain + 'images/' + prop.values.image?.sizes?.thumb?.address} alt="" style={{ borderRadius: '8px 8px 0px 0px', height: 200, objectFit: 'cover' }} />
                    <div style={{ padding: '10px 20px 30px 20px' }}>
                        <p className="font-bold text-ultra-big">{prop.values.name}</p>
                        <p className="text-small">{prop.values.description}</p>

                    </div>
                    </div>
                </div>
            </TLink>
        )
    }
}

export default (CategoriesBox);