import React from 'react';
import { translate, checkTextTranslation } from '../../utils/useful'
import LoaderButton from '../../components/LoaderButton'
import FormViewer from '../../components/FormViewer'
import HttpService from '../../utils/Http.services';

class MeChangePassword extends React.Component {

  state = {
    headers: [
      { key: 'currentPassword', type: 'PasswordInput', col: '6', information: { label: '{{lang}}currentPassword', icon: '/images/code.svg', iconWidth: 35, iconHeight: 35, placeholder: '{{lang}}insertPassword', required: true } },
      { key: 'newPassword', type: 'PasswordInput', col: '6', information: { label: '{{lang}}newPassword', icon: '/images/code.svg', iconWidth: 35, iconHeight: 35, placeholder: '{{lang}}insertPassword', required: true } },
      // { key: 'password', type: 'PasswordInput', information: { label: '{{lang}}password', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertPassword', required: true } }
    ]
  }


  updateUserInfo = () => {
    let data = this.form.getForm()
    if (data) {
      this.setState({ isLoading: true, errors: {}, message: null })
      HttpService.request("updateUserPassword", data, (fetchResult, fetchError) => {
        console.log(fetchError)
        this.setState({ isLoading: false })
        if (fetchError) { this.setState({ errors: fetchError.message }); return }
        this.setState({ message: '{{lang}}profileUpdated' })
        console.log(fetchResult.info)
        this.props.setUserNewInfo(fetchResult.info)
      })
    }
  }


  render() {

    return (
      <section>

        <div style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>

          <div className="p-3" style={{}}>
            <p className="text-big pb-3" style={{ borderBottom: '2px solid #ddd' }}>تغییر کلمه عبور</p>
            <div className="flexc">
              <div style={{ width: 100, height: 2, backgroundColor: '#FF6C00', marginTop: -2 }}></div>
              <div style={{ width: 50, height: 2, backgroundColor: '#FF6C00', marginTop: -2, marginRight: 0 }}></div>
            </div>
          </div>



          <div className="flexcc flex-column">




            {/* <h2 className="mt-4 " style={{ fontSize: 27 }}>Sign in | Sign up</h2> */}

            <div className="w-100" style={{ padding: '30px 35px' }}>

              <FormViewer ref={el => this.form = el} headers={this.state.headers} initData={this.props.user} errors={this.state.errors} inputClass={'modern-input'} />

              <div className="text-center">
                <LoaderButton
                  onClick={this.updateUserInfo}
                  isLoading={this.state.isLoading}
                  text={"{{lang}}update"}
                  type={"Oval"}
                  className="mt-4 mb-4"
                  buttonStyle={{ outline: 'none', backgroundColor: '#FF6C00', cursor: 'pointer', padding: '10px 50px', borderRadius: 30, fontSize: 15, color: '#fff', border: 'none' }}
                  width={40}
                  height={40}
                  color={'#202020'}
                />
                {/* <p style={{ fontSize: 13, opacity: 0.5, marginTop: 10 }}>This website uses cookies to deliver the best possible user experience. With signning up you are accepting this fact</p> */}
              </div>

              {this.state.message && (
                <div className="w-100 mt-2 text-center" style={{ backgroundColor: '#007aff10', borderRadius: 4, color: '#007aff', padding: '20px 20px' }}>
                  <p className="text-small">{checkTextTranslation(this.state.message)}</p>
                </div>
              )}

            </div>
          </div>



        </div>

      </section>
    );
  }
}

export default MeChangePassword

