import React from 'react';
// import { translate } from '../utils/useful'
// import Section from '../../components/Section'
// import TLink from '../../components/TLink';
import HttpService from '../../utils/Http.services';
// import { siteConfig } from '../variables/config';
import { capitalLetter, translate } from '../../utils/useful'
// import stripHtml from "string-strip-html";
import DataGrid from '../../components/DataGrid'
import CategoriesBox from '../../components/CategoriesBox'

class Resources extends React.Component {

  state = {
    resources: [
      // { image: 'https://images.unsplash.com/photo-1592254190449-9c4f0580f581?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80', width: 2400, height: 1600 },
      // { image: 'https://images.unsplash.com/photo-1592293362807-aa1ce4dfb94c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80', width: 2400, height: 1600 },
      // { image: '/images/cover.jpg', width: 2400, height: 1600 },
      // { image: 'https://images.unsplash.com/photo-1592296429945-93008c7e5a59?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1652&q=80', width: 2400, height: 1600 },
      // { image: 'https://images.unsplash.com/photo-1592239195729-3d3e57971816?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1705&q=80', width: 2400, height: 1600 },
    ]
  }


  componentDidMount() {
    let path = window.location.pathname.split('/')
    path = path[path.length - 1]
    if (path.toLowerCase() == 'vector' || path.toLowerCase() == 'photo') {
      this.setState({ type: path.toLowerCase() }, () => {
        this.fetch()
      })
    }
  }

  fetch() {
    this.setState({ isLoading: true })
    HttpService.request("getAllWithData", { applet: 'categories', filter: { 'values.resourceType': this.state.type } }, (fetchResult, fetchError) => {
      this.setState({ isLoading: false })
      if (fetchError) { return }
      this.setState({ resources: fetchResult.info })
    })
  }



  render() {
    
    return (
      <section style={{ paddingBottom: 80 }}>

        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#000' }}>
          <h1 className=" text-ultra-big white">Chilik.net</h1>
          <h1 className=" text-mega-big white">{capitalLetter(translate('category-type', { type: translate(this.state.type) }))}</h1>
        </div>


        <section className="container">
          <div className="col-12 mb-5 mt-4">
            <DataGrid isLoading={this.state.isLoading} data={this.state.resources} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={CategoriesBox} col="col-12 col-md-6 col-lg-4" />
          </div>
        </section>

      </section>
    );
  }
}

export default Resources;
