import React from 'react';
import { translate, imageAddress, phoneStandardView } from '../utils/useful'
import Section from '../components/Section'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
import HttpService from '../utils/Http.services';
import stripHtml from "string-strip-html";
import Loader from 'react-loader-spinner'
import { siteConfig } from '../variables/config';
import { priceStandardView, converNumberToPersian } from '../utils/useful'
import FastHelmet from '../components/FastHelmet';
import DataGrid from '../components/DataGrid'
import ProductCell from '../components/SingleProduct'
import TLink from '../components/TLink';
import AdvertisementW from '../components/AdvertisementW';
import ProductBox from '../components/Product'

class Product extends React.Component {

  state = {
    // data: { _id: 1 },
    // images: [

    //   '/images/products/2.jpeg',
    //   '/images/products/3.jpeg',
    //   '/images/products/1.jpeg',

    //   '/images/products/5.jpeg',
    //   '/images/products/4.jpeg',

    // ],
    info: {},
    currentImageIndex: 0,
    modalOpacity: 0,
    selectedTab: 0
  }


  componentDidMount() {
    // console.log("HERE")
    this.fetch()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.location != newProps.location) {
      this.setState({ isLoadingPage: true, data: {}, similarData: [] }, () => {
        this.fetch()
      })
    }
  }

  fetch() {

    // let search = this.props.location.search;
    // let params = new URLSearchParams(search);
    // let id = params.get('id');
    let id = this.props?.match?.params?.id

    // console.log(id)
    this.setState({ isLoadingPage: true })
    HttpService.request("getOneAd", { id: id }, (fetchResult, fetchError) => {
      this.setState({ isLoadingPage: false })
      // console.log(fetchResult)
      if (fetchError) { return }
      if (fetchResult.info) {
        // console.log(fetchResult.info)
        // if (fetchResult.info.values.image) {
        //   let images = Array.isArray(fetchResult.info.values.images) ? fetchResult.info.values.images : []
        //   images.splice(0, 0, fetchResult.info.values.image)
        //   fetchResult.info.values.images = images
        // }
        this.setState({ info: fetchResult.info },()=>{
          this.fetchSimilar()
        })
      }
    })
  }


  fetchSimilar() {
    let filter = {}

    filter.categories = this.state.info.categories
    filter._id = {$ne:this.state.info._id}
    this.setState({ isLoadingSimilars: true, data: [] })
    // if (search) {
    //   this.setState({ isLoadingSearch: true })

    //   filter = { ...this.state.searchInfo }
    //   if (filter.title) {
    //     filter.title = { $regex: filter.title }

    //   }
    // }

    HttpService.request("getAllAds", { filter,limit:8 }, (fetchResult, fetchError) => {
      // console.log(fetchResult)
      // console.log(fetchError)
      this.setState({ isLoadingSimilars: false })

      if (fetchError) { return }
      console.log(fetchResult.info)
      this.setState({ similars: fetchResult.info })
    })
  }


  openGallary(index) {
    this.setState({ showModal: true, modalOpacity: 1 }, () => {
      if (this.state.info?.images[index] && index !== this.state.currentImageIndex) {

        this.setState({ dontShowImage: true }, () => {
          setTimeout(() => {
            this.setState({ dontShowImage: false })

            this.setState({ currentImageIndex: index })
            let left = 0
            let width = 0

            if (this["gallary" + index]) {
              left = this["gallary" + index].getBoundingClientRect().x
              width = this["gallary" + index].getBoundingClientRect().width
            }
            if (this.gallary) {
              this.gallary.scrollLeft = this.gallary.scrollLeft + left - (window.innerWidth / 2 - width / 2)
            }
          }, 200);
        })
      }
    })
  }

  closeModal() {
    this.setState({ modalOpacity: 0 }, () => {
      setTimeout(() => {
        this.setState({ showModal: false })
      }, 300);
    })
  }


  getPrice() {
    // if (this.state.info?.unavailable) {
    //   return translate('unavailable')
    // } else {
    if (this.state.info?.price && this.state.info?.price != '' && Number(this.state.info?.price) != 0) {
      return converNumberToPersian(translate('currency', { price: priceStandardView(this.state.info?.price) }))
    } else {
      return "توافقی"
    }
    // }
  }



  handleBoomark = () => {
    let info = this.state.info

    let bookmarked = info.bookmarked == null ? true : !info.bookmarked
    info.bookmarked = bookmarked
    this.setState({ isLoading: true, errors: {}, message: null, info })
    HttpService.request("submitWish", { id: this.state.info._id, status: bookmarked }, (fetchResult, fetchError) => {

      // console.log(fetchError)
      this.setState({ isLoading: false })
      if (fetchError) {
        info.bookmarked = !bookmarked

        this.setState({ errors: fetchError.message, info }

        ); return
      }

      // this.setState({ message: '{{lang}}profileUpdated' })
      // console.log(fetchResult)
      // console.log(fetchError)

      // this.props.setUserNewInfo(fetchResult.info)
    })

  }

  openContactInfo = () => {
    this.setState({ showContact: this.state.showContact == null ? true : !this.state.showContact })
    // this.contactModal.showModal()
  }

  render() {

    if (!this.state.isLoadingPage) {
      return (
        <Section>

          <FastHelmet title={this.state.info?.title} description={'آگهی |' + this.state.info?.title} />


          <div className="container mb-5 pt-3">
            <div style={{ padding: '20px 0px 30px 0px' }}>

              <div className="row w-100 m-0 p-0">
                <div className="col-12 col-md-6 ">

                  <div className="row d-flex">

                    {Array.isArray(this.state.info?.images) && (
                      <div className={"m-0 p-1 d-flex " + "col-12"} style={{ height: 'auto', justifyContent: 'center' }}>
                        <button className="p-0 d-flex flex-column position-relative" onClick={() => this.openGallary(this.state.currentImageIndex)} >
                          <p className="text-smaller white" style={{ position: 'absolute', top: 10, right: 10, backgroundColor: '#00000088', padding: '5px 10px', borderRadius: 20 }}>
                            {this.state.currentImageIndex + 1} / {this.state.info?.images?.length}
                          </p>
                          <img src={imageAddress(this.state.info?.images[this.state.currentImageIndex], 'product')} width="100%" height="auto" style={{ objectFit: 'contain', borderRadius: 8, maxWidth: 350 }} alt="" />
                        </button>
                      </div>
                    )}



                    {!Array.isArray(this.state.info?.images) && (
                      <div className={"m-0 p-1 d-flex col-12"} style={{ height: 'auto', justifyContent: 'center' }}>
                        <div className="p-0 d-flex flex-column"  >
                          <img src={imageAddress(this.state.info?.images, 'product', 'thumb')} width="100%" height="auto" style={{ objectFit: 'contain', borderRadius: 8, maxWidth: 350 }} />
                        </div>
                      </div>
                    )}
                  </div>


                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div ref={el => this.gallary = el} className="d-flex mt-1 pl-3 pr-3 smooth-scroll no-scrollbar" style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
                      {Array.isArray(this.state.info?.images) && this.state.info?.images?.map((prop, index) => {
                        return (
                          <button className="m-0 p-1" key={index} ref={el => this["gallary" + index] = el} onClick={() => this.setState({ currentImageIndex: index })} style={{ width: '20%' }}>
                            <img className={"cursor-pointer gallary-image " + (index === this.state.currentImageIndex ? 'active' : '')} key={index} src={imageAddress(prop, 'product')} style={{ width: '100%', borderRadius: 8 }} />
                          </button>
                        )

                      })}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 pl-4 pr-4 pl-md-5 pr-md-5 mt-4 mt-md-0 pt-4" style={{ position: 'relative', flex: 1 }} >
                  <div className="" style={{ position: 'sticky', top: 110 }}>

                    {/* {this.state.info?.applet != "accessories" && (
                      <p>{this.state.info?.category?.values?.name}</p>
                    )} */}

                    <div className="mb-3 pb-2" style={{ borderBottom: '1px solid #eee' }}>
                      <div className="flexc mb-1" style={{ color: '#789' }}>
                        {Array.isArray(this.state.info?.categories) && this.state.info?.categories?.map((prop, index) => {
                          return (
                            <div className=" flexc" key={index}>
                              {(index > 0 ? ' -' : '') && (
                                <i className="fa fa-angle-left mx-2"></i>
                              )}
                              <p className="text-smaller">{prop?.values?.name}</p>
                            </div>
                          )
                        })}
                      </div>
                      <div className="flexcb">
                        <h1 className="bm-0 mt-0 text-big" style={{ fontWeight: 'normal' }}>{this.state.info?.title}</h1>
                        {this.props?.user?.loggedin && (
                          <button className="flexcc flex-column p-0" onClick={() => this.handleBoomark()}>
                            <div className="py-0 p-0 flexcc" style={{ color: '#000', textAlign: 'center', fontSize: 24 }}>
                              <i className={"fa " + (this.state.info.bookmarked ? "fa-bookmark" : 'fa-bookmark-o')} ></i>
                            </div>
                            <p className='text-smallest'>علاقه‌مندی</p>
                          </button>
                        )}
                        {/* <p className="mb-0 mt-0" style={{ fontSize: 18, fontWeight: 'normal' }}>{this.getPrice()}</p> */}
                      </div>
                      {/* {this.state.info?.unit && (<p className={"text-small mt-1"} style={{ whiteSpace: 'nowrap', fontSize: 14, color: '#222' }}>واحد اندازه گیری: {this.state.info?.unit}</p>)} */}
                    </div>

                    <div className="flexcb ">
                      <p className="bm-0 mt-0 text-normal" >قیمت</p>
                      <p className="mb-0 mt-0 text-normal" >{this.getPrice()}</p>
                    </div>

                    <div className="flexcb ">
                      <p className="bm-0 mt-0 text-small" >وضعیت کالا</p>
                      <p className="mb-0 mt-3 text-small" >{this.state.info?.used ? "دست دوم" : "کالا نو است"}</p>
                    </div>



                    {/* {Array.isArray(this.state.info?.colors) && this.state.info?.colors.length > 0 && (
                      <div className="w-100 page-loader mb-5 mt-4 flexc" style={{ flexWrap: 'wrap' }}>
                        <p style={{ color: '#789', whiteSpace: 'nowrap' }} className="mrd-2 mt-2">{translate('choose-color')}</p>
                        {this.state.info?.colors.map((prop, index) => {
                          return (
                            <button key={index} onClick={() => this.setState({ selectedColor: index })} className="flexcc mrd-2 px-2 py-1 mt-2" style={{ borderRadius: 20, border: '1px solid ' + (this.state.selectedColor === index ? '#aabbdd' : '#ddd'), backgroundColor: (this.state.selectedColor === index ? '#e4eaf2' : '#fff'), transition: 'all 0.5s' }}>
                              <div>
                                <div style={{ border: '1px solid #eee', width: 16, height: 16, borderRadius: 10, backgroundColor: '#' + prop.code }}></div>
                              </div>
                              <p className="font-small mr-2 text-small" style={{ color: '#789', whiteSpace: 'nowrap' }}>{prop.name}</p>
                            </button>
                          )
                        })}

                      </div>
                    )} */}

                    {this.state.info?.isCreator ? (
                      <div className="mb-2 mt-4 w-100 flexcc">
                        <TLink to={'/editAd/' + this.state.info?._id} className="w-100" style={{ backgroundColor: '#FF6C00', padding: '15px 25px', color: '#fff', textAlign: 'center', width: '100%', borderRadius: 30, fontSize: 16 }}>ویرایش</TLink>
                      </div>
                    ) : (
                        <button onClick={() => this.openContactInfo()} className="mb-2 mt-4" style={{ backgroundColor: '#FF6C00', padding: '15px 25px', color: '#fff', textAlign: 'center', width: '100%', borderRadius: 30, fontSize: 16 }}>اطلاعات تماس</button>
                      )}

                    {this.state.showContact && (
                      <div className=" mt-1" style={{ backgroundColor: '#f2f6f8', borderRadius: 40, overflow: 'hidden' }}>
                        <div className="px-4 py-3">
                          <div className="flexcb">
                            <p>شماره تماس</p>
                            <a href={"tel:" + phoneStandardView(this.state.info.phone)}>{converNumberToPersian(phoneStandardView(this.state.info.phone, true))}</a>
                          </div>

                          {this.state.info.address && this.state.info.address !== '' && (
                            <div className="flexcb mt-1">
                              <p className="mrd-4">آدرس</p>
                              {/* <p>{JSON.stringify(this.state.info?.city)}</p> */}
                              <div className="flexc">
                                {Array.isArray(this.state.info?.city) && this.state.info?.city.map((prop, index) => {
                                  return (
                                    <p key={index}>{prop?.values?.name} -  </p>
                                  )
                                })}
                                <p> {this.state.info.address}</p>

                              </div>

                            </div>
                          )}

                        </div>
                        <div className="mt-0 flexc px-4 py-3" style={{ backgroundColor: '#e4eaf2' }}>
                          <img src={imageAddress(this.state.info?.creator.image, 'profile', 'small')} height="40px" width="40px" className="round-image ml-2" />
                          <div>
                            <p className="text-smaller" style={{ color: '#789' }}>آگهی شده توسط</p>
                            <p>{this.state.info?.creator.name} {this.state.info?.creator.family}</p>
                          </div>
                        </div>

                      </div>
                    )}


                    <button className="mt-4 mb-2" onClick={() => this.setState({ selectedTab: 0 })} style={{ color: this.state.selectedTab === 0 ? '#202020' : '#89a' }}><p className="mb-0 mt-0 text-semibig transition-all">توضیحات</p></button>
                    {this.state.info?.description && (
                      <p className="font-small mb-2">{stripHtml(this.state.info?.description)}</p>
                    )}


                    <div className="mt-4" style={{ color: '#789' }}>
                      <p className="text-small">انبار مازاد هیچ گونه مسئولیتی نسبت به معاملات ندارد</p>
                      <p className="text-small mt-3">در زمان استفاده از سایت به قوانین و مقررات توجه نمایید</p>
                      <div className="mt-3 flexc">
                        <TLink to={'/pages/shopping'} className="text-small "><i className="fa fa-book mx-2"></i>راهنمای خرید امن</TLink>
                      </div>
                      {/* <p className="text-small mt-2">گزار</p> */}



                    </div>
                    {/* {this.showCartBut(this.state.data, this.state.selectedColor)} */}


                  </div>
                </div>


                <div className="col-12  mt-5 mb-2">
                  <AdvertisementW />
                </div>



                <div className="col-12  mt-5 mb-2">
                  <p className="mt-3 mb-4 text-semibig ">آگهی‌های مشابه</p>
                  <DataGrid isLoading={this.state.isLoadingSimilars} data={this.state.similars} notFoundText="موردی یافت نشد" schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={ProductBox} col="col-12 col-md-6 col-lg-3 flexcc align-items-stretch " />
                </div>




              </div>

            </div>



            {/* <div className="row w-100 m-0 ">
              <div className="col-12 ">

                <div className="flexc mb-4 pb-3" style={{ borderBottom: '2px solid #f2f6f8' }}>
                  <button className="mld-2" onClick={() => this.setState({ selectedTab: 0 })} style={{ color: this.state.selectedTab === 0 ? '#202020' : '#89a' }}><p className="mb-0 mt-0 text-semibig transition-all">توضیحات</p></button>

                  
                </div>

             

              </div>
            </div> */}
          </div>



          {
            this.state.showModal && (
              <div className='flexcc w-100 gallary' ref={el => this.modal = el} style={{ opacity: this.state.modalOpacity, transition: 'all 0.3s', position: 'fixed', top: 0, left: 0, zIndex: 10, backgroundColor: '#778899dd', height: '100vh', width: '100%', backdropFilter: 'blur(30px)', WebkitBackdropFilter: 'blur(30px)' }}>
                <button onClick={() => this.closeModal()} style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}></button>
                <div className="w-100 text-center ">
                  <div className="flexcc w-100">
                    <button className="invert gallary-arrow" onClick={() => this.openGallary(this.state.currentImageIndex - 1)}><i className="fa fa-angle-right text-mega-big"></i></button>
                    <div className="flexcc" style={{ height: '70vh', maxWidth: 'calc(100% - 70px)' }}>
                      <img src={imageAddress(this.state.info?.images[this.state.currentImageIndex], 'product')} style={{ opacity: this.state.dontShowImage ? 0 : 1, transition: 'all 0.2s', maxHeight: '100%', borderRadius: 15, maxWidth: 'calc(100%)', objectFit: 'contain' }} />
                    </div>
                    <button className="invert gallary-arrow" onClick={() => this.openGallary(this.state.currentImageIndex + 1)}><i className="fa fa-angle-left text-mega-big"></i></button>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div ref={el => this.gallary = el} className="d-flex mt-1 pl-3 pr-3 smooth-scroll no-scrollbar" style={{ overflow: 'auto' }}>
                      {this.state.info?.images.map((prop, index) => {
                        return (
                          <button className="m-0 p-0" key={index} ref={el => this["gallary" + index] = el} onClick={() => this.openGallary(index)}>
                            <img className={"m-1 cursor-pointer gallary-image " + (index === this.state.currentImageIndex ? 'active' : '')} key={index} src={imageAddress(prop, 'product')} style={{ maxHeight: '20vh', borderRadius: 8 }} />
                          </button>
                        )

                      })}
                    </div>
                  </div>
                </div>
              </div>
            )
          }

        </Section >
      );
    } else {
      return (
        <div style={{ textAlign: 'center', width: '100%', paddingTop: 90, minHeight: '100vh' }}>
          <Loader
            type="Oval"
            color="rgba(20,20,20,1)"
            height="50"
            width="50"
          />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({ cart: state.cart, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product);
