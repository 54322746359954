import React from 'react'
import LoaderButton from '../LoaderButton'
import FormViewer from '../FormViewer'
import TLink from '../TLink'
import { translate } from '../../utils/useful'

class ResourcePriceStep extends React.Component {
    state = {
        errors: {},
        formHeaders: [
            { key: 'values.price', type: 'PriceInput', information: { label: '{{lang}}price', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: '{{lang}}insertPrice', required: true } },
        ]

    }

    infoDone = () => {
        let data = this.form.getForm()
        if (data) {
            this.setState({ errors: {},isLoading:true })
            this.props.setData(data, () => {
                this.props.uploadResource((result, err) => {
                    if (err) { this.setState({ errors: err }) }
                    this.setState({ isLoading: false })
                })
            })
        }
    }


    render() {
        return (

            <div className="flexcc flex-column">
                <h2 className="mt-0 mb-0" style={{ fontSize: 27 }}>{translate('information')}</h2>

                <div className="w-100" style={{ maxWidth: 400 }}>

                <p className="text-center" style={{ fontSize: 14, margin: '0px 20px 20px 20px' }}>{translate('price-desc')}</p>

                    <FormViewer ref={el => this.form = el} headers={this.state.formHeaders} initData={this.props.data} errors={this.state.errors} inputClass={'modern-input'} />

                    <div className="text-center">
                        <LoaderButton
                            // onClick={this.uploadResource}
                            onClick={this.infoDone}

                            isLoading={this.state.isLoading}
                            // text={"Upload Resource"}
                            text={translate('upload-resource')}

                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                    </div>


                    <TLink to={'/pages/terms'}><p className="text-center" style={{ fontSize: 15, margin: '10px 20px 20px 20px' }}>{translate('resource-accept-terms')}</p></TLink>

                    {/* <p className="text-center" style={{ fontSize: 15, margin: '10px 20px 20px 20px' }}>With posting resource you are accepting our <TLink pathname="/pages/terms" style={{ color: '#007aff' }}>terms</TLink> and <TLink pathname="/pages/policies" style={{ color: '#007aff' }}>Policies</TLink></p> */}

                    <div className="w-100 text-center mb-5">
                        <button className="mb-1" onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate('back-one-stage')}</button>
                    </div>

                </div>
            </div>

        )
    }
}

export default ResourcePriceStep;