import React from 'react';
import { translate, checkTextTranslation } from '../../utils/useful'
import Section from '../../components/Section'
import TLink from '../../components/TLink';
import HttpService from '../../utils/Http.services';
import { siteConfig } from '../../variables/config';
import stripHtml from "string-strip-html";
import { imageAddress } from '../../utils/useful'

class Services extends React.Component {


  state = {
    data: [],
    services: [
      { title: 'دندانپزشکی زیبایی', image: 'https://image.flaticon.com/icons/svg/2035/2035135.svg', desc: 'اصولا نحوه قرارگیری دندانها و نمایش لثه ها و حتی زوایای دندانها در زیبا یا نازیبا شدن لبخند تاثیر زیادی دارد.مثلا تیزی دندانهای نیش چهره را مردانه وخشن نشان می دهد و گرد بودن زوایا چهره بیمار را ملایم می کند. در خانمها دو دندان جلو (سانترال) کمی بلندتر هستند وجلوه بیشتری دارند. در افراد جوان لبه ها وزوایای دندانها صاف ومشخص هستند در حالی در افراد مسن لبه دندانها سایش یافته و زوایا گردتر هستند.یا در بعضی افراد هنگام خنده میزان زیادی از لثه ها نمایان است ودندانها کوتاه و کوچک دیده می شوند که در این مواقع نیاز به اص لاح وزیباسازی لثه ها وجود دارد', slug: 'beauty' },
      { title: 'ایمپلنت', image: 'https://image.flaticon.com/icons/svg/662/662793.svg', desc: 'با پیشرفت علم دندانپزشکی و به وجود آمدن روش ایمپلنت امروزه دیگر بی دندانی یک مشکل جدی محسوب نمی شود و با ایمپلنت می شود این مشکل را برطرف نمود. از دست دادن دندان ها برای خیلی از افراد یک مشکل سخت و آزار دهنده است اما دلایل بسیاری برای رو برو شدن با بی دندانی وجود دارد' },
      { title: 'تکنولوژی لیزر', image: 'https://image.flaticon.com/icons/svg/893/893872.svg', desc: 'در جراحي بافت نرم، ليزر مانند چاقو عمل مي كند، اما نسبت به چاقو داراي مزيت هايي است. اول آن كه در مسير برش، مكانيسم آن به گونه اي است كه انعقاد مناسبي ايجاد مي كند. اين مسأله براي عده اي كه مشكلات انعقادي دارند، يك مزيت محسوب مي شود. هم چنين با انعقاد حاصل شده، جراح ديد بهتري نسبت به ناحيه جراحي پيدا مي كند' },
      { title: 'کد / کم', image: 'https://image.flaticon.com/icons/svg/2035/2035123.svg', desc: 'با کمک این روش، تمامیی فرآیند جراحی با استفاده از فایل قالب دیجیتال یا همان اسکن دهان متقاضی و فایلCBCT فک کامل وی  در نرم افزار، فرآیند شبیه سازی صورت میگیرد و سپس، مناسب ترین موقعیت کاشت ایمپلنت با استفاده از کیفیت استخوان فک بیمار و جای قرارگیری سینوس یا عصب معین می شود و طرح نهایی به وسیله متخصص فک و صورت یا جراح لثه، تایید می شود' }
    ],

  }


  componentDidMount() {
    this.fetch()
  }

  fetch() {
    HttpService.request("getAllWithData", { applet: 'categories' }, (fetchResult, fetchError) => {
      if (fetchError) { return }
      // console.log(fetchResult.info)
      this.setState({ data: fetchResult.info })
    })
  }

  render() {

    return (
      <section style={{ paddingBottom: 0 }}>

        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#000' }}>
          <h1 className=" text-ultra-big white">دندانپزشکی دکتر ادیب</h1>
          <h1 className=" text-mega-big white">خدمات</h1>
        </div>



        {this.state.services.map((prop, index) => {
          return (
            <Section key={index} className={'flexcc '} background={'#fff'}>
              <div className="container-fluid">
                <div className="row">

               
                  <div className="col-12 " style={{backgroundColor:index%2==0?'#fff':'#f2f6f8'}}>
                  <div className="container flexc p-5 w-100 flex-column flex-md-row text-center text-md-right">
                    <div className=" ml-0 ml-md-4">
                      <img src={prop.image} style={{ width: 100 }} alt="" />
                    </div>

                    <div>
                      <h1 className=" text-ultra-big">{prop.title}</h1>
                      {prop.desc && (
                        <p className="mt-2 text-normal">{prop.desc}</p>
                      )}

                    
                    </div>

                  </div>
                  </div>
                 
                </div>
              </div>
            </Section>
          )
        })}

      </section>
    );
  }
}

export default Services;
