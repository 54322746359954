import React from "react";
import HttpService from '../utils/Http.services';
import { siteConfig } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
import TLink from "../components/TLink";
import RandomBack from "../components/RandomBack";
import StagesManager from "../components/StagesManager";
import LoginFirstStep from "../components/login/LoginFirstStep";
import LoginCodeStep from "../components/login/LoginCodeStep";
import LoginPasswordStep from "../components/login/LoginPasswordStep";
import LoginInfoStep from "../components/login/LoginInfoStep";
import LoginWelcomeStep from "../components/login/LoginWelcomeStep";
import { chooseWallpaper, translate } from "../utils/useful";

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      colors: ['d7e2f7', 'eee1ff', 'fedfe7', 'dad6e4', 'f7dbd7', 'fff2e1'],
      loginInfo: {},
      stage: 0,
      canResend: true,
      errors: [],
      data: {},
      phone: '',
      settings: { indicatorType: 'phone', mode: 'login', loginWithCode: false, passwordRequired: true }
    }
  }


  componentDidMount() {
    let settings = this.state.settings
    let pathname = this.props.location.pathname.split('/')
    pathname = pathname[pathname.length - 1]
    if (pathname.toLowerCase() === 'forgotpassword') {
      settings.mode = 'forgotPassword'
    }
    this.setState({ data: { 'userIndicator': '0098', indicatorType: settings.indicatorType, settings } })
    this.fetchWallpapers()
    // this.changeStage("info")
  }


  postUserIndicator = (cb) => {
    let data = this.state.data
    if (data.userIndicator && data.userIndicator.length >= 5) {
      HttpService.request("postUserIndicator", data, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
        this.setData({ exist: fetchResult.exist })
        if (fetchResult.exist && !this.state.settings.loginWithCode) {
          this.changeStage('password')
        } else {
          this.changeStage('code')
        }
        if (cb) { cb() }
      })
    } else {
      if (this.state.settings.indicatorType === 'phone') {
        cb(null, { userIndicator: "{{lang}}errors.phoneLength" })
      }
      if (this.state.settings.indicatorType === 'email') {
        cb(null, { userIndicator: "{{lang}}errors.emailPattern" })
      }
    }
  }

  postForgotPassword = (cb) => {
    let data = this.state.data
    if (data.userIndicator && data.userIndicator.length >= 5) {
      HttpService.request("postForgotPassword", data, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
        this.changeStage('code')
        if (cb) { cb() }
      })
    } else {
      if (this.state.settings.indicatorType === 'phone') {
        cb(null, { userIndicator: "{{lang}}errors.phoneLength" })
      }
      if (this.state.settings.indicatorType === 'email') {
        cb(null, { userIndicator: "{{lang}}errors.emailPattern" })
      }
    }
  }


  postActicationCode = (cb) => {

    let data = this.state.data
    if (data.userIndicator && data.userIndicator.length >= 0 && data.code && String(data.code).length >= 4) {
      HttpService.request((this.state.data.exist || this.state.settings.mode === "forgotPassword") ? "signinActicationCode" : 'signupActicationCode', data, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
        if (this.state.data.exist || this.state.settings.mode === "forgotPassword") {
          if (this.state.settings.passwordRequired || this.state.settings.mode === "forgotPassword") {
            this.changeStage('password')
          } else {
            // LOGIN
            this.loginDone(fetchResult)
          }
        } else {
          this.changeStage('info')
        }
        if (cb) { cb() }
      })
    } else {
      cb(null, { code: "{{lang}}errors.codeLength" })
    }
  }


  postPassword = (cb) => {
    console.log("postPassword")
    let data = this.state.data
    if (data.userIndicator && data.userIndicator.length >= 0) {
      HttpService.request(this.state.settings.mode === 'forgotPassword' ? "postNewPassword" : 'postPassword', data, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
      console.log(fetchResult)
        this.loginDone(fetchResult)
        if (cb) { cb() }
      })
    } else {
      cb(null, { code: "{{lang}}errors.codeLength" })
    }
  }


  postUserInfo = (cb) => {
    let data = this.state.data
    if (data.userIndicator && data.userIndicator.length >= 0 && data.code && String(data.code).length >= 4) {
      HttpService.request("postUserInfo", data, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
        this.loginDone(fetchResult)
        if (cb) { cb() }
      })
    } else {
      cb(null, { code: "{{lang}}errors.codeLength" })
    }
  }


  loginDone = (data) => {
    this.setData(data.user)
    // console.log(data)
    if (data.auth?.token) {
      this.props.actions.setUser(data.user, data.auth, true)
      this.changeStage('welcome')
    }
  }


  setData = (data, cb) => {
    let newData = this.state.data
    for (const [key, value] of Object.entries(data)) {
      newData[key] = value
    }
    this.setState({ data: newData }, () => {
      if (cb) { cb() }
    })
  }



  changeStage = (newStage, cb) => {
    if (this.stageManager) {
      this.stageManager.changeStage(newStage, cb)
    }
  }

  lastStage = () => {
    if (this.stageManager) {
      this.stageManager.lastStage()
    }
  }

  fetchWallpapers() {
    HttpService.request('getAllWithData', { applet: 'wallpapers', filter: { 'values.page': 'ورود' } }, (fetchResult, fetchError) => {
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ wallpapers: fetchResult.info })
      }
    })
  }


  render() {
    return (
      <section className="d-flex w-100 position-relative" style={{ minHeight: '100vh', padding: '0px' }}>
        {/*         
        <div style={{ opacity: this.state.firstBack ? 1 : 0, transition: 'all 2s', position: 'absolute', zIndex: -1, width: '100%', height: '100%', top: 0, left: 0, background: "linear-gradient(to right bottom, #" + this.state.colors[this.state.primeColor] + " ,#" + this.state.colors[this.state.secondColor] + ")" }}></div>
        <div style={{ opacity: this.state.firstBack ? 0 : 1, transition: 'all 2s', position: 'absolute', zIndex: -1, width: '100%', height: '100%', top: 0, left: 0, background: "linear-gradient(to right bottom, #" + this.state.colors[this.state.sprimeColor] + " ,#" + this.state.colors[this.state.ssecondColor] + ")" }}></div> */}
        {/* <RandomBack colors={this.state.colors} /> */}

        <div className="row w-100 m-0">
          <div className="d-none d-md-block col-12 col-md-5 col-lg-4" style={{ height: '100vh', position: 'sticky', top: 0, padding: '100px 30px 20px 30px', textAlign: 'center', backgroundImage: "url(" + chooseWallpaper(this.state.wallpapers) + ")", backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div className="h-100 w-100" style={{ background: '#00000050', position: 'absolute', top: 0, left: 0 }}></div>
            <div style={{ position: 'relative' }}>
              <h2 className="text-ultra-big white">{siteConfig.siteName}</h2>
              <h2 className="text-big white mt-2" style={{ whiteSpace: 'nowrap', fontWeight: 'normal' }}>{translate('Signin-Signup')}</h2>
              <p className="white opacity-7" style={{ fontSize: 15, marginTop: 10, }}>کاربر گرامی شما با استفاده از این سایت با قوانین و مقررات موافقت می کنید</p>
            </div>
          </div>

          <div className="col-12 col-md-7 col-lg-8" style={{ opacity: this.state.mainOpacity, transition: 'all 0.5s', flex: '1 1 auto', paddingTop: 40 }}>

            <StagesManager ref={el => this.stageManager = el} >
              <LoginFirstStep initData={this.state.data} setData={this.setData} settings={this.state.settings} postUserIndicator={this.state.settings.mode == "forgotPassword" ? this.postForgotPassword : this.postUserIndicator} stage={0} stageName={'init'} info={this.state.loginInfo} type={'phone'} />
              <LoginCodeStep initData={this.state.data} setData={this.setData} postUserIndicator={this.postUserIndicator} postActicationCode={this.postActicationCode} stage={1} stageName={'code'} />
              <LoginPasswordStep initData={this.state.data} setData={this.setData} postPassword={this.postPassword} newPassword={this.state.settings.mode == "forgotPassword"} stage={2} stageName={'password'} />
              <LoginInfoStep initData={this.state.data} setData={this.setData} postUserInfo={this.postUserInfo} stage={3} stageName={'info'} />
              <LoginWelcomeStep initData={this.state.data} settings={this.state.settings} stage={4} stageName={'welcome'} />
            </StagesManager>

          </div>
        </div>



      </section>
    )
  }
}


const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
