import { siteConfig } from '../variables/config'
import HttpService from '../utils/Http.services';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function translate(key, params) {
    return (
        i18n.t(key, params)
    )
}


function checkTextTranslation(text) {
    if (text) {
        if (text.includes("{{lang}}")) {
            let newText = text
            newText = newText.replace('{{lang}}', '')
            newText = i18n.t(newText)
            return newText

        } else {
            return text
        }
    }
}



function changeBodyDirection(language) {

    if (language === 'fa') {
        document.body.classList.remove('ltr')
        document.body.classList.add('rtl')
    } else {
        document.body.classList.remove('rtl')
        document.body.classList.add('ltr')
    }
}


function currentLng() {
    return i18n.language
}


function changeUrlWithLng(lng) {

    let pathname = window.location.pathname.split('/')
    for (let index = pathname.length - 1; index >= 0; index--) {
        if (pathname[index] === "") {
            pathname.splice(index, 1)
        }
    }

    if (lng === siteConfig.defaultLng && siteConfig.dontShowUrlForDefaultLng) {
        if (siteConfig.languages.indexOf(pathname[0]) !== -1) {
            pathname.splice(0, 1).join('/')
            window.location = '/' + pathname + window.location.search + window.location.hash

        }
    } else {

        if (lng !== pathname[0]) {

            if (!pathname[0] || siteConfig.languages.indexOf(pathname[0]) !== -1) {
                pathname[0] = lng
            } else {
                pathname.splice(0, 0, lng)
            }
            window.location = "/" + pathname.join('/') + window.location.search + window.location.hash
        }
    }
}


function defaultPathMaker() {
    return siteConfig.dontShowUrlForDefaultLng ? (i18n.language === siteConfig.defaultLng ? '' : '/' + i18n.language) : '/' + i18n.language
}

function pathMaker(path) {
    return defaultPathMaker() + path
}

function imageAddress(image, type, prefered) {
    // console.log(image)
    let imageInfo = {
        profile: { address: 'images/', defaultImg: '/images/male.png' },
        product: { address: 'images/', defaultImg: '/images/no-product.png' },
        resource: { address: 'images/', defaultImg: '/images/no-image.png' },
        estate: { address: 'images/', defaultImg: '/images/no-image.jpg' },
        advertisement: { address: 'images/', defaultImg: '/images/noAdS.jpg' },
        advertisementW: { address: 'images/', defaultImg: '/images/noAdW.jpg' }

    }

    let prefixAddress = 'images/'
    let defaultImg = ''
    if (imageInfo[type]) {
        prefixAddress = imageInfo[type].address
    }
    if (imageInfo[type] && imageInfo[type].defaultImg) {
        defaultImg = imageInfo[type].defaultImg
    }

    if (image && image != '') {

        let imageName = ''
        let myImage = Array.isArray(image) ? image[0] : image

        if (prefered && myImage.sizes && myImage.sizes[prefered]) {
            // console.log("HERE")
            imageName = myImage.sizes[prefered].address

        } else {
            if (typeof myImage === "object" && !Array.isArray(myImage)) {
                // console.log(image.address)
                imageName = myImage.address
            } else {
                imageName = myImage
            }
        }


        let final = siteConfig.assetsDomain + prefixAddress + imageName
            // console.log(final)

        return final
    } else {
        return defaultImg
    }

}


function getToken() {
    let token = cookies.get('token')
    if (typeof token === "undefined") {
        token = null
    }
    return token
}

function getUserInfo(cb) {
    HttpService.request("getUserInfo", null, (fetchResult, fetchError, status) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message, status) }; return }
        // console.log(fetchResult)
        if (cb) { cb(fetchResult) }
    })
}

function phoneStandardView(number, noCountryCode) {
    let phone = number
    if (number) {
        if (number.includes('-')) {
            phone = number.substring(4, number.length)
        }

        if (noCountryCode) {
            phone = '0' + phone.substring(4, number.length)
        }
        // console.log(phone)


        let counter = 0
        while (number[counter] == "0" && !noCountryCode) {
            counter++
        }

        // console.log(phone)


        phone = phone.substring(counter, number.length)
        return phone
    }
}



let numbers = { '0': '۰', '1': '۱', '2': '۲', '3': '۳', '4': '۴', '5': '۵', '6': '۶', '7': '۷', '8': '۸', '9': '۹' }

function converNumberToPersian(number) {
    if (number != null) {
        let numberArray = String(number).split('')
        numberArray.forEach((element, index) => {
            if (numbers[element]) {
                numberArray[index] = numbers[element]
            }
        });
        return numberArray.join('')

    }
}


function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }


    var ia = new Uint8Array(bstr.length);
    for (var i = 0; i < bstr.length; i++) {
        ia[i] = bstr.charCodeAt(i);
    }

    return new Blob([ia], { type: mime });
}




function priceStandardView(priceRef) {
    if (priceRef) {

        let priceArray = String(priceRef).split('')
        let price = ''
            // let j = 0
        for (let i = 0; i < priceArray.length; i++) {
            let element = priceArray[priceArray.length - 1 - i];
            // j++
            price = element + price
                // console.log(price)
            if ((i + 1) % 3 == 0 && i != priceArray.length - 1) {
                price = ',' + price
            }
            // console.log(price)

        }

        return price

    }
}




function setObject(data, key, value, append) {

    if (!data) data = {}
    if (key && key.includes('.')) {
        let pList = key.split('.');
        let newKey = [...pList]
        newKey.splice(0, 1)
        newKey = newKey.join('.')
        data[pList[0]] = setObject(data[pList[0]], newKey, value)
        return data

    } else {
        if (append) {
            data[key] = data[key] ? data[key] : []
            if (Array.isArray(value)) {
                data[key] = [...data[key], ...value]
            } else {
                data[key] = [...data[key], value]
            }
        } else {
            data[key] = value
        }
        return data
    }

}

function getObject(data, key, splitLevel, level) {


    if (data && key) {

        if (key.includes('.') && (splitLevel == null || level == null || splitLevel > (level ? level : 0))) {
            let pList = key.split('.');
            let newKey = [...pList]
            newKey.splice(0, 1).join('.')
            newKey = newKey.join('.')
            return getObject(data[pList[0]], newKey, splitLevel, level ? level + 1 : 1)

        } else {

            return data[key]
        }
    } else {
        return null
    }
}


function capitalLetter(text) {
    if (text) {
        let words = text.split(' ')
        let newWords = []
        words.forEach((word, index) => {
            let newWord = word
            newWord = newWord.substr(0, 1).toUpperCase() + newWord.substr(1)
            newWords.push(newWord)
        });
        return newWords.join(' ')
    }
}

function chooseWallpaper(data) {
    // console.log(data)
    if ((data && data[0] && data[0].values && data[0].values.image)) {
        let image = Array.isArray(data[0].values.image) ? data[0].values.image[0] : data[0].values.image
        return siteConfig.assetsDomain + 'images/' + image.address
    }
    // return "/images/cover2.jpeg"
    // return "/images/cover.jpg"

}


export { translate, changeBodyDirection, dataURLtoFile, changeUrlWithLng, currentLng, checkTextTranslation, pathMaker, imageAddress, getToken, getUserInfo, phoneStandardView, getObject, setObject, capitalLetter, chooseWallpaper, priceStandardView, converNumberToPersian }