import React from "react";
// import Tabbar from '../components/Tabbar.jsx'
// import TabbarViewer from '../components/TabbarViewer.jsx'
import Loader from 'react-loader-spinner'
// import moment from 'moment'
import moment from 'jalali-moment'

import { Helmet } from "react-helmet";
import stripHtml from "string-strip-html";
import HttpService from '../utils/Http.services';
import { imageAddress } from "../utils/useful";
import { siteConfig } from "../variables/config";

class Blog extends React.Component {

  state = {

    isLoadingPage: true,
    similarData: [],
    data: {},
    tabbarData: [{ title: 'Other Articles' }],
  }

  componentWillMount() {
    this.fetch()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.location != newProps.location) {
      this.setState({ isLoadingPage: true, data: {}, similarData: [] }, () => {
        this.fetch()
      })
    }
  }


  fetch() {

    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    this.setState({ isLoadingPage: true })

    HttpService.request("getOneWithData", { applet: 'blogs', filter: { _id: id } }, (fetchResult, fetchError) => {
      console.log(fetchResult.info)
      // console.log(this.state.data?.values?.title)
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ isLoadingPage: false })
        this.setState({ data: fetchResult.info })
      }
    })
  }




  render() {
    if (!this.state.isLoadingPage) {
      // document.title = "Antbuildz " + '| ' + this.state.data?.values?.title

      return (

        <div className="page" id="page" style={{ flexDirection: 'column', width: '100%', overflow: 'hidden', backgroundColor: '#fff', minHeight: '100vh' }}  >

          <Helmet>
            <meta charSet="utf-8" />
            <title>{siteConfig.siteName} | {String(this.state.data?.values?.title)}</title>
            {/* <meta name="description" content={this.state.data?.values?.title} /> */}
            <meta property="title" content={this.state.data?.values?.title} />
            {/* <meta property="description" content={stripHtml(this.state.data?.values?.summery)} /> */}
            <meta property="image" content={imageAddress(this.state.data?.values?.image)} />
            <meta property="url" content={window.location} />

            <meta property="og:title" content={this.state.data?.values?.title} />
            <meta property="og:url" content={window.location} />
            <meta property="og:image" content={imageAddress(this.state.data?.values?.image)} />
            {/* <meta property="og:description" content={stripHtml(this.state.data?.values?.summery)} /> */}

            <meta property="twitter:card" content={imageAddress(this.state.data?.values?.image)} />
            <meta property="twitter:title" content={this.state.data?.values?.title} />
            {/* <meta property="twitter:description" content={stripHtml(this.state.data?.values?.summery)} /> */}
            {/* {console.log} */}
            {/* <link rel="canonical" href="http://mysite.com/example" /> */}
          </Helmet>

          <div style={{ backgroundImage: 'url(' + imageAddress(this.state.data?.values?.image, null, 'small') + ')', backgroundSize: 'cover', position: 'relative', marginTop: 45, backgroundColor: '#f2f4f6', width: '100%', borderRadius: 0, height: '60vh', minHeight: '400px', objectFit: 'cover' }}>
            {/* <img ref={"mainImage"} src={imageAddress(this.state.data?.values?.image,null,'small')} style={{ }} /> */}

            <div style={{ textAlign: 'center', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: '#20202070', display: 'flex', flexDirection: 'column', padding: '20px 70px', justifyContent: 'center' }}>
              <h1 className="boldiransansfont" style={{ alignItems: 'center', margin: 0, color: '#fff', opacity: 1, fontSize: 45, fontWeight: 'bold' }}>{this.state.data?.values?.title}</h1>
              <p style={{ fontWeight: 'bold', fontSize: 20, color: '#fff' }}>{moment(this.state.data?.date).locale('fa').format("jDD jMMMM, jYYYY")}</p>
              {/* <p style={{ fontWeight: 'bold', fontSize: 20, color: '#fff' }}>{this.state.data?.values?.category}</p> */}
            </div>
          </div>

          <div className="" style={{ position: 'relative', paddingLeft: '3%', paddingRight: '3%' }}>


            <div className='row' >


              <div className='col-12 mt-1  pt-0'>


                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className=' mt-3 ' style={{ width: '95%', maxWidth: '800px', borderRadius: 8 }}>
                    <div className=" mb-2 mediumiransansfont " style={{ marginTop: 10 }}>
                      <div className="container" style={{ borderRadius: 8, padding: 30 }}>
                        <div className="row">
                          <div className="col-12 mb-2">

                            <div style={{ color: '#202020', fontSize: 16, fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: this.state.data?.values?.summery }}></div>


                            {/* <div className="d-flex justify-content-between">

                              <div className={' mt-1 d-flex'}>
                                <a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href + "&t=" + this.state.data?.values?.title}><img src={'img/facebook.svg'} style={{ height: 27, opacity: 0.9, marginRight: 10 }} /></a>
                                <a target="_blank" href={"https://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href}><img src={'https://image.flaticon.com/icons/svg/145/145807.svg'} style={{ height: 27, opacity: 0.9, marginRight: 10 }} /></a>
                                <a target="_blank" href={"https://twitter.com/intent/tweet?url=" + window.location.href}><img src={'img/twitter.svg'} style={{ height: 27, opacity: 0.9, marginRight: 10 }} /></a>
                                <a target="_blank" href={"https://telegram.me/share/url?url=" + window.location.href}><img src={'img/telegram.svg'} style={{ height: 27, opacity: 0.9, marginRight: 10 }} /></a>
                                <a target="_blank" href={"mailto:?body=" + window.location.href}><img src={'https://image.flaticon.com/icons/svg/552/552486.svg'} style={{ height: 27, opacity: 0.9, marginRight: 10 }} /></a>
                                <a target="_blank" href={"whatsapp://send?text=" + window.location.href}><img src={'img/whatsapp.svg'} style={{ height: 27, opacity: 0.9, marginRight: 10 }} /></a>
                              </div>
                            </div> */}
                          </div>
                          {/* <div className="col-12 col-md-6 d-flex  justify-content-end align-items-start">
                            <div className="mt-2 d-inline-block">


                              <div className="mt-1" onClick={() => this.refs.infoModal.showModal()} style={{ cursor: 'pointer', backgroundColor: '#e1e2e3', color: '#999', padding: 7, paddingLeft: 15, paddingRight: 15, borderRadius: 6 }}>
                                <p>اشتراک گذاری</p>
                              </div>
                            </div>

                          </div> */}
                          <div className="col-12 mt-0 page-loader mb-5">
                            <div style={{ color: '#202020', fontSize: 16 }} dangerouslySetInnerHTML={{ __html: this.state.data?.values?.body }}></div>
                          </div>
                        </div>
                      </div>

                    </div>



                  </div>

                </div>




              </div>


              {/* 
              <div className="col-12  mb-5 mediumiransansfont  " style={{ marginTop: 10 }}>
                <div className="container">

                  <Tabbar data={this.state.tabbarData} onClick={this.changeTab} pageViewer={this.refs.pageViewer} />
                  <TabbarViewer ref={'pageViewer'} pages={['Blog']} data={this.state.similarData} />


                </div>
              </div> */}
            </div>
          </div>

          <div>

          </div>



        </div>
      );
    } else {
      return (
        <div style={{ minHeight: '100vh', textAlign: 'center', width: '100%', paddingTop: 150 }}>
          <Loader
            type="Oval"
            color="rgba(0,122,255,1)"
            height="50"
            width="50"
          />
        </div>
      )
    }
  }
}

export default Blog;
