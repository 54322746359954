import React from 'react'
import { translate, checkTextTranslation } from '../utils/useful'
import TLink from './TLink'

class Hero extends React.Component {
    state = {
        key: ''
    }


    render() {
        return (
            <div className="row flexcc w-100 p-0 m-0" style={{ background: this.props.background, height: this.props.height ? this.props.height : '100vh', position: 'relative' }}>

                {this.props.type === 'image' && (
                    <div className="position-absolute w-100 h-100" style={{ top: 0, left: 0, backgroundImage: "url(" + this.props.source + ")", backgroundPosition: 'center', backgroundSize: 'cover', }}></div>
                )}

                <div className="position-absolute w-100 h-100" style={{ background: '#10152050' }}>
                </div>

                <div className="text-center w-100 px-3" style={{ position: 'relative' }}>
                    {/* <h1 className="text-mega-big" style={{ margin: 0, color: '#fff', fontWeight: '600' }}>{translate('Opia Home')}</h1> */}
                    <img className="invert" src={"/images/drAdib.png"} width="300px" alt="logo" />
                    <p className=" white text-ultra-big mt-2 font-bold">دندان پزشکی دکتر ادیب</p>
                    <p className=" white text-normal mt-2">دکتر محمود ادیب – جراح دندانپزشک – ایمپلنتولوژیست</p>

                    {/* <div className="flexcc w-100">
                        <div className="flexcc w-100" style={{ padding: '0px 10px', height: 50, width: '80%', maxWidth: 500, backgroundColor: '#ffffffee', borderRadius: 8, border: '1px solid #fff', backdropFilter: 'blur(10px) saturate(180%)', }}>
                            <div>
                                <button className="p-2 search-border">{checkTextTranslation('{{lang}}photos')}</button>
                            </div>
                            <input className="w-100 h-100" placeholder="Search all recources ..." style={{ padding: '5px 20px', fontSize: 18, backgroundColor: 'transparent', border: 'none' }} />
                            <button className="p-0 mt-1"><img src="https://image.flaticon.com/icons/svg/483/483356.svg" height="30px" alt="" /></button>
                        </div>
                    </div> */}
                    <div className="flexcc mt-4">
                        <TLink to={'/services'} className="mrd-2 blur-back " style={{ borderRadius: 30, color: '#202020', background: '#ffffffdd', border: '#fff 2px solid', padding: '8px 30px', fontSize: 14 }}>{translate('خدمات ما')}</TLink>
                        <TLink to={'/aboutus'} className="mld-2 blur-back " style={{ borderRadius: 30, color: '#202020', background: '#ffffffdd', border: '#fff 1px solid', padding: '8px 30px', fontSize: 14, }}>{translate('درباره دکتر ادیب')}</TLink>
                    </div>
                    {/* <div className="mt-4 ph-3">
                        <span className=" white text-normal">{translate('Chilik brought revoloution for photography inductry')}</span>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default Hero