import React from 'react';
import { translate, checkTextTranslation } from '../../utils/useful'
import Section from '../../components/Section'
import TLink from '../../components/TLink';
import HttpService from '../../utils/Http.services';
import { siteConfig } from '../../variables/config';
import stripHtml from "string-strip-html";
import { imageAddress } from '../../utils/useful'
import Loader from 'react-loader-spinner'
import FastHelmet from '../../components/FastHelmet';

class Categories extends React.Component {


  state = {
    data: []
  }


  componentDidMount() {
    this.fetch()
    this.setWidth()
    window.addEventListener('resize', this.setWidth)
  }

  setWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  fetch() {
    this.setState({ isLoadingPge: true })

    HttpService.request("getAllWithData", { applet: 'categories', sort: { 'values.priority': 1 } }, (fetchResult, fetchError) => {
      this.setState({ isLoadingPge: false })

      if (fetchError) { return }
      // console.log(fetchResult.info)
      this.setState({ data: fetchResult.info })
    })
  }

  render() {

    return (
      <section style={{ paddingBottom: 80 }}>

        <FastHelmet title={'محصولات اوپیا'} description={'لیست محصولات اوپیا'} />


        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#000' }}>
          {/* <h1 className=" text-ultra-big white">{checkTextTranslation('{{lang}}welcome-to')}</h1> */}
          <h1 className=" text-mega-big white">{translate("opia-products")}</h1>
        </div>



        {this.state.data.map((prop, index) => {
          return (
            <Section key={index} className={'flexcc mt-5'} background={'#fff'}>
              <div className="container px-5 px-md-0">
                <div className="row ">

                  {((index % 2 === 1) || this.state.width < 768) && (
                    <div className="col-12 col-md-6 flexc" style={{ justifyContent: 'flex-end' }}>
                      <img src={imageAddress(prop.values?.image, null, 'small')} style={{ width: '100%', borderRadius: 15, objectFit: 'contain' }} alt="" />
                    </div>
                  )}


                  <div className="mb-5 mb-md-0 col-12 col-md-6 d-flex flex-column justify-content-center ">
                    <TLink pathname={'/products/' + prop.values.slug} className="mt-1 mt-md-0" ><h2 className=" text-mega-big mb-2 mt-0">{prop.values?.name}</h2></TLink>


                    {prop.values?.description && (
                      <p className=" text-normal" style={{ color: '#456' }}>{stripHtml(prop.values?.description)}</p>
                    )}

                    {/* <h1 className=" text-semi-big mt-2">Availavle as:</h1> */}

                    {/* <div className="d-flex mb-3 mt-2">
                      <p className=" text-semi-big mt-2 mrd-3 font-bold" style={{ backgroundColor: '#f2f6f8', borderRadius: 8, padding: '5px 15px' }}>Normal</p>
                      <p className=" text-semi-big mt-2 mrd-3 font-bold" style={{ backgroundColor: '#f2f6f8', borderRadius: 8, padding: '5px 15px' }}>Plus</p>
                      <p className=" text-semi-big mt-2 mrd-3 font-bold" style={{ backgroundColor: '#f2f6f8', borderRadius: 8, padding: '5px 15px' }}>Luxury</p>
                      <p className=" text-semi-big mt-2 mrd-3 font-bold" style={{ backgroundColor: '#f2f6f8', borderRadius: 8, padding: '5px 15px' }}>Special</p>
                    </div> */}
                    
                    <div className="mt-4">
                      <TLink pathname={'/products/' + prop.values.slug} className="mb-4" style={{ backgroundColor: '#e4eaf2', whiteSpace: 'nowrap', padding: '10px 20px', color: '#456', textAlign: 'center', borderRadius: 10, fontSize: 14 }}>{translate('view-products')}</TLink>
                    </div>

                  </div>
                  {(index % 2 !== 1) && this.state.width >= 768 && (
                    <div className="col-12 col-md-6 flexc">
                      <img src={imageAddress(prop.values?.image, null, 'small')} style={{ width: '100%', borderRadius: 15, objectFit: 'contain' }} alt="" />
                    </div>
                  )}
                </div>
              </div>
            </Section>
          )
        })}

        {this.state.isLoadingPge && (
          <div style={{ textAlign: 'center', width: '100%', paddingTop: 90, }}>
            <Loader
              type="Oval"
              color="rgba(20,20,20,1)"
              height="50"
              width="50"
            />
          </div>
        )}

        {!this.state.isLoadingPge && this.state.data?.length == 0 && (
          <div className="mt-5">
            <p className="text-center mb-3">{translate('found-nothing')}</p>
          </div>
        )}


      </section>
    );
  }
}

export default Categories;
