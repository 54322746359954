import React from 'react';
import { translate, phoneStandardView, checkTextTranslation, chooseWallpaper } from '../../utils/useful'
import { siteConfig } from '../../variables/config';
import TLink from '../../components/TLink'
import FormViewer from '../../components/FormViewer'
import LoaderButton from '../../components/LoaderButton';
import HttpService from '../../utils/Http.services';
import FastHelmet from '../../components/FastHelmet';

class RaeesAboutus extends React.Component {


  state = {
    stage: 0,
    socialNetworks: [
      { name: 'facebook', icon: 'https://image.flaticon.com/icons/svg/1409/1409943.svg' },
      { name: 'linkedin', icon: 'https://image.flaticon.com/icons/svg/1409/1409945.svg' },
      { name: 'instagram', icon: 'https://image.flaticon.com/icons/svg/2111/2111463.svg' },
      { name: 'youtube', icon: 'https://image.flaticon.com/icons/svg/2965/2965363.svg' },
      { name: 'aparat', icon: 'https://cdn.iconscout.com/icon/free/png-512/aparat-569197.png' },

    ],



    headers: [

      { key: 'name', type: 'TextInput', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertName', required: true } },
      { key: 'phone', type: 'TextInput', information: { label: '{{lang}}phone', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertPhone', required: true } },
      { key: 'message', type: 'TextAreaInput', information: { label: '{{lang}}Message', placeholder: '{{lang}}insertMessage', rows: 4, required: true } },

      // { type: 'TextInput', key: 'name', information: { title: 'Name', required: true } },
      // { title: 'Phone', type: 'TextInput', key: 'phone', },
      // { title: 'Email', type: 'TextInput', key: 'email', },
      // { title: 'Message', type: 'TextAreaInput', key: 'body', settings: { required: true } },
      // { title: 'Section', key: 'section', type: 'select', options: { type: 'local', items: [{ title: "Sale", value: 'sale' }, { title: 'Managment', value: 'managment' }] }, settings: { required: true }, showMain: true },
    ],
    // services: [
    //   { title: 'رهن و اجاره', image: 'https://image.flaticon.com/icons/svg/2095/2095557.svg', desc: 'با حذف واسطه‌‌ها توانسته ایم پایینترین قیمت و بالاترین کیفیت را با ارسال سریع و مستقیم برای مشتریان خود فراهم آوردیم' },
    //   { title: 'خرید و فروش', image: 'https://image.flaticon.com/icons/svg/2095/2095555.svg', desc: 'برند اوپیا با استفاده از به روزترین تکنولوژی اروپا و استفاده از بهترین مواد اولیه توانسته است محصولی رقابتی را به بازار جهانی ارائه دهد' },
    //   { title: 'پیش فروش', image: 'https://image.flaticon.com/icons/svg/2095/2095575.svg', desc: 'مفتخریم با راه اندازی فروشگاه آنلاین اختصاصی تمام محصولات خود را به ساده ترین شکل ممکن در اختیار مشتریان گرامی قرار داده ایم' },
    //   { title: 'مشارکت', image: 'https://image.flaticon.com/icons/svg/2095/2095538.svg', desc: 'این امکان را برای شما در نظر گرفته ایم که با آسودگی خاطر٬ در مدت ۱۰ روز در صورت عدم باز شدن بسته کالابرای بازگرداندن آن اقدام کنید' }
    // ],

    services: [
      { title: 'قیمت ایده‌آل ', image: 'https://image.flaticon.com/icons/svg/181/181036.svg', desc: 'ارائه بهترین قیمت آثار به نسبت سایت‌های مشابه خارجی و داخلی' },
      { title: 'کاربری آسان', image: 'https://image.flaticon.com/icons/svg/180/180778.svg', desc: 'ایجاد فضایی کاربردی با قابلیت دسترسی راحت به تمامی بخش‌ها' },
      { title: 'برترین کیفیت‌ها', image: 'https://image.flaticon.com/icons/svg/181/181022.svg', desc: 'ارائه باکیفیت‌ترین عکس‌ها و وکتورها به کاربران' },
      { title: 'معرفی جهانی آثار', image: 'https://image.flaticon.com/icons/svg/181/181035.svg', desc: 'تمام جهان، صحنه‌ی نمایش هنرمندان چیلیک خواهد بود' }
    ],


    fastAccess: [
      { title: '{{lang}}faq', link: '/pages/faq', image: '/images/faq.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' },
      { title: '{{lang}}blogs', link: '/blogs', image: '/images/blog.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' },
      { title: '{{lang}}addEstate', link: '/addestate', image: '/images/policies.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' }],

  }


  componentDidMount() {
    this.fetchWallpapers()
  }


  fetchWallpapers() {

    HttpService.request('getAllWithData', { applet: 'wallpapers', filter: { 'values.page': 'درباره ما' } }, (fetchResult, fetchError) => {
      // console.log(fetchResult)
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ wallpapers: fetchResult.info })
      }
    })
  }


  sendContact = () => {
    let data = {}
    data.values = this.form.getForm()
    if (data.values) {
      this.setState({ isLoading: true, errors: {} })

      data.applet = 'contacts'
      console.log(data)
      HttpService.request('submitForm', data, (fetchResult, fetchError) => {
        console.log(fetchError)
        console.log(fetchResult)
        this.setState({ isLoading: false })
        if (fetchError) { this.setState({ errors: fetchError }); return }
        this.setState({ stage: 1 })
      })
    }

  }

  render() {

    return (
      <section style={{ paddingBottom: 80, backgroundColor: '#f2f6f8' }}>

        <FastHelmet title={translate('aboutus')} description={translate('aboutus')} />


        {/* <div className="text-center flexcc" style={{ padding: '40px 20px 40px 20px', position: 'relative', backgroundColor: '#bbccdd', backgroundImage: "url(" + chooseWallpaper(this.state.wallpapers) + ")", height: '60vh', backgroundSize: 'center', backgroundPosition: 'center' }}>
          <div className="position-absolute w-100 h-100" style={{ background: '#00000070', top: 0, left: 0 }}>
          </div>
          <div style={{ position: 'relative' }}>
            <h1 className=" text-mega-big white" >درباره ما</h1>
            <p className=" text-small white mt-2 opacity-5" style={{ letterSpacing: 2 }} >RAEESMELK.COM</p>

          </div>
        </div> */}

        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#000' }}>
          <h1 className=" text-mega-big white">{translate('aboutus')}</h1>
        </div>



        <div style={{ paddingTop: 10, marginTop: 0, paddingBottom: 30, backgroundColor: '#fff' }}>
          <div className="container mb-5 mediumiransansfont"  >


            <div className={' pr-0 mb-1 mt-5 d-flex align-items-center justify-content-center'} >
              <div className="text-center">
                <h2 className=' mb-1 text-ultra-big' style={{ marginTop: 0, whiteSpace: 'nowrap', color: '#13191d', marginLeft: 20, marginRight: 20, fontWeight: 'bold' }}>خدمات ما</h2>
              </div>
            </div>

            <div className={'row text-center mt-5'}>
              {this.state.services.map((prop, index) => {
                return (
                  <div key={index} className="col-6 col-sm-6 col-md-3 col-lg-3 mt-4">
                    <img src={prop.image} style={{ height: 100, marginBottom: 5 }} />
                    <p className={'text-big font-bold mt-2'}>{prop.title}</p>
                    <p className={'text-small mt-2'}>{prop.desc}</p>
                  </div>
                )
              })}

            </div>

          </div>
        </div>



        <div className="container mt-5" ref="contact">
          <div className="row pt-4 d-flex pb-4 mb-5">
            <div className="col-12 col-lg-6">
              <div className="w-100 h-100" style={{ backgroundColor: '#fff', padding: "30px 30px 10px 30px", borderRadius: 8, marginBottom: 30 }}>

                {this.state.stage == 0 && (
                  <div>
                    <p className="text-center font-weight-bold mt-4" style={{ fontSize: 18 }}>{translate('contact-us')}</p>
                    {/* <p className="text-center  mt-1 mb-5" style={{ fontSize: 15 }}>Please let us know your enquiry</p> */}
                    <FormViewer headers={this.state.headers} ref={el => this.form = el} inputClass={'modern-input'} />

                    <div className="text-center">
                      <LoaderButton
                        onClick={this.sendContact}
                        isLoading={this.state.isLoading}
                        text={translate('submit')}
                        type={"Oval"}
                        className="mt-4 mb-4"
                        buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                        width={40}
                        height={40}
                        color={'#202020'}
                      />
                    </div>

                  </div>
                )}

                {this.state.stage == 1 && (
                  <div className="text-center mt-2" style={{ padding: 30 }}>
                    <div className="mb-4" style={{ backgroundColor: '#2cb5e820', padding: 20, borderRadius: 8 }}>
                      <p className=" " style={{ fontSize: 14, color: '#789' }}>{translate('contact-sent')}</p>
                      <p onClick={() => this.setState({ stage: 0 })} className=" mt-2" style={{ cursor: 'pointer', fontSize: 14, color: '#007aff' }}>{translate('send-new-contact')}</p>

                    </div>
                  </div>
                )}

              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="w-100 h-100" style={{ backgroundColor: '#fff', padding: 30, borderRadius: 8, marginBottom: 30 }}>
                <p className="text-center font-weight-bold mt-4 mb-3" style={{ fontSize: 18 }}>{translate('contact-info')}</p>

                <div>
                  <div className="d-flex align-items-center "><img src="https://image.flaticon.com/icons/svg/2399/2399925.svg" width="40px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="text-small" style={{ color: '#222' }}></span><span>{siteConfig.name}</span></p></div>
                  {siteConfig.addressFa && (<div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/636/636199.svg" width="40px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="text-small" style={{ color: '#222' }}>آدرس: </span><span>{siteConfig.addressFa}</span></p></div>)}
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/684/684912.svg" width="40px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="text-small" style={{ color: '#222' }}>شماره تماس: </span><a style={{ color: '#007aff' }} href={"tel:" + siteConfig.phone}><span>{phoneStandardView(siteConfig.phone)}</span></a></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/726/726623.svg" width="40px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="text-small" style={{ color: '#222' }}>ایمیل: </span><a style={{ color: '#007aff' }} href={"mailto://" + siteConfig.email}>{siteConfig.email}</a></p></div>

                  {this.state.socialNetworks.map((prop, index) => {
                    if (siteConfig[prop.name] && siteConfig[prop.name] != '')
                      return (
                        <div key={index} className="d-flex align-items-center mt-4">
                          <img src={prop.icon} width="40px" />
                          <p className="mld-4" style={{ lineHeight: 1, color: '#456' }}>
                            <span className="text-small" style={{ color: '#222' }}>{translate(prop.name)}: </span>
                            <a rel="no-follow" className="text-small " href={siteConfig[prop.name]} style={{ color: '#007aff' }}>{translate("follow-us")}</a>
                          </p>
                        </div>
                      )
                  })}

                  {/* <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/1409/1409945.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">Linkedin: </span><a href={siteConfig.facebook}>Follow us</a></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/1409/1409943.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">Facebook: </span><a href={siteConfig.linkedin}>Follow us</a></p></div> */}

                </div>
              </div>
            </div>


          </div>
        </div>



        <div style={{ marginTop: 0, paddingBottom: 10, }}>
          <div className="container mb-5 mediumiransansfont"  >
            <div className={' pr-0 mb-1 mt-5 d-flex align-items-center justify-content-center'} >
              <div className="text-center">
                <h2 className=' mb-1 ' style={{ fontSize: 26, marginTop: 0, whiteSpace: 'nowrap', color: '#13191d', marginLeft: 20, marginRight: 20, fontWeight: 'bold' }}>{translate("learn-more-about-us")}</h2>
              </div>
            </div>

            <div className={'row text-center mt-3 ml-1 mr-1'}>
              {this.state.fastAccess.map((prop, index) => {
                return (
                  <TLink to={prop.link} className="col-12  col-md-4 col-lg-4 mt-4">
                    <div>
                      <img src={prop.image} style={{ boxShadow: '0px 0px 30px rgba(0,0,0,0.1)', width: '100%', marginBottom: 5, borderRadius: 8 }} />
                      <p className={'text-big font-bold'}>{checkTextTranslation(prop.title)}</p>
                      {/* <p className={'info-desc pr-1 pl-1 pr-md-0 pl-md-0'} style={{ fontSize: 13 }}>{prop.desc}</p> */}
                    </div>
                  </TLink>
                )
              })}

            </div>

          </div>
        </div>





      </section>
    );
  }
}

export default RaeesAboutus;
