import React from 'react'
import LoaderButton from '../LoaderButton'
// import FormViewer from '../FormViewer'
import TLink from '../TLink'
import { getObject, translate } from '../../utils/useful'

class ResourceTypeStep extends React.Component {
    state = {
        key: '',
        selectedType: 'photo',
        data: {},
        isLoading: false,
        formHeaders: []
    }



    nextStage = () => {
        this.setState({ error: null })

        if (getObject(this.props.data, 'values.resourceType') == null) {
            this.setState({ error: 'Choose one type to continue' })
        } else {
            this.props.chooseResourceStep()
        }
    }

    changeType(type) {
        this.props.setData({ "values.resourceType": type })
    }


    render() {
        return (
            <div className="flexcc flex-column">


                <h2 className="mt-3 mb-1" style={{ fontSize: 27 }}>{translate('resource-type')}</h2>
                <div className="w-100" style={{ maxWidth: 400 }}>

                    <p className="text-center" style={{ fontSize: 14, margin: '0px 20px 20px 20px' }}>{translate('resource-type-desc')}</p>

                    <div className="row w-100 mt-5 mb-5 text-center">
                        <div className="col-12 col-md-6">
                            <button onClick={() => this.changeType('photo')} className="hover-back p-3 transition-all border-radius-8" style={{ backgroundColor: getObject(this.props.data, 'values.resourceType') === 'photo' ? '#def' : null, border: getObject(this.props.data, 'values.resourceType') === 'photo' ? '1px #bcd dashed' : '1px transparent solid' }}>
                                <img src="https://image.flaticon.com/icons/svg/3039/3039446.svg" width="100px" />
                                <p className="font-bold text-big">{translate('photo')}</p>
                            </button>
                        </div>

                        <div className="col-12 col-md-6">
                            <button onClick={() => this.changeType('vector')} className="hover-back p-3 transition-all border-radius-8" style={{ backgroundColor: getObject(this.props.data, 'values.resourceType') === 'vector' ? '#def' : null, border: getObject(this.props.data, 'values.resourceType') === 'vector' ? '1px #bcd dashed' : '1px transparent solid' }}>
                                <img src="https://image.flaticon.com/icons/svg/2919/2919740.svg" width="100px" />
                                <p className="font-bold text-big">{translate('vector')}</p>
                            </button>
                        </div>

                    </div>

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.nextStage}
                            isLoading={this.state.isLoading}
                            text={translate('next-step')}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                        {this.state.error && (
                            <p style={{ fontSize: 15, margin: '5px 0px', color: '#ee5050' }}>{this.state.error}</p>
                        )}

                        <TLink to={'/pages/terms'}><p style={{ fontSize: 15, margin: '10px 20px 20px 20px' }}>{translate('resource-accept-terms')}</p></TLink>

                        {/* <p style={{ fontSize: 15, margin: '10px 20px 20px 20px' }}>With sending resources you are accepting our partner <TLink pathname="/pages/terms" style={{ color: '#007aff' }}>terms</TLink> and <TLink pathname="/pages/policies" style={{ color: '#007aff' }}>Policies</TLink></p> */}
                        {/* <p style={{ fontSize: 13, opacity: 0.5, marginTop: 10 }}>This website uses cookies to deliver the best possible user experience. With using the website you are accepting it</p> */}
                    </div>

                    <div className="w-100 text-center mb-5">
                        <button className="mb-1" onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate('back-one-stage')}</button>
                    </div>


                </div>
            </div>
        )
    }
}

export default ResourceTypeStep;