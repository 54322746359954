import React from "react";


class Modal extends React.Component {
    state = {
        opacity: 1,
        showModal: false,
        maxWidth: 600
    }

    hideModal = (cb) => {
        document.body.classList.remove("body-no-sroll")

        if (this.state.showModal) {

            this.setState({ showModal: false }, () => {

                if (this.props.onHide) {
                    this.props.onHide()
                }
                if (cb) {
                    cb()
                }

            })


        }
    }

    showModal = (cb) => {
        this.setState({ opacity: 1 })
        this.setState({ showModal: true })
        document.body.classList.add("body-no-sroll")

        if (cb) {
            cb()
        }
    }

    componentDidMount() {
        if (this.props.maxWidth) {
            this.setState({ maxWidth: this.props.maxWidth })
        }
    }

    render() {
        if (this.state.showModal) {
            return (
                <div style={{ ...styles, opacity: this.state.opacity }}>
                    <button style={{ ...backStyles }} onClick={() => { if (!this.props.notClose) this.hideModal() }}></button>

                    <button style={{ position: 'absolute', top: 15, right: 15 }} onClick={() => { if (!this.props.notClose) this.hideModal() }}>
                        <img src="https://image.flaticon.com/icons/svg/1828/1828778.svg" style={{ width: 20, height: 20, filter: 'invert(100%)' }} />
                    </button>

                    <div className={" " + (this.props.position === "center" ? 'flexcc' : '')} style={{ paddingTop: '3%', paddingBottom: '3%', overflow: 'auto', width: '100%', backgroundColor: '#ee5050530', height: this.props.fullHeight ? 'auto' : '100%', }}>
                        <div className="no-scrollbar fadein" style={{ ...contentStyle, maxWidth: this.state.maxWidth, overflow: this.props.overflow ? this.props.overflow : 'auto' }}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div></div>
            )
        }
    }
}


const styles = {
    transition: 'all 0.3s ease-out , z-index 0s linear ',
    backdropFilter: 'blur(10px) saturate(180%)',
    WebkitBackdropFilter: 'blur(10px) saturate(180%)',

    textAlign: 'right',
    backgroundColor: 'rgba(20,30,40,0.4)',
    position: 'fixed',
    width: '100%',
    height: '100vh',
    zIndex: 50000,
    top: 0,
    left: 0,
    // overflow: 'auto',
    // paddingTop: '10%',
    paddingLeft: '0%',
    justifyContent: 'center',
    alignItems: 'center'
    // overflow:'auto',

}


const backStyles = {

    position: 'fixed',
    width: '100%',
    // height: '100%',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    // paddingTop:'10%',
    // paddingLeft:'0%',

}



const contentStyle = {
    position: 'relative',
    backgroundColor: '#fff',
    transition: 'all 0.5s',
    // transition:'height 0.5s',
    margin: 'auto',

    padding: 0,
    border: '0px solid #888',
    width: '90%',
    // maxHeight:'80%',
    borderRadius: 10,
    overflow: 'auto',
    // maxHeight: '100%',
    boxShadow: '0 4px 30px rgba(0,0,0,0.05),0 6px 20px 20px rgba(0,0,0,0.05)'
    // -webkit-animation-name: animatetop;
    // -webkit-animation-duration: 0.4s;
    // animation-name: animatetop;
    // animation-duration: 0.4s
}

export default Modal;
