import React from 'react';
import { translate, imageAddress } from '../../utils/useful'
import Section from '../../components/Section'
import TLink from '../../components/TLink';
import HttpService from '../../utils/Http.services';
import stripHtml from "string-strip-html";
import { siteConfig } from '../../variables/config';
import Loader from 'react-loader-spinner'
import DataGrid from '../../components/DataGrid'
import Product from '../../components/SingleProduct'

class LomanabProducts extends React.Component {


  state = {
    data: []
  }

  componentDidMount() {
    this.fetch()
  }


  fetch() {
    this.setState({ isLoading: true })
    HttpService.request("getAllWithData", { applet: 'products' }, (fetchResult, fetchError) => {
      this.setState({ isLoading: false })

      if (fetchError) { return }

      // console.log(fetchResult.info)
      this.setState({ data: fetchResult.info })
    })
  }


  render() {

    return (
      <section style={{ paddingBottom: 80 }}>

        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#f2f6f8' }}>
        <h1 className=" text-mega-big">محصولات لوماناب</h1>
          <h1 className=" text-big" style={{color:'#9ab',letterSpacing:5}}>Lomanab.ir</h1>

        </div>



        {/* {this.state.data.map((prop, index) => {
          return ( */}
        <div className="container mt-4">
          <div className="row">

            <div className="col-12 mb-5 mt-4">
              <DataGrid isLoading={this.state.isLoading} data={this.state.data} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Product} col="col-12 col-md-6 col-lg-4" />
            </div>
          </div>
        </div>
        {/* })} */}


      </section>
    );
  }
}

export default LomanabProducts;
