import React from 'react'
import { checkTextTranslation } from '../../utils/useful'

class NumberInput extends React.Component {
    state = {
    }


    changeInputValue(target) {
        let value = (target.validity.valid) ? target.value : null
        if (value !== null) {
            // console.log(Number(value))
            this.props.changeValue(this.props.header.key, Number(value))
        }
    }


    render() {
        return (
            <div className="w-100 h-100">
                <input ref={el => this.input = el} pattern="[0-9]*" value={this.props.data!=null ? this.props.data : ''} onChange={(e) => this.changeInputValue(e.target)} placeholder={checkTextTranslation(this.props.header.information.placeholder)} maxLength={this.props.header.information.maxLength} className="transpanet-input" />
            </div>
        )
    }
}

export default NumberInput;