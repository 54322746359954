import React from 'react';
import { translate, phoneStandardView, checkTextTranslation, converNumberToPersian } from '../../utils/useful'
import { siteConfig } from '../../variables/config';
import TLink from '../../components/TLink'
import FormViewer from '../../components/FormViewer'
import LoaderButton from '../../components/LoaderButton';
import HttpService from '../../utils/Http.services';
import FastHelmet from '../../components/FastHelmet';

class Aboutus extends React.Component {


  state = {
    stage: 0,
    socialNetworks: [
      { name: 'facebook', icon: 'https://image.flaticon.com/icons/svg/1409/1409943.svg' },
      { name: 'linkedin', icon: 'https://image.flaticon.com/icons/svg/1409/1409945.svg' },
      { name: 'instagram', icon: 'https://image.flaticon.com/icons/svg/2111/2111463.svg' },
      { name: 'youtube', icon: 'https://image.flaticon.com/icons/svg/2965/2965363.svg' },
      { name: 'aparat', icon: 'https://cdn.iconscout.com/icon/free/png-512/aparat-569197.png' },

    ],



    headers: [

      { key: 'name', type: 'TextInput', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertName', required: true } },
      { key: 'phone', type: 'TextInput', information: { label: '{{lang}}phone', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertPhone', required: true } },
      { key: 'message', type: 'TextAreaInput', information: { label: '{{lang}}Message', placeholder: '{{lang}}insertMessage', rows: 4, required: true } },

      // { type: 'TextInput', key: 'name', information: { title: 'Name', required: true } },
      // { title: 'Phone', type: 'TextInput', key: 'phone', },
      // { title: 'Email', type: 'TextInput', key: 'email', },
      // { title: 'Message', type: 'TextAreaInput', key: 'body', settings: { required: true } },
      // { title: 'Section', key: 'section', type: 'select', options: { type: 'local', items: [{ title: "Sale", value: 'sale' }, { title: 'Managment', value: 'managment' }] }, settings: { required: true }, showMain: true },
    ],
    services: [
      { title: 'حذف واسطه‌ها', image: '/images/181036.svg', desc: 'در انبار مازاد، خریدار و فروشنده بدون هیچ واسطه ای می توانند به طور مستقیم با هم ارتباط گرفته و خرید انجام شود. این ارتباط گیری مستقیم سبب می شود علاوه بر کاهش هزینه های واسطه گری؛ بر حسب شرایط خریدار و فروشنده، نحوه ی پرداخت و یا نوع ارسال طبق آنچه طرفین توافق می کنند صورت گیرد و این موارد نیز منحصر به یک شرایط خاص نشوند' },
      { title: 'درج رایگان آگهی', image: '/images/180778.svg', desc: 'درج آگهی توسط کاربران رایگان انجام می شود و کاربران می توانند به راحتی نسبت به درج آگهی های مربوط به اقلام خود اقدام نمایند. با معرفی آنلاین آگهی ها، امکان دیده شدن آگهی ها توسط کاربران زیادی در وب فراهم می شود' },
      { title: 'دسته بندی کاربردی', image: '/images/181022.svg', desc: 'با توجه به این که انبار مازاد به طور تخصصی در زمینه ی رشته های مربوط به برق، الکترونیک و مخابرات فعالیت می کند، دسته بندی اقلام به طور ویژه برای این اقلام انجام شده و کاربران راحت تر می توانند نسبت به خرید و فروش در این حوزه اقدام کنند' },
      { title: 'جست و جوی سریع', image: '/images/2910447.svg', desc: 'براحتی می توانید از طریق بخش جستجو، نسبت به جستجوی اقلام مدنظر خود اقدام نمائید و یا از طریق دسته بندی سایت، کالاهای موجود در دسته ی مدنظر خود را مشاهده کنید' }
    ],

    // services: [
    //   { title: 'قیمت ایده‌آل ', image: '/images/181036.svg', desc: 'ارائه بهترین قیمت آثار به نسبت سایت‌های مشابه خارجی و داخلی' },
    //   { title: 'کاربری آسان', image: 'https://image.flaticon.com/icons/svg/180/180778.svg', desc: 'ایجاد فضایی کاربردی با قابلیت دسترسی راحت به تمامی بخش‌ها' },
    //   { title: 'برترین کیفیت‌ها', image: 'https://image.flaticon.com/icons/svg/181/181022.svg', desc: 'ارائه باکیفیت‌ترین عکس‌ها و وکتورها به کاربران' },
    //   { title: 'معرفی جهانی آثار', image: 'https://image.flaticon.com/icons/svg/181/181035.svg', desc: 'تمام جهان، صحنه‌ی نمایش هنرمندان چیلیک خواهد بود' }
    // ],


    fastAccess: [
      { title: '{{lang}}faq', link: '/pages/faq', image: '/images/faq.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' },
      { title: '{{lang}}terms', link: '/pages/terms', image: '/images/blog.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' },
      { title: 'مقالات', link: '/pages/blogs', image: '/images/policies.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' }],

  }

  sendContact = () => {
    let data = {}
    data.values = this.form.getForm()
    if (data.values) {
      this.setState({ isLoading: true, errors: {} })

      data.applet = 'contacts'
      console.log(data)
      HttpService.request('submitForm', data, (fetchResult, fetchError) => {
        console.log(fetchError)
        console.log(fetchResult)
        this.setState({ isLoading: false })
        if (fetchError) { this.setState({ errors: fetchError }); return }
        this.setState({ stage: 1 })
      })
    }

  }

  render() {

    return (
      <section style={{ paddingBottom: 80, backgroundColor: '#fff' }}>

        <FastHelmet title={translate('aboutus')} description={translate('aboutus')} />

        <div className="text-center" style={{ padding: '40px 20px 40px 20px', backgroundColor: '#FF6C00' }}>
          {/* <h1 className=" text-ultra-big white">{siteConfig.siteName}</h1> */}
          <h1 className=" text-mega-big white">{translate('aboutus')}</h1>
        </div>


        <div style={{ paddingTop: 10, marginTop: 0, paddingBottom: 30, backgroundColor: '#fff' }}>
          <div className="container mb-5 mediumiransansfont"  >


            <div className={' pr-0 mb-1 mt-5 d-flex align-items-center justify-content-center'} >
              <div className="text-center">
                <h2 className=' mb-1 text-ultra-big' style={{ marginTop: 0, whiteSpace: 'nowrap', color: '#13191d', marginLeft: 20, marginRight: 20, fontWeight: 'bold' }}>چرا انبار مازاد؟</h2>
                <p className=' mb-1 text-normal' style={{ marginTop: 0, whiteSpace: 'nowrap', color: '#789', marginLeft: 20, marginRight: 20, }}>یک جمله شعاری در اینجا آورده میشود</p>

              </div>
            </div>

            <div className={'row text-center mt-3 pb-5'} style={{ borderBottom: '1px solid #e2e8f2' }}>
              {this.state.services.map((prop, index) => {
                return (
                  <div key={index} className="col-6 col-sm-6 col-md-3 col-lg-3 mt-4">
                    <img src={prop.image} style={{ height: 70, marginBottom: 5 }} />
                    <p className={'text-semibig mt-2'}>{prop.title}</p>
                    <p className={'text-small mt-2'} style={{ color: '#789' }}>{prop.desc}</p>
                  </div>
                )
              })}

            </div>

            <div >
              <p className={'text-big mt-5 text-center font-bold'}>درباره ما</p>

              <p className={'text-small mb-3'} style={{textAlign:'justify',color:'#123'}} className={'text-small mt-4'}>
              قطعات و ادوات مازادی دارید که می خواهید آن ها را به نقدینگی تبدیل کرده و از هزینه های نگهداری خلاص شوید؟ یا به دنبال خرید اقلام نو، دست دوم و نایاب در حوزه قطعات و دستگاه های الکترونیکی، مخابراتی و الکتریکی با هزینه ی کمتر هستید؟ انبار مازاد راه حل شماست!
            </p>
            <p className={'text-small mb-3'} style={{textAlign:'justify',color:'#123'}}>
            با گذشت زمان اغلب شرکت های تولیدی و یا تحقیقاتی و همچنین افرادی که در حوزه های مربوط به برق و الکترونیک فعالیت می کنند، با حجمی از مواد اولیه روبرو هستند که به دلایل متفاوتی همچون تغییرات محصول تولیدی، متوقف شدن خط تولید، اتمام پروژه و ... دیگر محل مصرفی نخواهند داشت. پر واضح است که سرمایه شرکت و یا فرد در قالب مواد، قطعات و یا لوازم اولیه در انبار دپو شده است. حجم موجود غیرقابل استفاده در انبار از چندین میلیون تا چند صد میلیون در شرکت های بزرگ تر متغییر خواهد بود. همچنین در طول زمان علاوه بر از بین رفتن بازار مصرف برخی از این مواد یا اقلام، ممکن است که آن ها با کاهش قیمت نیز روبرو شوند. حال در صورتی که بتوان تمام یا حتی بخشی از این موارد را به فروش رساند، نقدینگی بوجود آمده می تواند در حوزه های مختلف آن شرکت و یا برای آن فرد مورد استفاده قرار بگیرد.
            </p>
            <p className={'text-small mb-3'} style={{textAlign:'justify',color:'#123'}}>
            از آن جا که بسیاری از شرکت ها و افراد فعال در حوزه برق، الکترونیک، مخابرات و ... و تولیدکنندگان محصولات مرتبط؛ با مشکل دپو شدن قطعات مصرفی الکترونیکی روبرو هستند سایت انبارمازاد درصدد است تا با فراهم کردن بستری مناسب، امکان فروش قطعات مصرفی این حوزه ها را فراهم آورد.
            </p>
            <p className={'text-small mb-3'} style={{textAlign:'justify',color:'#123'}} style={{color:'#007aff'}}>انبار مازاد امکانی است جهت معرفی و فروش قطعات و اقلام مازاد و یا دپو شده در انبار.</p>
           
           
           
            <p className={'text-small mb-3'} style={{textAlign:'justify',color:'#123'}} className={'text-big mt-5 mb-4 font-bold text-center'}>انبار مازاد از کجا و به کجا؟</p>

            <p className={'text-small mb-3'} style={{textAlign:'justify',color:'#123'}}>
            انبار مازاد؛ ابتدا به عنوان زیر مجموعه ای در سایت پارتجو در زمستان سال 1396 فعالیت خود را آغاز کرد. شیوه ی عملکرد ابتدایی انبار مازاد بدین صورت بود که متقاضیان درخواست خود را به همراه لیست قطعات و ادوات مازاد خود از طریق ای میل ارسال می کردند؛ سپس این درخواست و لیست توسط پارتجو بررسی و متناسب با تعداد اقلام هزینه ی محدودی از متقاضی دریافت می شد. در انتها آن لیست وارد بخش انبار مازاد سایت پارتجو می شد و در نتایج جستجو ها نمایش داده می شد.  
            </p>



            <p className={'text-small mb-3'} style={{textAlign:'justify',color:'#123'}}>
            این شیوه چندین معضل داشت، به طور مثال کل فرایند، فرآیند به نسبت طولانی و زمانگیری بود. از ارسال ای میل و آماده کردن لیست توسط متقاضی، تا پاسخ دستی به هر ای میل و سپس درج اقلام در سایت. و یا از دیگر مشکلات این بود که مکانی برای مشاهده ی مستقیم قطعات وجود نداشت و کاربران تنها در نتایج جستجو می توانستند در زیر مجموعه ی انبار مازاد که به عنوان یک فروشگاه تعریف شده بود، آن قطعات را مشاهده کنند. مشکل دیگری که در این شیوه وجود داشت عدم بروز شدن اطلاعات مربوط به فروشنده و قطعات مربوط به وی بود؛ چرا که فروشنده پنل شخصی برای خود نداشت و در صورت هر گونه تغییر باید از طریق تماس با پارتجو، آن تغییر را اطلاع می داد. 
            </p>
            <p className={'text-small mb-3'} style={{textAlign:'justify',color:'#123'}}>
            تیم پارتجو در ادامه روش دیگری را هم در مدت محدودی اجرایی کرد. در این روش پارتجو واسطه ی خرید می شد و پس از دریافت قطعات؛ خود اقدام به ارسال قطعات می کرد. این روش مزایای محدودی داشت منتهی باز هم امکان گسترش آن بسیار سخت بود. خصوصاً این که ممکن بود با توسعه ی این روش پارتجو احیاناً واسطه ی خرید و فروش کالایی شود که در ظاهر مشکل خاصی نداشته باشد ولی کالایی غیر اصل باشد و این در آینده به اعتبار پارتجو لطمه بزند.
            </p>
            <p className={'text-small mb-3'} style={{textAlign:'justify',color:'#123'}}>
            لذا از زمستان سال 1398 بررسی های مختلفی توسط تیم پارتجو انجام گرفت و نهایتا در اوایل سال 1399؛ تصمیم برآن گرفته شد که انبار مازاد به صورت یک سایت مستقل و با شیوه ای متفاوت راه اندازی شود. پس از نهایی شدن طرح و ویژگی های کلی آن؛ کار برنامه نویسی سایت آغاز شد و سایت اولیه در پاییز 1399 راه اندازی شد. حدود 3 ماه پس از آن نیز تست های اولیه و رفع باگ ها انجام شد و نهایتاً سایت انبار مازاد از ابتدای سال 1400 در دسترس عموم کاربران قرار گرفت. 
            </p>
            <p className={'text-small mb-3'} style={{textAlign:'justify',color:'#123'}}>
            در شیوه ی جدید بکار رفته در سایت انبار مازاد؛ فرآیند ثبت آگهی بسیار ساده تر شده و خود متقاضی نسبت به درج آگهی خود اقدام می کند. تنها در مرحله ی تائید آگهی جهت جلوگیری از درج آگهی مغایر با قوانین سایت، تائید توسط انبار مازاد انجام شده و پس از آن آگهی بدون دریافت هزینه ای از کاربر در سایت نمایش داده می شود. در این شیوه خریدار به طور مستقیم با فروشنده ارتباط برقرار می کند و انبار مازاد هیچ نقشی در فرآیند خرید ندارد. 
            </p>
            <p className={'text-small mb-3'} style={{textAlign:'justify',color:'#123'}}>
            در آینده امکانات دیگری نیز ان شاالله به سایت اضافه خواهد شد.  
            </p>
            <p className={'text-small mb-3'} style={{textAlign:'justify',color:'#123'}}>
            شما می توانید هر گونه نظر، انتقاد و یا پیشنهاد خود را از طریق صفحه ی تماس با ما؛ با ما در میان بگذارید. 
            </p>
           
            </div>

          </div>
        </div>




        <div style={{ marginTop: 0, paddingBottom: 10,backgroundColor:'#fff' }}>
          <div className="container pb-5 mediumiransansfont"  >
            <div className={' pr-0 mb-1  d-flex align-items-center justify-content-center'} >
              <div className="text-center">
                <h2 className=' mb-1 ' style={{ fontSize: 26, marginTop: 0, whiteSpace: 'nowrap', color: '#13191d', marginLeft: 20, marginRight: 20, fontWeight: 'bold' }}>اطلاعات بیشتر</h2>
              </div>
            </div>

            <div className={'row text-center mt-3 ml-1 mr-1'}>
              {this.state.fastAccess.map((prop, index) => {
                return (
                  <TLink to={prop.link} className="col-12  col-md-4 col-lg-4 mt-4" key={index}>
                    <div>
                      <img src={prop.image} style={{ boxShadow: '0px 0px 30px rgba(0,0,0,0.1)', width: '100%', marginBottom: 5, borderRadius: 8 }} />
                      <p className={'text-semibig mt-3'}>{checkTextTranslation(prop.title)}</p>
                      {/* <p className={'info-desc pr-1 pl-1 pr-md-0 pl-md-0'} style={{ fontSize: 13 }}>{prop.desc}</p> */}
                    </div>
                  </TLink>
                )
              })}

            </div>

          </div>
        </div>





      </section>
    );
  }
}

export default Aboutus;
