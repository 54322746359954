import React from 'react'
import { translate, currentLng, converNumberToPersian, priceStandardView } from '../utils/useful'
import { siteTheme, siteConfig } from '../variables/config'
import TLink from './TLink'
import stripHtml from "string-strip-html"
import { imageAddress } from '../utils/useful'
import moment from 'jalali-moment'

class Product extends React.Component {
    state = {
        schema: { title: null, description: null, price: null, image: null, portals: null }
    }

    dataBuilder(data, schema) {
        let prop = {}
        for (const [key] of Object.entries(this.state.schema)) {
            prop[key] = this.valueBuilder(schema[key], data)
        }
        return prop
    }

    valueBuilder(key, data) {
        if (key != null && data) {
            let keysArray = key.split('.')
            let newData = data
            if (data.lng && keysArray[0] === "{{lng}}") {
                newData = data.lng[currentLng()]
            } else {
                newData = data[keysArray[0]]
            }
            if (keysArray[1] != null) {
                return this.valueBuilder(keysArray[1], newData)
            } else {
                return newData
            }
        } else {
            return null
        }
    }

    getPrice(info) {
        // if (this.state.info?.unavailable) {
        //   return translate('unavailable')
        // } else {
        if (info?.price && info?.price != '' && Number(info?.price) != 0) {
            return converNumberToPersian(translate('currency', { price: priceStandardView(info?.price) }))
        } else {
            return "توافقی"
        }
        // }
    }


    render() {
        let prop = this.props.data//this.dataBuilder(this.props.data, this.props.schema)
        if (this.props.mainKey) {
            prop = prop[this.props.mainKey]
        }
        // console.log(prop)
        return (
            <div className="w-100 d-flex flex-column product-box" style={{ alignItems: 'stretch', flex: 1, position: 'relative' }}>
                {/* <TLink to={'/آگهی/' + (prop.slug ? prop.slug : prop._id)} ref={el => this.box = el} className="h-100 mt-0" > */}
                <TLink className="flexcc w-100 flex-column" to={'/ad/' + prop._id + '/' + prop?.title?.replace(/[^\/\\\.0-9a-zA-Z\u0621-\u06CC\u0660-\u0669]/gi, '_')} style={{ alignItems: 'stretch', flex: 1, backgroundColor: '#fff', borderRadius: 8 }}>

                    <div className="flexcc w-100 flex-column" style={{ flex: 1 }}>
                        <img src={imageAddress(prop.images, 'product', 'thumb')} alt="" style={{ borderRadius: 8, width: '100%', objectFit: 'contain' }} />
                        <div className="px-3 pb-2 d-flex w-100 flex-column" style={{ flex: 1 }}>
                            <div className="flexcb mt-3 " style={{ flexWrap: 'wrap' }}>
                                <p className=" text-normal" style={{ color: '#FF6C00',overflowWrap:'anywhere' }}>{prop.title}</p>
                                <p className=" text-smaller" style={{ color: '#585858dd', whiteSpace: 'nowrap' }}>{converNumberToPersian(moment(prop.uDate).locale('fa').format('jDD jMMMM jYYYY'))}</p>
                            </div>
                            <div className="w-100 mt-2" style={{ backgroundColor: '#FF6C0050', height: 1 }}>

                            </div>
                            <div style={{ flex: 1 }}>
                                <p className="text-smaller mt-2 mb-2 " style={{ color: '#585858' }} >{stripHtml(prop.description)}</p>
                            </div>

                            <div className="flexcb">
                                <p className="text-smaller mt-2 mb-2 font-bold" style={{ color: '#585858' }} >{this.getPrice(prop)}</p>
                                <div className={"product-view-but"} style={{}}>
                                    <p className="text-smaller" >مشاهده</p>
                                </div>

                            </div>
                            {prop.userinactive && (
                                <p className="text-smaller mb-1 mt-1" style={{ color: '#ee5050' }}>آگهی غیر فعال است</p>
                            )}
                        </div>



                    </div>
                </TLink>

                {this.props.settings?.showMore && (
                    <button onClick={() => { if (this.props.settings?.moreClicked) { this.props.settings.moreClicked(prop) } }} className="flexcc back-blur" style={{ position: 'absolute', top: 10, right: 10, width: 30, height: 30, backgroundColor: '#FF6C00dd', borderRadius: 30 }}>
                        <img src='/images/more.svg' height="16px" className="invert" />
                    </button>
                )}

                {/* </TLink> */}
            </div>
        )
    }
}

export default (Product);