import React from 'react';
import { phoneStandardView, translate, imageAddress, checkTextTranslation } from '../../utils/useful'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import stripHtml from "string-strip-html";
import { siteConfig } from '../../variables/config';
import Loader from 'react-loader-spinner'
import HttpService from '../../utils/Http.services';


class PaymentMethod extends React.Component {


  state = {
    methods: [
      { method: 'online', title: '{{lang}}onlinePayment', description: '{{lang}}onlinePaymentHint', icon: 'https://image.flaticon.com/icons/svg/138/138291.svg' },
      { method: 'cash', title: '{{lang}}payByCash', description: '{{lang}}payByCashHint', icon: 'https://image.flaticon.com/icons/svg/438/438526.svg' },
      // { method: 'wallet', title: '{{lang}}payByWallet', description: '{{lang}}payByWalletHint', icon: 'https://image.flaticon.com/icons/svg/1041/1041888.svg' }

    ]
  }


  componentDidMount() {
    this.initData()
  }

  initData() {
    if (Array.isArray(this.state.methods) && this.props.data.method) {
      this.state.methods.forEach((oneData, index) => {
        if (oneData.method == this.props.data.method) {
          this.setState({ method: oneData.method })
        }
      });
    }
  }


  getInfo = (cb) => {
    if (this.state.method) {
      let data = { method: this.state.method }
      cb(data)
    } else {
      cb(null, "{{lang}}errors.selectOne")
    }
  }


  sendOffCode() {
    this.setState({ error: null })
    HttpService.request("postOffCode", { code: this.state.offCode }, (fetchResult, fetchError) => {
      console.log(fetchError)
      if (fetchError) { this.setState({ error: fetchError.message }); return }
      this.props.useOffCode(fetchResult.info)
    })
  }


  render() {

    return (
      <section>

        <div style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>
          <div className="p-3" style={{ backgroundColor: '#e0e6e8' }}>
            <p className="font-bold text-center" style={{ fontSize: 18 }}>{translate('PaymentMethod')}</p>
          </div>


          <div className="w-100 " style={{ padding: '30px 25px' }}>
            {this.state.methods.map((prop, index) => {
              return (
                <div key={index} onClick={() => this.setState({ method: prop.method })} className=" mt-2" style={{ cursor: 'pointer', padding: 20, paddingTop: 10, paddingBottom: 15, backgroundColor: '#f4f6f8', borderRadius: 8 }}>
                  <div className="mt-2" style={{ alignItems: 'center', display: 'flex' }}>
                    <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                      <div style={{ backgroundColor: this.state.method == prop.method ? '#789' : '#cde', transition: 'all 0.5s', width: 20, height: 20, borderRadius: '50%', }}>
                      </div>
                      <div className="mld-4" style={{ borderRadius: 8, overflow: 'hidden' }}>
                        <img src={prop.icon} style={{ width: 50 }} />

                      </div>
                      <div className=" mr-3 ml-3">
                        <p className="font-bold text-semibig" style={{ color: '#222' }} >{checkTextTranslation(prop.title)}</p>
                        <p className="mt-1" style={{ fontSize: 11, color: '#789' }}>{checkTextTranslation(prop.description)}</p>

                      </div>
                    </div>

                  </div>
                </div>
              )
            })}
            {siteConfig.showDeliveryCalcText && (
              <p className="mt-3 mrd-2" style={{ color: '#ee5050' }}>هزینه ارسال پیش از ارسال به صورت تماس تلفنی با شما هماهنگ خواهد شد</p>
            )}

          </div>

        </div>

        <div className="mt-4" style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden', padding: '30px 25px' }}>
          <div className=" flexc" >
            <p className="font-bold text-semibold mrd-2" style={{ color: '#222' }} >{translate('off-code')}</p>
            <input onChange={e => this.setState({ offCode: e.target.value })} className="text-normal" style={{ width: 150, direction: 'ltr', backgroundColor: '#f2f6f8', border: '1px solid #eee', borderRadius: 4, height: 35, padding: '5px 15px' }} />

            {this.state.isLoadingOffCode ? (
              <div className="mt-2 mld-3">
                <Loader
                  type={"Oval"}
                  color={"#30aa70"}
                  height={35}
                  width={35}
                />
              </div>
            ) : (
                <button onClick={() => this.sendOffCode()} className="mld-3 text-small" style={{ backgroundColor: '#30aa70', borderRadius: 4, height: 35, color: '#fff', padding: '5px 20px' }}>{translate('submit-off-code')}</button>
              )}
          </div>

          {this.props.off?.price && (
            <p className="mt-3 text-small" style={{ color: '#ee5050' }}>تخفیف {this.props.off.price} تومانی برای شما اعمال شد</p>
          )}

          {this.state.error && (
            <p className="mt-3 text-small" style={{ color: '#ee5050' }}>{checkTextTranslation(this.state.error)}</p>
          )}
          <p className="mt-3 text-small" style={{ color: '#789' }}>{translate('off-code-desc')}</p>
        </div>

      </section >
    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user, cart: state.cart })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null, { forwardRef: true }
)(PaymentMethod);


