import React from 'react'
import LoaderButton from '../LoaderButton'
import FormViewer from '../FormViewer'
import TLink from '../TLink'
import { translate } from '../../utils/useful'

class GuaranteeCodeStep extends React.Component {
    state = {
        errors: {},
        formHeaders: [
            { key: 'code', type: 'TextInput', information: { label: '{{lang}}Code', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: '{{lang}}insertCode', required: true } },
            // { key: 'family', type: 'TextInput', information: { label: '{{lang}}family', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: '{{lang}}insertFamily', required: true } },
            // { key: 'email', type: 'TextInput', information: { label: '{{lang}}email', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: '{{lang}}insertEmail', required: true } },
            // { key: 'address', type: 'TextInput', information: { label: '{{lang}}address', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: '{{lang}}insertAddress', required: true } }
        ]

    }

    postGuaranteeCode = () => {
        let data = this.form.getForm()
        if (data) {
            this.setState({ isLoading: true, errors: {} })
            this.props.setData(data, () => {
                this.props.postGuaranteeCode((result, err) => {
                    if (err) { this.setState({ errors: err }) }
                    this.setState({ isLoading: false })
                })
            })
        }
    }


    render() {
        return (

            <div className="flexcc flex-column">
                <h2 className="mt-0 mb-0" style={{ fontSize: 27 }}>{translate('guarantee-code')}</h2>

                <div className="w-100" style={{ maxWidth: 400 }}>

        <p className="mb-4 mt-1 text-center" style={{ fontSize: 15 }}>{translate('guarantee-code-desc')}</p>


                    <FormViewer ref={el => this.form = el} headers={this.state.formHeaders} initData={this.props.initData} errors={this.state.errors} inputClass={'modern-input'} />

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.postGuaranteeCode}
                            isLoading={this.state.isLoading}
                            text={translate('send-code')}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                    </div>



        <p className="text-center" style={{ fontSize: 15, margin: '10px 20px 20px 20px' }}>{translate('guarantee-use-once')}</p>

                    <div className="w-100 text-center mb-5">
                        <button className="mb-1" onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate('back-one-stage')}</button>
                    </div>

                </div>
            </div>

        )
    }
}

export default GuaranteeCodeStep;