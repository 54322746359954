import React from 'react'
import LoaderButton from '../LoaderButton'
import FormViewer from '../FormViewer'
import TLink from '../TLink'
import ImageInput from '../inputs/ImageInput'
import { translate } from '../../utils/useful'
class UploadResourceStep extends React.Component {
    state = {
        key: '',
        selectedType: 'photo',
        data: {},
        isLoading: false,
        formHeaders: []
    }



    nextStage = () => {
        this.setState({ error: null })
        if (this.props.data['**files']?.["values.file"] == null) {
            this.setState({ error: translate("select-photo-to-continue") })
        } else {
            if(this.props.goToInfoStep){
                this.props.goToInfoStep()
            }else{
                this.props.photoFileDone()
            }
        }
    }


    changeFiles = (data, key) => {
        data.formType = 'ImageInput'
        // data.single = true

        this.props.setData({ ["**files"]: { [key]: data } })
    }

    onChange = () => {

    }

    render() {
        return (
            <div className="flexcc flex-column">
                <h2 className="mt-3 mb-1" style={{ fontSize: 27 }}>Choose Resource</h2>
                <div className="w-100" style={{ maxWidth: 400 }}>

                    <p className="text-center" style={{ fontSize: 14, margin: '0px 20px 20px 20px' }}>Choose the resource you want to upload. Your files should have hight quality and high resolution</p>

                    <div className=" w-100 mt-5 mb-5 text-center">
                        <div className="flexcc p-3" style={{ border: '1px #bcd dashed', backgroundColor: '#def', borderRadius: 8, minHeight: 100 }}>
                            <ImageInput files={this.props.data['**files']?.['values.file']}  header={{ key: 'values.file', information: { single: true } }} changeFiles={this.changeFiles} onChange={this.onChange} />
                        </div>
                    </div>

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.nextStage}
                            isLoading={this.state.isLoading}
                            text={translate('next-step')}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                        {this.state.error && (
                            <p style={{ fontSize: 15, margin: '5px 0px', color: '#ee5050' }}>{this.state.error}</p>
                        )}
                        <TLink to={'/pages/terms'}><p style={{ fontSize: 15, margin: '10px 20px 20px 20px' }}>{translate('resource-accept-terms')}</p></TLink>

                        <div className="w-100 text-center mb-5">
                            <button className="mb-1" onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate("back-one-stage")}</button>
                        </div>
                        {/* <p style={{ fontSize: 13, opacity: 0.5, marginTop: 10 }}>This website uses cookies to deliver the best possible user experience. With using the website you are accepting it</p> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default UploadResourceStep;