import React from 'react'
import LoaderButton from '../LoaderButton'
// import FormViewer from '../FormViewer'
import TLink from '../TLink'
import { checkTextTranslation, translate } from '../../utils/useful'
import { Trans } from 'react-i18next'

class AddAdFirstStep extends React.Component {
    state = {
        data: {},
        isLoading: false,
    }


    letsStart = () => {
        this.props.letsStart()
        window.history.pushState(null, null, '#add');

    }


    render() {
        return (
            <div className="flexcc flex-column">

                {/* <img src="/images/add1.png" width="50px" /> */}
                <h2 className="mt-2 mb-3" style={{ fontSize: 27 }}>ثبت آگهی</h2>
                <div className="w-100" style={{ maxWidth: 400 }}>

                    <p className="text-center" style={{ fontSize: 15, margin: '0px 20px 20px 20px' }}>توضیحات و قوانین ثبت آگهی در اینجا آورده میشود. این توضیحات میتواند ۲ الی ۳ پاراگراف باشد</p>

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.letsStart}
                            isLoading={this.state.isLoading}
                            text={'ادامه'}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#FF6C00', cursor: 'pointer', padding: '10px 50px', borderRadius: 30, fontSize: 15, color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                        {/* <p style={{ fontSize: 15, margin: '10px 20px 20px' }}>With becoming partner you are accepting our partner <TLink pathname="/pages/terms" style={{ color: '#007aff' }}>terms</TLink> and <TLink pathname="/pages/policies" style={{ color: '#007aff' }}>Policies</TLink></p> */}
                        <TLink to={'/pages/terms'} style={{ fontSize: 15, margin: '10px 20px 20px' }}>قوانین و مقررات سایت را مطالعه فرمایید</TLink>

                        {/* <Trans i18nKey="userMessagesUnread" >
                            Hello <strong >Pouya</strong>, you have 2 unread message. <TLink to="/msgs">Go to messages</TLink>.
                            </Trans> */}

                        <p style={{ fontSize: 13, opacity: 0.5, marginTop: 10 }}>از درستی اطلاعات ورودی اطمینان حاصل نمایید. در صورت مغایرت اطلاعات آگهی شما حذف خواهد شد</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddAdFirstStep;