import Posts from '../pages/Posts'
// import Home from '../pages/Chilik/Home'
import Home from '../pages/Home'


import Login from '../pages/Login'
import Categories from '../pages/Chilik/Categories'
import Resource from '../pages/Chilik/Resource'
import Resources from '../pages/Chilik/Resources'

import Guarantee from '../pages/Opia/Guarantee'
import OpiaAboutus from '../pages/Opia/Aboutus'

import Me from '../pages/Me'
import Checkout from '../pages/Checkout'
import BecomePartner from '../pages/Chilik/BecomePartner'
import SendResource from '../pages/Chilik/SendResource'
import Pages from '../pages/Pages'

import Products from '../pages/Opia/Products'
import ProductCategories from '../pages/Opia/Categories'
// import OpiaProduct from '../pages/Opia/Product'
import Profile from '../pages/Chilik/Profile'
import Product from '../pages/Product'
import OpiaProduct from '../pages/Opia/Product'

//ADIB
import AdibHome from '../pages/Adib/Home'


//Raees
import RaeesHome from '../pages/Raees/Home'
import RaeesAboutus from '../pages/Raees/Aboutus'
import RaeesBlogs from '../pages/Raees/Blogs'


import Estates from '../pages/Raees/Estates'
import Estate from '../pages/Raees/Estate'

//LOMANAB
import LomanabHome from '../pages/Lomanab/Home'
import Services from '../pages/Adib/Services'
import OneSession from '../pages/Adib/OneSession'
import AdibAboutus from '../pages/Adib/Aboutus'

import Samples from '../pages/Adib/Samples'
import LomanabProducts from '../pages/Lomanab/Products'
import LomanabPosts from '../pages/Lomanab/Posts'
import Blog from '../pages/Blog'
import AboutusLomanab from '../pages/Lomanab/Aboutus'
import Verify from '../pages/Verify'
import VerifyBalance from '../pages/VerifyBalance'

import Accessories from '../pages/Opia/Accessories'
import Addestate from '../pages/Raees/AddEstate'
// import Search from '../pages/Chilik/Search'
import ThreeD from '../pages/Opia/ThreeD'
import Blogs from '../pages/Opia/Blogs'
// import LomanabProduct from '../pages/Lomanab/Product'

import ChilikAboutus from '../pages/Chilik/Aboutus'
import EditResource from '../pages/Chilik/EditResource'
import AddAd from '../pages/AddAd'
import Search from '../pages/Search'
import EditAd from '../pages/EditAd'
import Contactus from '../pages/Contactus'



const routes = [


    // LOMANAB
    // { path: "/", name: "{{lang}}Home", icon: "", component: LomanabHome, public: true, exact: true, },
    // { path: "/product", name: "{{lang}}Product", icon: "", component: Product, public: true, },
    // { path: "/products", name: "{{lang}}products", icon: "", component: LomanabProducts, public: true, header: true },
    // { path: "/blogs", name: "{{lang}}blogs", icon: "", component: LomanabPosts, public: true, header: true },
    // { path: "/blog", name: "{{lang}}blog", icon: "", component: Blog, public: true, header: false },
    // { path: "/aboutus", name: "{{lang}}AboutUs", icon: "", component: AboutusLomanab, public: true, header: true },



    // DR-adib
    // { path: "/", name: "Home", icon: "", component: AdibHome, public: true, exact: true, },
    // { path: "/onesession", name: "درمان های یک جلسه ای", icon: "", component: OneSession, public: true, header: true },
    // { path: "/services", name: "خدمات", icon: "", component: Services, public: true, header: true },
    // { path: "/portfolio", name: "نمونه ها", icon: "", component: Samples, public: true, header: true },
    // { path: "/aboutus", name: "درباره ما", icon: "", component: AdibAboutus, public: true, header: true },


    // CHILIK
    // { path: "/", name: "Home", icon: "", component: Home, public: true, exact: true, },
    // { path: "/resource", name: "resource", icon: "", component: Resource, public: true },
    // { path: "/resources/photo", name: "{{lang}}photos", icon: "", component: Resources, exact: true, public: true, header: true },
    // { path: "/resources/vector", name: "{{lang}}vectors", icon: "", component: Resources, exact: true, public: true, header: true },
    // { path: "/becomepartner", name: "{{lang}}becomepartner", icon: "", component: BecomePartner, header: true, roles: [{ role: 'user' }, { role: 'pending' }, ] },
    // { path: "/category/:slug", name: "Categories", icon: "", component: Categories, public: false },
    // { path: "/profile", name: "Categories", icon: "", component: Profile, public: false },
    // { path: "/aboutus", name: "{{lang}}AboutUs", icon: "", component: ChilikAboutus, public: true, header: true },
    // { path: "/sendResource", name: "{{lang}}sendResource", icon: "", component: SendResource, header: true, roles: [{ role: 'partner' }] },
    // { path: "/editResource", name: "{{lang}}editResource", icon: "", component: EditResource, header: false, roles: [{ role: 'partner' }] },

    // { path: "/search/:keyword", name: "{{lang}}search", icon: "", component: Search, exact: true, public: true, header: false },


    // OPIA
    { path: "/", name: "Home", icon: "", component: Home, public: true, exact: true, },
    // { path: "/product", name: "product", icon: "", component: OpiaProduct, public: true, },
    { path: "/ad/:id", name: "products", icon: "", component: Product, public: false, },
    { path: "/search", name: "جست و جو", icon: "", component: Search, public: false, header: true },
    { path: "/editAd/:id", name: "edit ad", icon: "", component: EditAd, public: false, },

    // { name: "جست و جو", icon: "", component: ProductCategories, public: true, header: true },
    // { path: "/accessories/:product?", pathForHeader: '/accessories', name: "{{lang}}accessories", icon: "", component: Accessories, public: true, header: true },
    { path: "/new-ad", name: "ثبت آگهی", icon: "", component: AddAd, public: true, header: false },
    // { path: "/blogs", name: "{{lang}}blogs", icon: "", component: Blogs, public: true, header: true },
    // { path: "/blog", name: "blog", icon: "", component: Blog, public: true, header: false },
    // { path: "/پشتیبانی", name: "پشتیبانی", icon: "", component: OpiaAboutus, public: true, header: true },

    { path: "/aboutus", name: "{{lang}}AboutUs", icon: "", component: OpiaAboutus, public: true, header: true },

    { path: "/contactus", name: "{{lang}}contactus", icon: "", component: Contactus, public: true, header: true },


    // Raeesmelk
    // { path: "/", name: "Home", icon: "", component: RaeesHome, public: true, exact: true, },
    // { path: "/rent", name: "{{lang}}rent", icon: "", component: Estates, public: true, header: true },
    // { path: "/sale", name: "{{lang}}sale", icon: "", component: Estates, public: true, header: true },
    // { path: "/presale", name: "{{lang}}presale", icon: "", component: Estates, public: true, header: true },
    // { path: "/barter", name: "{{lang}}barter", icon: "", component: Estates, public: true, header: true },
    // { path: "/addestate", name: "{{lang}}barter", icon: "", component: Addestate, public: true, header: false },
    // { path: "/estate", name: "{{lang}}estate", icon: "", component: Estate, public: true, header: false },

    // { path: "/blogs", name: "{{lang}}blogs", icon: "", component: RaeesBlogs, public: true, header: true },
    // { path: "/aboutus", name: "{{lang}}AboutUs", icon: "", component: RaeesAboutus, public: true, header: true },
    // { path: "/blog", name: "blog", icon: "", component: Blog, public: true, header: false },



    // { path: "/blog", name: "blog", icon: "", component: Blog, public: true, header: false },

    { path: "/verify", name: "{{lang}}verify", icon: "", component: Verify, public: true, header: false },
    { path: "/verifybalance", name: "{{lang}}verify", icon: "", component: VerifyBalance, public: true, header: false },

    { path: "/pages/:name", name: "pages", icon: "", component: Pages, public: true, header: false },
    { path: "/checkout", name: "checkout", icon: "", component: Checkout },
    { path: "/login", name: "Log in", icon: "", component: Login, public: true, },
    { path: "/me", name: "me", icon: "", component: Me, public: true, },
    { path: "/forgotpassword", name: "forgotPassword", icon: "", component: Login, public: true, },







    // {
    //     path: "/portals",
    //     name: "portals",
    //     icon: "",
    //     component: Posts,
    //     public: true,
    //     header: true
    // },




]


export default routes