import React from 'react';
import DataGrid from '../../components/DataGrid'
import BlogBox from '../../components/BlogBox'
import HttpService from '../../utils/Http.services';

// import { translate } from '../utils/useful'


class LomanabPosts extends React.Component {


  state = {
    data: []
  }

  componentDidMount() {
    this.fetch()
  }


  fetch() {
    this.setState({ isLoading: true })
    HttpService.request("getAllWithData", { applet: 'blogs' }, (fetchResult, fetchError) => {
      this.setState({ isLoading: false })

      if (fetchError) { return }

      // console.log(fetchResult.info)
      this.setState({ data: fetchResult.info })
    })
  }


  render() {

    return (
      <section style={{ paddingBottom: 80 }}>

        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#f2f6f8' }}>
          <h1 className=" text-mega-big">مقالات لوماناب</h1>
          <h1 className=" text-big" style={{color:'#9ab',letterSpacing:5}}>Lomanab.ir</h1>

        </div>
       
        <div className="container mt-4">
          <div className="row">

            <div className="col-12 mb-5 mt-4">
              <DataGrid isLoading={this.state.isLoading} data={this.state.data} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={BlogBox} col="col-12 col-md-6 col-lg-4" />
            </div>
          </div>
        </div>

      </section>
    );
  }
}

export default LomanabPosts;
